const colors = {
    aqua: '#9eba72',
    red: '#f25555',
    white: '#ffffff',
    black: '#000000',
    lightGray: '#F2F1F0',
    menuHover: 'yellow',
    darkGray: '#E8E8E8',
    lightBlue: '#7A869C',
    mediumBlue: '#7899D4',
    darkBlue: '#2d3047',
    mediumGray: '#cdcdca',
    //Old Colors
    brightGreen: '#17CE10',
    yellow: '#FAAC13',
    gray: '#40516a',
};

export default colors;
