import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';

export const StyledForm = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
    grid-gap: ${scale.xl};
`;

export const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${scale.sm};
    padding-top: ${scale.md};
    margin-top: ${scale.md};
    border-top: 1px solid ${colors.lightGray};
    grid-column-start: span 2;
`;
