import React from 'react';
import { FieldAttributes, Field } from 'formik';
import styled from 'styled-components';
import colors from '../constants/colors';
import { CheckOutlined } from '@ant-design/icons';

const StyledField = styled(Field)`
    display: none;
    :checked + .checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.aqua};
    }
    :checked + .custom-checkbox {
        border: 2px solid ${colors.aqua} !important;
    }
`;

const StyledLabel = styled.label`
    width: 35px;
    height: 35px;
    border-radius: 3px;
    background-color: ${colors.white};
    //TODO fix border color when checked
    border: 2px solid ${colors.darkGray};
    transition: background-size 0.2s ease;
    cursor: pointer;
    :hover {
        border: 2px solid ${colors.aqua};
    }
`;

const StyledSpan = styled(CheckOutlined)`
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: none;
`;

const FormikCheckbox: React.FC<FieldAttributes<any>> = (props) => (
    <StyledLabel className="custom-checkbox">
        <StyledField type="checkbox" {...props} />
        <StyledSpan className="checkmark"></StyledSpan>
    </StyledLabel>
);
export default FormikCheckbox;
