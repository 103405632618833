import React from 'react';
import { Typography, Space, Tooltip } from 'antd';
import moment from 'moment';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import colors from '../constants/colors';
import useRouter from '../hooks/useRouter';

const HeaderContainer = styled.div`
    width: 100%;
    padding: 20px 0;
`;

const HeaderTitle = styled(Typography.Text)`
    font-size: 20px;
    color: ${colors.lightGray};
`;

const HeaderSubitle = styled(Typography.Text)`
    font-size: 14px;
    color: ${colors.lightGray};
`;

const StyledArrow = styled(ArrowLeftOutlined)`
    font-size: 30px;
    color: ${colors.lightGray};
`;

const ScreenHeader: React.FC<{ title: string; showBack?: boolean }> = ({ title, showBack }) => {
    const router = useRouter();
    const date = moment().format('dddd, MMMM D, YYYY');

    return (
        <HeaderContainer>
            <Space align="baseline" size="middle">
                {showBack ? (
                    <Tooltip title="Click to go back">
                        <StyledArrow onClick={() => router.history.goBack()} />
                    </Tooltip>
                ) : null}
                <HeaderTitle strong>{title}</HeaderTitle>
                <HeaderSubitle>{date}</HeaderSubitle>
            </Space>
        </HeaderContainer>
    );
};

export default ScreenHeader;
