import storage from '../utilities/localStorage';
import Auth from '../types/auth';
import User from '../types/user';

const useIsAuthenticated = (): boolean => {
    const auth: Auth = storage.get('auth');
    const user: User = storage.get('user');
    const date = new Date();

    //do we have a good auth?
    //no
    if (
        !user ||
        !auth ||
        !auth.secret ||
        !auth.passPhrase ||
        !auth.dateExpires ||
        Date.parse(auth.dateExpires) <=
            Date.UTC(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                date.getUTCSeconds(),
            )
    ) {
        //return failure
        return false;
    }
    //return success
    return true;
};

export default useIsAuthenticated;
