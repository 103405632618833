import ProjectFormValues from '../types/newProject';

const FormValues: ProjectFormValues = {
    name: '',
    dateStart: '',
    dateEnd: '',
    quarterStartMonth: 0,
    daysToSample: [],
    sampleTimes: { timeStart: '', timeEnd: '' },
    groups: [],
    message: '',
    sendNow: false,
};

export const timesArray = [
    ['9:00 am', '5:00 pm'],
    ['8:00 am', '7:00 pm'],
];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default FormValues;
