import React from 'react';
import styled from 'styled-components';
import MenuBar from '../components/MenuBar';
import FeelalyticsLogo from '../components/FeelalyticsLogo';
import colors from '../constants/colors';
import scale from '../constants/scale';
import { device } from '../constants/device';
import Footer from '../components/Footer';
import NavCompanyName from '../components/NavCompanyName';

export const NavContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: ${scale.lg};
    background-color: ${colors.darkBlue};
    padding: ${scale.md} ${scale.lg};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

    @media ${device.laptop} {
        padding: ${scale.sm} ${scale.md};
    }

    @media print {
        display: none;
    }
`;

const Body = styled.div`
    padding: 0 143px;
    width: 100%;
    min-height: calc(100vh - 122px);
    display: flex;
    flex-direction: column;

    @media ${device.laptop} {
        padding: ${scale.md};
    }
`;

const StandardLayout: React.FC<{ page?: string; simple?: boolean }> = ({
    children,
    page = 'dashboard',
    simple = false,
}) => (
    <>
        <NavContainer>
            <FeelalyticsLogo />
            {simple ? null : <MenuBar current={page} />}
            <NavCompanyName />
        </NavContainer>
        <Body>{children}</Body>
        <Footer />
    </>
);
export default StandardLayout;
