import React, { useEffect, useState } from 'react';
import { Button, Card, Input, Space, Table, Tooltip, Tag, notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import ScreenHeader from '../../components/ScreenHeader';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import useIsAdmin from '../../hooks/useIsAdmin';
import colors from '../../constants/colors';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import auths from '../../query-functions/auths';
import scale from '../../constants/scale';
import error from '../../utilities/error';

type AuthTypes = {
    [key: string]: any;
    dateAdded: string;
    dateExpires: string;
    enabled: boolean;
    id: string;
    ipAddress: string;
    phoneNumber: string;
};

const StyledButton = styled(Button)`
    background-color: ${({ disabled }) => (disabled ? '' : colors.yellow)};
    border-color: ${({ disabled }) => (disabled ? '' : colors.yellow)};
`;

const FilterContainer = styled.div`
    padding: ${scale.sm};
`;

const Auths: React.FC = () => {
    const authenticated = useIsAuthenticated();
    const admin = useIsAdmin();
    const router = useRouter();
    const queryClient = useQueryClient();

    const [authsData, setAuthsData] = useState<AuthTypes[]>([]);
    const [selected, setSelected] = useState<AuthTypes>({} as AuthTypes);
    const [filteredData, setFilteredData] = useState<AuthTypes[]>([]);
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [statusLoading, setStatusLoading] = useState<boolean>(false);

    const { isLoading } = useQuery('auths', auths.get, {
        onSuccess: (data: AuthTypes[]) => {
            setAuthsData([]);
            data?.forEach((obj: any) => {
                setAuthsData((prevState) =>
                    prevState.concat({
                        dateAdded: moment(obj.dateAdded).format('L LT'),
                        dateExpires: moment(obj.dateExpires).format('L LT'),
                        id: obj.id,
                        ipAddress: obj.ipAddress,
                        enabled: obj.enabled,
                        phoneNumber: obj.phoneNumber
                            ? obj.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                            : '',
                    }),
                );
            });
        },
        onError: (err) => {
            notification.error({
                message: 'An error has occurred',
                description: 'If the error persists please contact Feelalytics',
            });
            error(err, 'Auths.tsx');
        },
    });
    const mutation = useMutation(auths.disable, {
        onMutate: () => setStatusLoading(true),
        onSuccess: () => {
            setStatusLoading(false);
            queryClient.invalidateQueries('auths');
        },
        onError: (err) => {
            setStatusLoading(false);
            notification.error({
                message: 'An error has occurred',
                description: 'If the error persists please contact Feelalytics',
            });
            error(err, 'Employees.tsx');
        },
    });

    // Check for auth and admin
    useEffect(() => {
        if (authenticated && admin) return;
        router.push('/login');
    }, [authenticated, router, admin]);

    useEffect(() => {
        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
    }, [isLoading]);

    const ActionBar: React.FC = () => {
        const [disabled, setDisabled] = useState<boolean>(true);

        useEffect(() => {
            if (selected.id) setDisabled(false);
        }, [selected]);

        const disableAuth = () => {
            mutation.mutate({
                id: selected.id,
                enabled: !selected.enabled,
            });
        };

        return (
            <Space direction="horizontal">
                <StyledButton
                    onClick={disableAuth}
                    loading={statusLoading}
                    disabled={disabled}
                    size="large"
                    style={{
                        color: disabled ? colors.mediumGray : colors.white,
                        backgroundColor: disabled ? colors.lightGray : colors.aqua,
                    }}
                >
                    {selected.enabled ? 'Disable' : 'Enable'}
                </StyledButton>
            </Space>
        );
    };

    const Filter = (column: string) => (
        <FilterContainer>
            <Space>
                <Input
                    value={value}
                    onChange={(e) => {
                        const currValue = e.target.value;
                        setValue(currValue);
                        const newData = authsData.filter((entry) =>
                            entry[column].toString().toLowerCase().includes(value),
                        );
                        setFilteredData(newData);
                    }}
                />
                <Tooltip title="Clear Search">
                    <Button
                        onClick={() => {
                            setValue('');
                            setFilteredData([]);
                        }}
                        icon={<CloseCircleOutlined />}
                    />
                </Tooltip>
            </Space>
        </FilterContainer>
    );

    const columns = [
        {
            title: 'IP Address',
            dataIndex: 'ipAddress',
            filterDropdown: Filter('ipAddress'),
            width: 150,
            render: (ip: string) => {
                switch (ip) {
                    case '70.112.26.48':
                        return (
                            <Tooltip title="Justin Mozley" placement="right">
                                {ip}
                            </Tooltip>
                        );

                    default:
                        return ip;
                }
            },
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            width: 200,
            filterDropdown: Filter('phoneNumber'),
        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',
            width: 200,
            filterDropdown: Filter('dateAdded'),
        },
        {
            title: 'Date Expires',
            dataIndex: 'dateExpires',
            width: 200,
            filterDropdown: Filter('dateExpires'),
        },
        {
            title: 'Status',
            dataIndex: 'enabled',
            render: (tag: boolean) => <Tag color={tag ? 'green' : 'red'}>{tag ? 'Enabled' : 'Disabled'}</Tag>,
        },
    ];

    return (
        <StandardLayout page="auths">
            <ScreenHeader title="Auths" />
            <Card title={<ActionBar />}>
                <Table
                    columns={columns}
                    loading={loading}
                    dataSource={filteredData.length > 0 ? filteredData : authsData}
                    rowKey={(record) => record.id}
                    onRow={(record) => ({
                        onClick: () => setSelected(record),
                    })}
                    rowSelection={{
                        selectedRowKeys: [selected.id],
                        type: 'radio',
                        columnWidth: 40,
                    }}
                />
            </Card>
        </StandardLayout>
    );
};

export default Auths;
