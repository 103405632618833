import { Button, TimePicker, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikField from '../FormikField';
import FormikCheckbox from '../FormikCheckbox';
import FormikRadio from '../FormikRadio';
import storage from '../../utilities/localStorage';
import ProjectFormValues, { ProjectCreation } from '../../types/newProject';
import FormValues, { days } from '../../constants/projects';
import SpanRequired from '../Styled/SpanRequired';
import moment from 'moment';
import useRouter from '../../hooks/useRouter';
import Project from '../../types/project';

const quarterlyValidation = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    dateStart: Yup.string().required('Required'),
    dateEnd: Yup.string().required('Required'),
    quarterStartMonth: Yup.string().min(2, 'Required').required('Required'),
    daysToSample: Yup.array().min(1, 'Required'),
});

const Container = styled(Form)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
    margin-top: ${scale.xl};
`;
const DoubleContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

const OptionCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    padding: ${scale.lg};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const InputGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    gap: ${scale.md};
`;

const StyledInputGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: ${scale.md};
    align-items: center;
    margin-top: ${scale.md};
    margin-bottom: ${scale.md};
`;

const RadioGroup = styled(StyledInputGroup)`
    margin-bottom: ${scale.md};
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    height: 40px;
    margin-top: ${scale.md};
    display: flex;
    justify-content: center;
`;

const StyledTimePicker = styled(TimePicker.RangePicker)`
    width: 540px;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${scale.md};
`;

const StyledButton = styled(Button)`
    width: 194px;
    margin-top: ${scale.md};
`;

const StyledCancelButton = styled(Button)`
    color: ${colors.darkBlue};
    background-color: ${colors.lightGray};
    margin-top: ${scale.md};
    border-color: ${colors.lightGray};
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    :hover {
        background-color: ${colors.lightGray};
        border-color: ${colors.lightGray};
        color: ${colors.red};
    }
`;

const ErrorMessage = styled.div`
    color: ${colors.red};
    font-size: 12px;
`;

const Quarterly: React.FC<{
    onClick: () => void;
    onchooseGroup: (project: ProjectCreation) => void;
    projectType: string;
    projectData?: Project;
}> = ({ onClick, onchooseGroup, projectType, projectData }) => {
    const company: any = storage.get('company');
    const router = useRouter();

    const now = moment();
    const remainder = 5 - (moment().minute() % 5);

    const roundedTimeStart = now.add(remainder, 'minutes');
    const [defaultTimeStart, setDefaultTimeStart] = useState<moment.Moment | undefined>(
        moment(roundedTimeStart.format('HH:mm a'), 'HH:mm a').utc(),
    );

    const roundedTimeEnd = roundedTimeStart.add(3, 'hours');
    const [defaultTimeEnd, setDefaultTimeEnd] = useState<moment.Moment | undefined>(
        moment(roundedTimeEnd.format('HH:mm a'), 'HH:mm a').utc(),
    );

    const [defaultDateStart, setDefaultDateStart] = useState<moment.Moment>(moment().add(1, 'day'));
    const [defaultDateEnd, setDefaultDateEnd] = useState<moment.Moment>(moment().add(1, 'year'));

    const CreateProject = (data: ProjectFormValues) => {
        const daysArray: number[] = [];

        data.daysToSample.forEach((item) => {
            daysArray.push(days.findIndex((day) => day === item));
        });

        const ProjectToCreate = {
            projectType: projectType,
            name: data.name,
            quarterStartMonth: data.quarterStartMonth,
            dateStart: data.dateStart,
            dateEnd: data.dateEnd,
            daysToSample: daysArray,
            sampleTimes: {
                timeStart: data.sampleTimes.timeStart,
                timeEnd: data.sampleTimes.timeEnd,
            },
            companyId: company.companyId,
            groups: [],
            sendNow: false,
        };
        onchooseGroup(ProjectToCreate);
    };

    const FieldValues = {
        ...FormValues,
        dateStart: moment(defaultDateStart).format(),
        dateEnd: moment(defaultDateEnd).format(),
    };

    const SetDays = (data: number[]) => {
        const DaysStringArray: string[] = [];

        data.forEach((item) => {
            DaysStringArray.push(days[item]);
        });

        return DaysStringArray;
    };

    const SetQuarter = (data: number) => {
        switch (data) {
            case 1:
                return 'First Month (Jan, Apr, Jul, Oct)';
            case 2:
                return 'Second Month (Feb, May, Aug, Nov)';
            case 3:
                return 'Third Month (Mar, Jun, Sep, Dec)';
            default:
                return 'First Month (Jan, Apr, Jul, Oct)';
        }
    };

    useEffect(() => {
        //Editing
        //did we get project data?
        //yes
        if (projectData !== undefined) {
            setDefaultTimeStart(moment(projectData.sampleTimes.timeStart));
            //do we have a date?
            //yes
            if (projectData.sampleTimes.timeEnd !== undefined && projectData.sampleTimes.timeEnd?.length > 11) {
                setDefaultTimeEnd(moment(projectData.sampleTimes.timeEnd));
            }
            setDefaultDateStart(moment(projectData.dateStart));
            setDefaultDateEnd(moment(projectData.dateEnd));
            FieldValues.name = projectData.name;
            FieldValues.dateStart = projectData.dateStart;
            FieldValues.dateEnd = projectData.dateEnd;
            FieldValues.quarterStartMonth = SetQuarter(projectData.quarterStartMonth);
            FieldValues.daysToSample = SetDays(projectData.daysToSample);
            FieldValues.sampleTimes.timeStart = projectData.sampleTimes.timeStart;
            FieldValues.sampleTimes.timeEnd = projectData.sampleTimes.timeEnd;
        }
    }, []);

    return (
        <Formik
            initialValues={FieldValues}
            validationSchema={quarterlyValidation}
            onSubmit={(values) => CreateProject(values)}
        >
            {({ values, setFieldValue, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <>
                    <Container onSubmit={handleSubmit}>
                        <DoubleContainer>
                            <OptionCard>
                                <InputGrid>
                                    <div>
                                        <Text bold>
                                            Name your project <SpanRequired>*</SpanRequired>
                                        </Text>
                                        <FormikField
                                            type="name"
                                            name="name"
                                            placeholder="Project name"
                                            onChange={handleChange}
                                        />
                                        {touched.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                                    </div>
                                    <div>
                                        <Text bold>
                                            Start Date <SpanRequired>*</SpanRequired>
                                        </Text>
                                        <StyledDatePicker
                                            format={'MM/DD/YYYY'}
                                            defaultValue={defaultDateStart}
                                            value={defaultDateStart}
                                            onChange={(value) => {
                                                setDefaultDateStart(moment(value));
                                                setFieldValue('dateStart', moment(value).format('MM/DD/YYYY'));
                                            }}
                                            disabledDate={(current) => {
                                                return moment().add(-1, 'days') >= current;
                                            }}
                                        />
                                        {touched.dateStart && <ErrorMessage>{errors.dateStart}</ErrorMessage>}
                                    </div>
                                    <div>
                                        <Text bold>
                                            End Date <SpanRequired>*</SpanRequired>
                                        </Text>
                                        <StyledDatePicker
                                            format={'MM/DD/YYYY'}
                                            defaultValue={defaultDateEnd}
                                            value={defaultDateEnd}
                                            onChange={(value) => {
                                                setDefaultDateEnd(moment(value));
                                                setFieldValue('dateEnd', moment(value).format('MM/DD/YYYY'));
                                            }}
                                            disabledDate={(current) => {
                                                return moment().add(-1, 'days') >= current;
                                            }}
                                        />
                                        {touched.dateEnd && <ErrorMessage>{errors.dateEnd}</ErrorMessage>}
                                    </div>
                                </InputGrid>
                            </OptionCard>
                            <OptionCard>
                                <Text bold>
                                    During which month of the quarter do you want to send questions?{' '}
                                    <SpanRequired>*</SpanRequired>
                                </Text>
                                <RadioGroup>
                                    <FormikRadio name="quarterStartMonth" value={'First Month (Jan, Apr, Jul, Oct)'} />
                                    <Text size="14" bold>
                                        First Month (Jan, Apr, Jul, Oct)
                                    </Text>
                                    <FormikRadio name="quarterStartMonth" value={'Second Month (Feb, May, Aug, Nov)'} />
                                    <Text size="14" bold>
                                        Second Month (Feb, May, Aug, Nov)
                                    </Text>
                                    <FormikRadio name="quarterStartMonth" value={'Third Month (Mar, Jun, Sep, Dec)'} />
                                    <Text size="14" bold>
                                        Third Month (Mar, Jun, Sep, Dec)
                                    </Text>
                                </RadioGroup>
                                {touched.quarterStartMonth && <ErrorMessage>{errors.quarterStartMonth}</ErrorMessage>}
                                <Text bold>
                                    Which days of the week do you want to send questions? <SpanRequired>*</SpanRequired>
                                </Text>
                                <StyledInputGroup>
                                    {days.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <FormikCheckbox name="daysToSample" value={item} />
                                            <Text bold>{item}</Text>
                                        </React.Fragment>
                                    ))}
                                </StyledInputGroup>
                                {touched.daysToSample && <ErrorMessage>{errors.daysToSample}</ErrorMessage>}
                            </OptionCard>
                        </DoubleContainer>
                        <OptionCard>
                            <Text bold>
                                Choose a time range to send questions. The time shown is in your current time zone.
                                <SpanRequired>*</SpanRequired>
                            </Text>
                            <StyledInputGroup>
                                <StyledTimePicker
                                    value={[
                                        defaultTimeStart !== undefined ? defaultTimeStart.local() : moment(),
                                        defaultTimeEnd !== undefined ? defaultTimeEnd.local() : moment(),
                                    ]}
                                    onChange={(values) => {
                                        const startTime =
                                            values !== undefined && values !== null
                                                ? values?.[0]?.utc()
                                                : defaultTimeStart;
                                        const endTime =
                                            values !== undefined && values !== null
                                                ? values?.[1]?.utc()
                                                : defaultTimeEnd;

                                        setDefaultTimeStart(startTime);
                                        setDefaultTimeEnd(endTime);
                                        FieldValues.sampleTimes.timeStart =
                                            values !== undefined && values !== null
                                                ? values?.[0]?.utc().format()
                                                : moment().format();
                                        FieldValues.sampleTimes.timeEnd =
                                            values !== undefined && values !== null
                                                ? values?.[1]?.utc().format()
                                                : moment().format();
                                    }}
                                    use12Hours
                                    format="hh:mm a"
                                    minuteStep={5}
                                />
                            </StyledInputGroup>
                            {process.env.NODE_ENV === 'development' ? (
                                <pre>{JSON.stringify(values, null, 2)}</pre>
                            ) : null}
                        </OptionCard>
                    </Container>
                    <ButtonGroup>
                        <StyledCancelButton
                            disabled={isSubmitting}
                            onClick={() => onClick()}
                            size="large"
                            style={{ color: colors.black }}
                            htmlType="submit"
                        >
                            X Cancel
                        </StyledCancelButton>
                        <StyledButton
                            onClick={() => handleSubmit()}
                            size="large"
                            style={{ color: colors.white, backgroundColor: colors.red }}
                            htmlType="submit"
                        >
                            {router.location.pathname === '/editproject' ? 'Update Project' : 'Choose Groups'}
                        </StyledButton>
                    </ButtonGroup>
                </>
            )}
        </Formik>
    );
};

export default Quarterly;
