import React, { useState, useEffect } from 'react';
import NEIBanner from '../../components/NEIBanner';
import StandardLayout from '../../layout/StandardLayout';
import {
    RedoOutlined,
    CalendarOutlined,
    DashboardOutlined,
    NotificationOutlined,
    QuestionOutlined,
    DislikeOutlined,
    LikeOutlined,
} from '@ant-design/icons';
import useRouter from '../../hooks/useRouter';
import Project from '../../types/project';
import Ongoing from '../../components/Project/Ongoing';
import Quarterly from '../../components/Project/Quarterly';
import OneTimeSample from '../../components/Project/OneTimeSample';
import OneTime from '../../components/Project/OneTime';
import { useMutation, useQueryClient } from 'react-query';
import projects from '../../query-functions/projects';
import pulse from '../../query-functions/pulse';
import SHORTNotification from '../../components/SHORTNotification';
import storage from '../../utilities/localStorage';
import User from '../../types/user';

const EditProjectSettings: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();
    const user: User = storage.get('user');

    const [step2Icon, setStep2Icon] = useState<JSX.Element | null>(null);
    const [stepTitle, setStepTitle] = useState<string>('Choose your project type');
    const [projectComponent, setProjectComponent] = useState<React.ReactNode | null>(null);

    const { edit } = router.location.state ? (router.location.state as { edit: boolean }) : { edit: false };
    const { project } = router.location.state
        ? (router.location.state as { project: Project })
        : { project: {} as Project };

    const UpdateProjectPulseData = useMutation(pulse.updateProjectPulse, {
        onSuccess: () => {
            //SHORTNotification('Pulse has been updated');
            router.push('/');
        },
    });

    const UpdateProjectData = useMutation(projects.update, {
        onSuccess: () => {
            SHORTNotification('Project updated');
            queryClient.invalidateQueries('projects');
            if (
                project.projectType !== 'One-Time Announcement' &&
                project.projectType !== 'One-Time Custom Question' &&
                project.projectType !== 'One-Time Yes/No Question'
            ) {
                router.push('/');
            }
        },
    });

    useEffect(() => {
        if (!edit) {
            router.push('/');
        }
        if (edit) {
            HandleProject(project);
        }
    }, []);

    const UpdateProject = (data: any) => {
        delete data.groups;
        switch (project.projectType) {
            case 'Ongoing Sample':
                delete data.message;
                break;
            case 'Quarterly Sample':
                delete data.message;
                data.quarterStartMonth = SetQuarterData(data.quarterStartMonth);
                break;
            case 'One-Time Sample':
                delete data.message;
                delete data.quarterStartMonth;
                delete data.daysToSample;
                break;
            case 'One-Time Announcement':
                delete data.quarterStartMonth;
                delete data.daysToSample;
                break;
            case 'One-Time Custom Question':
                delete data.quarterStartMonth;
                delete data.daysToSample;
                break;
            case 'One-Time Yes/No Question':
                delete data.quarterStartMonth;
                delete data.daysToSample;
                break;
        }
        const ProjectUpdateData = { ...data, id: project.id, companyId: user.companyId };
        UpdateProjectData.mutate(ProjectUpdateData);
        if (
            project.projectType === 'One-Time Announcement' ||
            project.projectType === 'One-Time Custom Question' ||
            project.projectType === 'One-Time Yes/No Question'
        ) {
            const PulseUpdatedData = { id: project.pulses[0].id, companyId: user.companyId, question: data.message };
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(PulseUpdatedData);
            }
            UpdateProjectPulseData.mutate(PulseUpdatedData);
        }
    };

    const SetQuarterData = (data: string) => {
        switch (data) {
            case 'First Month (Jan, Apr, Jul, Oct)':
                return 1;
            case 'Second Month (Feb, May, Aug, Nov)':
                return 2;
            case 'Third Month (Mar, Jun, Sep, Dec)':
                return 3;
            default:
                return 1;
        }
    };

    const HandleProject = (data: Project) => {
        switch (data.projectType) {
            case 'Ongoing Sample':
                setStep2Icon(<RedoOutlined />);
                setStepTitle('Settings for an ongoing emotional temperature Check');
                setProjectComponent(
                    <Ongoing
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
            case 'Quarterly Sample':
                setStep2Icon(<CalendarOutlined />);
                setStepTitle('Settings for a quarterly emotional temperature Check');
                setProjectComponent(
                    <Quarterly
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
            case 'One-Time Sample':
                setStep2Icon(<DashboardOutlined />);
                setStepTitle('Settings for a one-time emotional temperature Check');
                setProjectComponent(
                    <OneTimeSample
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
            case 'One-Time Announcement':
                setStep2Icon(<NotificationOutlined />);
                setStepTitle('Settings for an announcement');
                setProjectComponent(
                    <OneTime
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
            case 'One-Time Custom Question':
                setStep2Icon(<QuestionOutlined />);
                setStepTitle('Settings for a custom question');
                setProjectComponent(
                    <OneTime
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
            case 'One-Time Yes/No Question':
                setStep2Icon(
                    <>
                        <LikeOutlined style={{ transform: 'scaleX(-1)' }} />
                        <DislikeOutlined />
                    </>,
                );
                setStepTitle('Settings for a yes / no question');
                setProjectComponent(
                    <OneTime
                        onClick={() => router.push('/')}
                        onchooseGroup={(data) => UpdateProject(data)}
                        projectType={data.projectType}
                        projectData={data}
                    />,
                );
                break;
        }
    };

    return (
        <StandardLayout>
            <NEIBanner titleCard pageIcon={step2Icon} title={stepTitle} />
            {projectComponent}
        </StandardLayout>
    );
};

export default EditProjectSettings;
