import React from 'react';
import { FieldAttributes, Field } from 'formik';
import styled from 'styled-components';
import colors from '../constants/colors';

const StyledLabel = styled.label`
    cursor: pointer;
`;

const StyledDiv = styled.div`
    width: 28px;
    height: 28px;
    border: 2px solid ${colors.darkBlue};
    border-radius: 50%;
    box-sizing: border-box;
    padding: 5px;
    ::after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-color: ${colors.darkBlue};
        border-radius: 50%;
        transform: scale(0);
    }
`;

const RadioInput = styled(Field)`
    display: none;
    :checked + ${StyledDiv}::after {
        transform: scale(1);
    }
    :checked + ${StyledDiv} {
        background-color: ${colors.aqua};
        border: 2px solid ${colors.aqua};
    }
`;

const FormikRadio: React.FC<FieldAttributes<any>> = (props) => (
    <StyledLabel>
        <RadioInput type="radio" {...props} />
        <StyledDiv />
    </StyledLabel>
);
export default FormikRadio;
