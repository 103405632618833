const textLibrary: string[][] = [
    //-100 to -80
    [
        //heading
        `Your NEI is currently in the lowest emotional state possible. ` +
            `You have a serious problem with toxic culture that you need to address. ` +
            `Your business may be on the verge of, or in the middle of, a crisis.`,

        //text
        `Ask one of these questions and follow-up:\n\n` +
            `Does your manager listen to all relevant parties and consider all alternatives in the case of critical and irreversible decisions?\n\n` +
            `Does your manager remain calm and composed in difficult situationsor does your manager lose their temper and have a tendency to act irresponsibly?\n\n` +
            `Does your manager praise in public and criticise in private?\n\n` +
            `Does your manager communicate praise and criticisms in a language which is respectful, constructive, and opens up options instead of closing them down?\n\n` +
            `Is your manager open to feedback?\n\n` +
            `Do people feel it necessary to go around your manager with the problems which stop them from doing their best work?\n\n` +
            `If you haven't already, run a group-level NEI and figure out which group is driving the negativity. ` +
            `Start inquiries into the cause of the negativity in the areas that comparatively show the lowest NEI. ` +
            `Consider bringing in an executive coaching business to help turn your culture around.`,
    ],
    //1
    //-79 to -60
    [
        //heading
        `Warning! Your NEI is currently in an extremely negative emotional state. ` +
            `You likely have a serious problem with toxic culture that you need to address.`,

        //text
        `Ask one of these questions and follow-up:\n\n` +
            `Do team leaders chastise and blame people who experience failure, or do they support them to use failure as the learning opportunity that it is?\n\n` +
            `What can management do to use the lessons learnt to build up to future successes?\n\n` +
            `Do team leaders care about their people?\n\n` +
            `Do team leaders know what their direct reports care about, and where does work fit in their lives?\n\n` +
            `What can managers do to know what is most important to their staff?\n\n` +
            `Do team leaders inspire confidence and strength by acting authentically, calmly and honestly about their own fears?\n\n` +
            `Are any of your managers prone to get excited and carried away by their emotions?\n\n` +
            `Do managers keep every task for themselves, or try to be a perfectionist and control everything?\n\n` +
            `How would you like managers to delegate and give people the freedom to come to them with ideas and different outcomes?\n\n` +
            `If you haven’t already, run a group-level NEI and figure out which group leader may be driving the negativity. ` +
            `Consider bringing in an executive coaching business to help turn your culture around.`,
    ],
    //2
    //-59 to 40
    [
        //heading
        `Warning! Your NEI is currently in a very negative emotional state and you may be facing high attrition. ` +
            `You potentially have a serious problem with toxic culture.`,

        //text
        `Ask one of these questions:\n\n` +
            `If you were in my position, what would you do differently?\n\n` +
            `What am I doing wrong, or not as well as you would expect?\n\n` +
            `Do you see any gaps between my declared values and my actions?\n\n` +
            `What do you wish to discuss with me but fear it might upset me?\n\n` +
            `If you haven’t already, run a group-level NEI and figure out which group is driving the negativity. ` +
            `Start inquiries into the cause of the negativity in the areas that comparatively show the lowest NEI. ` +
            `Consider bringing in an executive coaching business to help turn your culture around.`,
    ],
    //3
    //-39 to -20
    [
        //heading
        `Your NEI is currently in a negative emotional state and you have people related issues you need to address.`,

        //text
        `Ask one of these questions:\n\n` +
            `What can I do to make your life easier?\n\n` +
            `How can I make sure you have everything you need to be successful?\n\n` +
            `Address the situation immediately. ` +
            `If you haven’t already, run a group-level NEI to understand which group is driving the negativity. ` +
            `Start inquiries into the cause of the negativity in the areas that comparatively show the lowest NEI.`,
    ],
    //4
    //-19 to -1
    [
        //heading
        `Your NEI is currently more negative than positive emotional state. ` +
            `You may be in a state of 'business as usual,' but your emotional culture needs a lift. ` +
            `It's common to dip below zero in difficult times or when the business is experiencing challenges, however watch out for areas of the business where a toxic culture may take hold and spread.`,

        //text
        `Ask one of these questions:\n\n` +
            `When was the last time you laughed at work?\n\n` +
            `If you had the opportunity to get a message across to a large group of people, what would you say?\n\n` +
            `Who do you look up to?\n\n` +
            `Who are your mentors, both those you know personally and those who inspire you from afar?\n\n` +
            `Consider starting these conversations as part of a team-building exercise that can help refresh and energize your team. ` +
            `Take a moment to write down all of the positive responses and how they can relate to your organization. Don’t forget to celebrate your wins!`,
    ],
    //5
    //1 to 19
    [
        //heading
        `Your NEI is currently more positive than negative emotional state. ` +
            `That's good, but pay attention to areas of your business that are suffering from toxic culture.`,

        //text
        `Ask one of these questions:\n\n` +
            `When was the last time you stepped out of your comfort zone and what was the result?\n\n` +
            `What are you excited about and what are you worried about?\n\n` +
            `Consider taking a group-level NEI, so you can better pinpoint any potential problem areas. ` +
            `Also consider scheduling one-on-one check-ins with those who may be under more stress.`,
    ],
    //6
    //20 to 39
    [
        //heading
        `Your NEI is currently in a positive emotional state. ` +
            `You have a positive culture and the positive energy is outpacing the negative. ` +
            `Energy levels are good and productivity is high. ` +
            `Sustaining an NEI over 20 is the optimum goal for maintaining your positive culture. ` +
            `Watch closely for dips below 20.`,

        //text
        `Ask the one of these questions:\n\n` +
            `What has surprised you lately?\n\n` +
            `What problem do you wish you could solve?\n\n` +
            `What is one life lesson that you think everyone should know?\n\n` +
            `While things are probably going well, don't take it for granted. ` +
            `Take this opportunity to listen to feedback and make small but meaningful adjustments. ` +
            `There may be a few pockets of negativity lurking in the corners. ` +
            `Consider scheduling one-on-one check-ins with those who may be under more stress.`,
    ],
    //7
    //40 to 59
    [
        //heading
        `Your NEI is currently in a very positive emotional state. ` +
            `Take a look at what's happening in your company and make notes, because it's really good. ` +
            `You likely have a very motivating leadership team.`,

        //text
        `Ask the question:\n\n` +
            `How does your team leader (or how do I) empower and strengthen you?\n\n` +
            `What can your team leader (or what can I) do to avoid disempowering you?\n\n` +
            `Asking these questions with honesty and vulnerability will help build your emotional intelligence and develop positive energy, keeping positivity at the core of your culture.`,
    ],
    //8
    //60 to 79
    [
        //heading
        `Your NEI is currently in an extremely positive emotional state. ` +
            `Take a look at what's happening in your company and make notes, because it's amazing. ` +
            `It's difficult to achieve an NEI over 60, and it is a score that will not remain in place for an extended period of time.`,

        //text
        `Ask the one of these questions:` +
            '\n\n' +
            `What do you enjoy about working with this team?\n\n` +
            `What do you look forward to doing during the work day?\n\n` +
            `What projects energize you?\n\n` +
            `What do you love about your work?\n\n` +
            `Listen to identify the areas of your organization that are thriving and celebrate them. ` +
            `Use this feedback to develop a plan for expanding those positive feelings into the team culture.`,
    ],
    //9
    //80 to 100
    [
        //heading
        `Your NEI is currently in an extraordinarily high positive state. ` +
            `That means everyone in your company feels celebratory, like if you were on the winning team of the Super Bowl. ` +
            `You wouldn't expect this intensity of a positive emotional state to remain in place for an extended period of time.`,

        //text
        `Ask the question:\n\n` +
            `What was your favorite moment thus far today?\n\n` +
            `Or What was your favorite moment yesterday?\n\n` +
            `Take note of the answers and how the responses relate to this overall amazing emotional state within your team. When times get tough, remember this feedback and revisit the circumstances that created these emotions.`,
    ],
    //10
    //0 NEI
    [
        //heading
        `Your NEI is currently neutral. ` +
            `That means everyone in your company feels neutral, like if you were trying to identify the taste of water. ` +
            `You should expect this lack of intensity to change with any programs.`,

        //text
        `Ask the question:\n\n` +
            `What was your favorite moment thus far today?\n\n` +
            `Or What was your favorite moment yesterday?\n\n` +
            `Take note of the answers and how the responses relate to this overall neutral emotional state within your team. When times get better, remember this feedback and revisit the circumstances that created these emotions.`,
    ],
];

const NeiText = (nei: number) => {
    //-100 to -80
    if (nei >= -100 && nei <= -80) {
        return textLibrary[0];
    }
    //1
    //-79 to -60
    else if (nei >= -79 && nei <= -60) {
        return textLibrary[1];
    }
    //2
    //-59 to 40
    else if (nei >= -59 && nei <= -40) {
        return textLibrary[2];
    }
    //3
    //-39 to -20
    else if (nei >= -39 && nei <= -20) {
        return textLibrary[3];
    }
    //4
    //-19 to 0
    else if (nei >= -19 && nei < 0) {
        return textLibrary[4];
    }
    //5
    //1 to 19
    else if (nei > 0 && nei <= 19) {
        return textLibrary[5];
    }
    //6
    //20 to 39
    else if (nei >= 20 && nei <= 39) {
        return textLibrary[6];
    }
    //7
    //40 to 59
    else if (nei >= 40 && nei <= 59) {
        return textLibrary[7];
    }
    //8
    //60 to 79
    else if (nei >= 60 && nei <= 79) {
        return textLibrary[8];
    }
    //9
    //80 to 100
    else if (nei >= 80 && nei <= 100) {
        return textLibrary[9];
    }
    //10
    //0 NEI
    else {
        return textLibrary[10];
    }

    return ['default'];
};

export default NeiText;
