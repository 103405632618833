import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';

type DisableTypes = {
    id: string;
    enabled: boolean;
};

const auths = {
    get: async () => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.auth, {
                params: {
                    all: true,
                },
                headers,
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
    disable: async ({ id, enabled }: DisableTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(
                api.auth,
                {
                    id,
                    enabled,
                },
                {
                    headers,
                },
            )
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
};

export default auths;
