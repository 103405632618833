import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import { QueryFunctionContext } from 'react-query';
import error from '../utilities/error';
import Group from '../types/group';

type RemoveTypes = {
    groupId: string;
    companyId: string | string[];
};

const groups = {
    getAll: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.group, {
                params: {
                    companyId: queryKey[1],
                },
                headers,
            })
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });
        return data.sort((a: Group, b: Group) => (a.name > b.name ? 1 : -1));
    },
    getByProject: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.group, {
                params: {
                    projectId: queryKey[1],
                },
                headers,
            })
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });
        return data.sort((a: Group, b: Group) => (a.name > b.name ? 1 : -1));
    },
    getOne: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.group, {
                params: {
                    id: queryKey[1],
                },
                headers,
            })
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });
        return data;
    },
    create: async ({ group, companyIdentifier }: { group: Group; companyIdentifier: string }) => {
        const { open, name, description, welcome, members, enabled, timezone } = group;

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };
        const { data } = await axios
            .post(
                api.group,
                {
                    companyId: companyIdentifier,
                    description,
                    enabled,
                    members,
                    name,
                    open: open === 'yes' ? true : false,
                    timezone,
                    welcome,
                },
                {
                    headers,
                },
            )
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });

        return data;
    },
    duplicate: async ({ group, companyIdentifier }: { group: Group; companyIdentifier: string }) => {
        const { open, name, description, welcome, members, enabled, timezone } = group;

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .post(
                api.group,
                {
                    companyId: companyIdentifier,
                    description,
                    enabled,
                    members,
                    name: `${name} - Copy`,
                    open: open === 'yes' ? true : false,
                    timezone,
                    welcome,
                },
                {
                    headers,
                },
            )
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });

        return data;
    },
    update: async ({ group, companyIdentifier }: { group: Group; companyIdentifier: string }) => {
        const { open, name, description, welcome, members, enabled, timezone, id } = group;

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(
                api.group,
                {
                    companyId: companyIdentifier,
                    description,
                    enabled,
                    id,
                    members,
                    name,
                    open: open === 'yes' ? true : false,
                    timezone,
                    welcome,
                },
                {
                    headers,
                },
            )
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });

        return data;
    },
    delete: async ({ groupId, companyId }: RemoveTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .delete(api.group, {
                params: {
                    id: groupId,
                    companyId,
                },
                headers,
            })
            .catch((err) => {
                error(err, 'groups.ts');
                throw new Error(err);
            });

        return data;
    },
};

export default groups;
