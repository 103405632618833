import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import storage from '../utilities/localStorage';
import useIsAdmin from '../hooks/useIsAdmin';
import colors from '../constants/colors';
import scale from '../constants/scale';
import User from '../types/user';

const StyledCompanyName = styled.div`
    color: ${colors.lightGray};
    font-size: ${scale.md};
    padding: 0 20px;
    line-height: 46px;
    margin-top: -1px;
    cursor: default;
`;

const NavCompanyName: React.FC = () => {
    const admin = useIsAdmin();
    const [companyName, setCompanyName] = useState<User['companyName'] | undefined>();

    // Load company from localStorage
    useEffect(() => {
        const company = storage.get('company');
        company && setCompanyName(company.companyName);
    }, []);

    // (Don't display component if user is not admin)
    if (!admin) return null;

    return (
        <StyledCompanyName>
            <div>{companyName}</div>
        </StyledCompanyName>
    );
};

export default NavCompanyName;
