import React, { useState } from 'react';
import { Button, notification } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import api from '../../constants/api';
import Auth from '../../types/auth';
import storage from '../../utilities/localStorage';
import error from '../../utilities/error';
import { useQueryClient } from 'react-query';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import CustomCard from '../../components/CustomCard';
import { Formik } from 'formik';
import FormikLabel from '../../components/FormikLabel';
import FormikField from '../../components/FormikField';
import * as Yup from 'yup';
import Company from '../../types/company';
import BackButton from '../../components/BackButton';
import { StyledForm, FormFooter } from '../../components/Styled/Form';
import colors from '../../constants/colors';

type RouterState = Company;

const FormSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    contactFirstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    contactLastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    contactEmailAddress: Yup.string().email('Invalid email').required('Required'),
    contactPhoneNumber: Yup.string().min(10, 'Must be 10 digits').required('Required'),
});

const PageContainer = styled.div`
    padding: ${scale.xl} 0;
`;

const newCompanyDefaults = {
    name: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmailAddress: '',
    contactPhoneNumber: '',
    welcome: '',
};

const CompanyForm: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const company = router.location.state as RouterState;

    const [loading, setLoading] = useState<boolean>(false);
    const [companyToVew] = useState<Company>(company);

    const auth: Auth = storage.get('auth');
    const headers = {
        SECRET: auth?.secret,
        PASSPHRASE: auth?.passPhrase,
    };

    return (
        <StandardLayout page="companyForm">
            <PageContainer>
                <BackButton />
                <CustomCard title={<Text size="22">{company ? 'Update Company' : 'New Company'}</Text>} formWidth>
                    <Formik
                        initialValues={companyToVew || newCompanyDefaults}
                        onSubmit={async (values) => {
                            setLoading(true);
                            try {
                                if (company) {
                                    const { id } = companyToVew;

                                    await Axios.patch(api.company, { ...values, id }, { headers }).then(
                                        ({ status }) => {
                                            if (status === 200) {
                                                notification.success({
                                                    message: 'Company updated',
                                                });
                                            }
                                        },
                                    );
                                }
                                if (!company) {
                                    await Axios.post(api.company, values, { headers }).then(({ status }) => {
                                        if (status === 200) {
                                            return notification.success({
                                                message: 'New company created',
                                            });
                                        }
                                    });
                                }
                            } catch (err) {
                                error(err, 'CompanyForm.tsx');
                            } finally {
                                queryClient.invalidateQueries('companies');
                                setLoading(false);
                                router.history.goBack();
                            }
                        }}
                        validationSchema={FormSchema}
                    >
                        {({ errors, touched, submitForm, values: { contactPhoneNumber } }) => (
                            <StyledForm>
                                <FormikLabel
                                    required
                                    error={errors.name}
                                    touched={touched.name}
                                    field={<FormikField name="name" />}
                                    fieldLabel="Company Name"
                                />
                                <FormikLabel
                                    error={errors.welcome}
                                    touched={touched.welcome}
                                    field={<FormikField name="welcome" />}
                                    fieldLabel="Welcome Message"
                                />
                                <FormikLabel
                                    required
                                    error={errors.contactFirstName}
                                    touched={touched.contactFirstName}
                                    field={<FormikField name="contactFirstName" />}
                                    fieldLabel="Contact First Name"
                                />
                                <FormikLabel
                                    required
                                    error={errors.contactLastName}
                                    touched={touched.contactLastName}
                                    field={<FormikField name="contactLastName" />}
                                    fieldLabel="Contact Last Name"
                                />
                                <FormikLabel
                                    required
                                    error={errors.contactPhoneNumber}
                                    touched={touched.contactPhoneNumber}
                                    field={
                                        <FormikField
                                            maxLength={contactPhoneNumber?.startsWith('+') ? 15 : 10}
                                            name="contactPhoneNumber"
                                            value={
                                                contactPhoneNumber?.startsWith('+')
                                                    ? contactPhoneNumber?.replace(/(\d{2})(\d{4})(\d{6})/, '$1 $2 $3')
                                                    : contactPhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            }
                                        />
                                    }
                                    fieldLabel="Contact Phone Number"
                                />
                                <FormikLabel
                                    required
                                    error={errors.contactEmailAddress}
                                    touched={touched.contactEmailAddress}
                                    field={<FormikField name="contactEmailAddress" />}
                                    fieldLabel="Contact Email"
                                />
                                <FormFooter>
                                    <Button
                                        loading={loading}
                                        size="large"
                                        style={{ color: colors.white, backgroundColor: colors.red }}
                                        onClick={submitForm}
                                    >
                                        {company ? 'Update' : 'Submit'}
                                    </Button>
                                </FormFooter>
                            </StyledForm>
                        )}
                    </Formik>
                </CustomCard>
            </PageContainer>
        </StandardLayout>
    );
};
export default CompanyForm;
