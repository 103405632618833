import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import scale from '../../constants/scale';
import { PeriodType } from '../../pages/Onboarding/OnboardingWizard';
import Text from '../Text';
import { ListContainer, TextContainer, OptionCard } from './ChooseGroupType';
import colors from '../../constants/colors';
const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: repeat(3, 1fr);
`;

const SampleDescription = styled(TextContainer)`
    grid-row-start: span 3;
`;

const SampleOptionCard = styled(OptionCard)`
    display: grid;
    grid-gap: ${scale.xl};
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(2, min-content);
`;

const CardTitleContainer = styled.div`
    grid-column-start: span 2;
`;

const ChooseSamplePeriod: React.FC<{ onClick: (selected: PeriodType) => void }> = ({ onClick }) => {
    return (
        <Container>
            <SampleDescription>
                <Text bold>With any sample period you choose, keep in mind:</Text>
                <ListContainer>
                    <li>
                        <Text>
                            Groups need a minimum of six members to qualify as a group and provide aggregated data. This
                            is so that we can maintain an anonymous reporting system and provide members with the safety
                            to know that their individual responses can’t be traced back to them.
                        </Text>
                    </li>
                    <li>
                        <Text>Members can anonymously opt-in or opt-out at any time.</Text>
                    </li>
                    <li>
                        <Text>Members can anonymously opt back in after they’ve opted out.</Text>
                    </li>
                    <li>
                        <Text>
                            You can choose to sample your members daily, or you can choose days of the week that align
                            with their work schedule.
                        </Text>
                    </li>
                    <li>
                        <Text>You’ll be able to select the time windows of the day to gather samples.</Text>
                    </li>
                    <li>
                        <Text>Don’t worry! You can always change your sample periods later.</Text>
                    </li>
                </ListContainer>
            </SampleDescription>
            <SampleOptionCard>
                <CardTitleContainer>
                    <Text bold>Option 1 – Ongoing sampling</Text>
                </CardTitleContainer>
                <Text>You can choose to check in on your organizations’s emotional state on an ongoing basis.</Text>
                <Button
                    onClick={() => onClick('ongoing')}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                >
                    Choose Ongoing
                </Button>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <Text bold>Option 2 – Quarterly sample periods</Text>
                </CardTitleContainer>
                <Text>
                    For a comparison between quarters, you can choose to sample your members for a 30-day period each
                    quarter.
                </Text>
                <Button
                    onClick={() => onClick('quarterly')}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                >
                    Choose Quarterly
                </Button>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <Text bold>Option 3 – On Demand</Text>
                </CardTitleContainer>
                <Text>
                    If you don’t want to have a regularly automated sample period, you can choose to create one
                    on-demand instead.
                </Text>
                <Button
                    onClick={() => onClick('onDemand')}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                >
                    Choose On Demand
                </Button>
            </SampleOptionCard>
        </Container>
    );
};

export default ChooseSamplePeriod;
