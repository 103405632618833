import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import error from '../utilities/error';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const projects = {
    get: async () => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.project, {
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    getByCompany: async ({ queryKey }: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const companyId = queryKey[1];
        const params = { companyId };

        const { data } = await axios
            .get(api.project, {
                headers,
                params,
            })
            .catch((err) => {
                //error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    getTextResults: async ({ queryKey }: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const projectId = queryKey[1];
        const results = 'text results';
        const params = { projectId, results };

        const { data } = await axios
            .get(api.project, {
                headers,
                params,
            })
            .catch((err) => {
                //error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    getTableauResults: async (projectId: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const results = 'tableau results';
        const params = { projectId, results };

        const { data } = await axios
            .get(api.project, {
                headers,
                params,
            })
            .catch((err) => {
                //error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    create: async (project: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .post(api.project, project, {
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    duplicate: async (project: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        project.name += ' -COPY-';

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .post(api.project, project, {
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
    delete: async (idToDelete: string) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .delete(api.project, {
                params: {
                    id: idToDelete,
                },
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });
        return data;
    },
    enableDisable: async (project: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        //reset the enabled flag
        project.enabled = !project.enabled;

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(api.project, project, {
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });
        return data;
    },
    update: async (project: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return project;
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(api.project, project, {
                headers,
            })
            .catch((err) => {
                error(err, 'projects.ts');
                throw new Error(err);
            });

        return data;
    },
};

export default projects;
