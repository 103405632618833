import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import useRouter from '../../hooks/useRouter';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import { useQuery } from 'react-query';
import pulse from '../../query-functions/pulse';
import moment from 'moment';

const ResultsBodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
    padding: ${scale.md};
`;

const ChartContainer = styled.div`
    width: 100%;
    height: 450px;
`;

const FeelingsColor = [
    '#800B00',
    '#130240',
    '#610D80',
    '#5E20FF',
    '#6F8000',
    '#C21FFF',
    '#797779',
    '#2F0D80',
    '#002280',
    '#015A80',
    '#ffffff',
    '#0247FE',
    '#03B3FF',
    '#FF0358',
    '#A6013A',
    '#8612AF',
    '#FB9902',
    '#FD5308',
    '#FABC02',
    '#FEFE33',
    '#CBEB00',
];

const MobileResults: React.FC = () => {
    const router = useRouter();
    const [question, setQuestion] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [responseRate, setResponseRate] = useState<number>(0);
    const [bubbleData, setBubbleData] = useState<any>([]);
    const [pulseData, setPulseData] = useState<any>(new Object());

    //TODO set up with text msg page
    const { id } = router.query;

    const pulseQuery = useQuery(['pulse', id], pulse.get);

    useEffect(() => {
        const { isSuccess, isError } = pulseQuery;

        if (isError) {
            router.push('/');
        }

        if (isSuccess) {
            setBubbleData([]);
            setPulseData(pulseQuery.data);
            setQuestion(pulseQuery.data.question);
            setStartDate(moment(pulseQuery.data.dateStart).format('LLLL'));
            setResponseRate(pulseQuery.data.responseRate);

            //do we have any data?
            //yes
            if (pulseQuery.data && pulseQuery.data.results) {
                pulseQuery.data.results.forEach((pulseResults: any) => {
                    setBubbleData((prev: any) =>
                        prev.concat({
                            name: pulseResults.emotion,
                            value: pulseResults.numericValue + 11 * pulseResults.count,
                            color: FeelingsColor[pulseResults.numericValue + 10],
                        }),
                    );
                });
            }
        }
    }, [pulseQuery.data]);

    useEffect(() => {
        //bubble chart set up
        const chart = am4core.create('BubbleChart', am4plugins_forceDirected.ForceDirectedTree);
        const bubbleSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());

        //bubble chart data
        chart.data = bubbleData;

        //Bubble chart styles setup
        bubbleSeries.dataFields.color = 'color';
        bubbleSeries.dataFields.value = 'value';
        bubbleSeries.dataFields.name = 'name';
        bubbleSeries.nodes.template.label.text = '{name}';
        bubbleSeries.fontSize = 12;
        bubbleSeries.minRadius = 15;
        bubbleSeries.maxRadius = 70;
        return () => {
            chart.dispose();
        };
    }, [bubbleData]);

    return (
        <ResultsBodyWrapper>
            <Text size="18">{question}</Text>
            <Text size="18" hidden={bubbleData.length === 0}>
                How people are feeling:
            </Text>
            <ChartContainer hidden={bubbleData.length === 0}>
                <div id="BubbleChart"></div>
            </ChartContainer>
            <Text size="18" hidden={bubbleData.length === 0}>
                {startDate}
            </Text>
            <Text size="18" hidden={bubbleData.length === 0}>{`${responseRate}% Reporting`}</Text>
            <Text size="18" hidden={bubbleData.length !== 0}>
                Check back again as not enough people have responded for this to be anonymous.
            </Text>
        </ResultsBodyWrapper>
    );
};

export default MobileResults;
