import React from 'react';
import styled from 'styled-components';
import scale from '../constants/scale';
import moment from 'moment';

const StyledFooter = styled.div`
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: ${scale.md};
    width: 100%;

    @media print {
        display: none;
    }
`;

const Footer = () => (
    <StyledFooter>
        <span>&copy;</span> {moment().format('YYYY')} - Feelalytics, Inc. All rights reserved
    </StyledFooter>
);

export default Footer;
