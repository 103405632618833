/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import colors from '../constants/colors';

const NotFound: React.FC = () => {
    const history = useHistory();

    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button
                        onClick={() => history.push('/')}
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Back Home
                    </Button>
                }
            />
        </>
    );
};

export default NotFound;
