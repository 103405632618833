import React, { useState, useEffect } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import styled from 'styled-components';
import StandardLayout from '../layout/StandardLayout';
import CustomCard from '../components/CustomCard';
import { FormFooter, StyledForm } from '../components/Styled/Form';
import Text from '../components/Text';
import scale from '../constants/scale';
import { Formik } from 'formik';
import FormikLabel from '../components/FormikLabel';
import FormikField from '../components/FormikField';
import * as Yup from 'yup';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import user from '../query-functions/user';
import SHORTNotification from '../components/SHORTNotification';
import colors from '../constants/colors';

export type UserProfile = {
    firstName: string;
    lastName: string;
    department: string;
    emailAddress: string;
    phoneNumber: string;
};

const FormSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    phoneNumber: Yup.string().min(10, 'Must be 10 digits').required('Required'),
    emailAddress: Yup.string().email('Invalid email').required('Required'),
});

const PageContainer = styled.div`
    padding: ${scale.xl} 0;
`;

const LoadingContainer = styled.div`
    width: 100%;
    min-height: 460px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EditProfile: React.FC = () => {
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(true);
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<UserProfile>({} as UserProfile);

    const query = useQuery('user', user.get);

    const updateMutation = useMutation(user.update, {
        onSuccess: () => {
            SHORTNotification('Profile updated');
            queryClient.invalidateQueries('user');
        },
    });

    useEffect(() => {
        const { data, isLoading } = query;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
        if (data) setInitialValues(data);
    }, [query.data, query.isLoading]);

    useEffect(() => {
        const { isLoading } = updateMutation;

        if (isLoading) setRequestLoading(true);
        if (!isLoading) setRequestLoading(false);
    }, [updateMutation.isLoading]);

    return (
        <StandardLayout page="myProfile">
            <PageContainer>
                <CustomCard title={<Text size="22">My Profile</Text>} formWidth>
                    {loading ? (
                        <LoadingContainer>
                            <Spin />
                        </LoadingContainer>
                    ) : (
                        <>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={async (values) => {
                                    updateMutation.mutate(values);
                                }}
                                validationSchema={FormSchema}
                            >
                                {({ handleSubmit, errors, touched, values: { phoneNumber } }) => (
                                    <StyledForm onSubmit={handleSubmit}>
                                        <FormikLabel
                                            required
                                            fieldLabel="First Name"
                                            field={<FormikField name="firstName" />}
                                            error={errors.firstName}
                                            touched={touched.firstName}
                                        />
                                        <FormikLabel
                                            required
                                            fieldLabel="Last Name"
                                            field={<FormikField name="lastName" />}
                                            error={errors.lastName}
                                            touched={touched.lastName}
                                        />
                                        <FormikLabel
                                            fieldLabel="Department"
                                            field={<FormikField name="department" />}
                                            error={errors.department}
                                            touched={touched.department}
                                        />
                                        <FormikLabel
                                            required
                                            fieldLabel="Email"
                                            field={<FormikField name="emailAddress" />}
                                            error={errors.emailAddress}
                                            touched={touched.emailAddress}
                                        />
                                        <Tooltip
                                            placement="topLeft"
                                            title="To change your phone number please contact Feelalytics Support"
                                        >
                                            {/* This div allows the tooltip to render over the label/input */}
                                            <div>
                                                <FormikLabel
                                                    fieldLabel="Phone"
                                                    field={
                                                        <FormikField
                                                            disabled
                                                            name="phoneNumber"
                                                            value={phoneNumber?.replace(
                                                                /(\d{3})(\d{3})(\d{4})/,
                                                                '($1) $2-$3',
                                                            )}
                                                        />
                                                    }
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                />
                                            </div>
                                        </Tooltip>
                                        <FormFooter>
                                            <Button
                                                loading={requestLoading}
                                                size="large"
                                                style={{ color: colors.white, backgroundColor: colors.red }}
                                                htmlType="submit"
                                            >
                                                Update
                                            </Button>
                                        </FormFooter>
                                    </StyledForm>
                                )}
                            </Formik>
                        </>
                    )}
                </CustomCard>
            </PageContainer>
        </StandardLayout>
    );
};
export default EditProfile;
