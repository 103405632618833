import { notification } from 'antd';

const SHORTNotification = (message: string) => {
    notification.success({
        message: 'Success!',
        description: message,
        duration: 2.5,
    });
};

export default SHORTNotification;
