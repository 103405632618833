import { useState, useEffect } from 'react';

const usePhoneFormatter = () => {
    const [formatted, setFormatted] = useState<string>('');
    const [num, setNumber] = useState<string>('');

    useEffect(() => {
        //international number?
        //yes
        if (num?.startsWith('+')) {
            setNumber(num?.trim());
            setFormatted(num?.replace(/(\d{2})(\d{4})(\d{6})/, '$1 $2 $3'));
        }
        //international number?
        //no
        else {
            setNumber(num?.replace(/\D/, ''));
            setFormatted(num?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'));
        }

        return () => {
            setNumber('');
            setFormatted('');
        };
    }, [num]);

    return { formatted, setNumber, num };
};

export default usePhoneFormatter;
