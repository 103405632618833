import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import { QueryFunctionContext } from 'react-query';
import storage from '../utilities/localStorage';
import error from '../utilities/error';

export type PulseData = {
    companyId: string;
    projectId: string;
    groups: GroupsArray[];
    question?: string;
    dateStart: string;
    mentalHealthQuestion: boolean;
    announcementOnly: boolean;
    yesNoOnly: boolean;
};

export type GroupsArray = {
    id: string;
    name: string;
};

const pulse = {
    get: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret !== 'undefined' ? auth?.secret : 'RESULTS',
            PASSPHRASE: auth?.passPhrase !== 'undefined' ? auth?.passPhrase : 'PASSPHRASE',
        };

        const { data } = await axios
            .get(api.pulse, {
                params: {
                    id: queryKey[1],
                },
                headers,
            })
            .catch((err) => {
                error(err, 'pulse.ts');
                throw new Error(err);
            });
        return data;
    },
    createProjectPulse: async (pulseData: PulseData) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .post(api.pulse, pulseData, {
                headers,
            })
            .catch((err) => {
                error(err, 'pulse.ts');
                throw new Error(err);
            });
        return data;
    },
    updateProjectPulse: async (pulseData: any) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(api.pulse, pulseData, {
                headers,
            })
            .catch((err) => {
                error(err, 'pulse.ts');
                throw new Error(err);
            });
        return data;
    },
};

export default pulse;
