import React, { useState } from 'react';
import { Button, message, Radio } from 'antd';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';
import { OptionCard } from './ChooseGroupType';
import UploadExample from '../../assets/images/onbording/Group3235.png';
import Modal from 'antd/lib/modal/Modal';
/*import { UploadOutlined, UsergroupAddOutlined } from '@ant-design/icons';*/
import FeelalyticsLogo from '../FeelalyticsLogo';
import LargeInput from '../LargeInput';
import MemberUploadModal from '../MemberUploadModal';

type FormState = {
    [key: string]: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

const StyledImage = styled.img`
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    width: 100%;
`;

const StyledOptionCard = styled(OptionCard)`
    display: flex;
    flex-direction: column;
    gap: ${scale.xl};
`;

const StyledRadio = styled(Radio)`
    display: block;
    font-size: 18px;
    color: ${colors.darkBlue};
    margin: ${scale.md} 0;
`;

const FullRowContainer = styled.div`
    grid-column-start: span 2;
`;

const CustomFooter = styled(FullRowContainer)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Link = styled.a`
    color: ${colors.darkBlue};
    text-decoration: underline;
`;

const AddMembers: React.FC = () => {
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [signupModal, setSignupModal] = useState<boolean>(false);
    const [addType, setAddType] = useState<'single' | 'bulk' | null>(null);
    const [formData, setFormData] = useState<FormState>({ firstName: '', lastName: '', phoneNumber: '', email: '' });

    const handleNext = () => {
        if (!addType) return message.error('Please select an option');
        setSignupModal(true);
    };

    const validateForm = () => {
        setUploadModal(true);
        const { firstName, lastName, phoneNumber, email } = formData;
        if (firstName.length < 1) return message.error('First Name cannot be empty');
        if (lastName.length < 1) return message.error('Last Name cannot be empty');
        if (phoneNumber.length !== 10) return message.error('Phone number must be 10 digits');
        if (email.length < 1 || !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
            return message.error('Email address must be valid');
        }
    };

    return (
        <Container>
            <StyledOptionCard>
                <Text bold>Do you want to add one member at a time or bulk upload a .xlsx or .csv?</Text>
                <Radio.Group size="large" onChange={(event) => setAddType(event.target.value)}>
                    <StyledRadio value="single">One at a time</StyledRadio>
                    <StyledRadio value="bulk">Bulk upload</StyledRadio>
                </Radio.Group>
                <div>
                    <Text size="14" bold>
                        Keep in mind:
                    </Text>
                    <ul>
                        <li>
                            <Text size="14">
                                Even though there is personally identifiable information in the system, every sample is
                                anonymized and aggregated for member privacy.
                            </Text>
                        </li>
                    </ul>
                </div>
                <Button onClick={handleNext} size="large" style={{ color: colors.white, backgroundColor: colors.red }}>
                    Next
                </Button>
            </StyledOptionCard>
            <StyledImage src={UploadExample} />
            <Modal
                footer={null}
                centered
                onCancel={() => setSignupModal(false)}
                visible={signupModal}
                width={900}
                bodyStyle={{
                    display: 'grid',
                    gridTemplateRows: 'auto',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridGap: scale.xl,
                    padding: scale.xl,
                }}
            >
                <FullRowContainer>
                    <FeelalyticsLogo />
                </FullRowContainer>
                <FullRowContainer>
                    <Text size="28">We are so excited you’re ready to calculate your NEI!</Text>
                    <Text size="28">We need a few more details:</Text>
                </FullRowContainer>
                <LargeInput
                    label="First Name"
                    required
                    onChange={(event) =>
                        setFormData((prev) => {
                            return { ...prev, firstName: event.target.value };
                        })
                    }
                />
                <LargeInput
                    label="Last Name"
                    required
                    onChange={(event) =>
                        setFormData((prev) => {
                            return { ...prev, lastName: event.target.value };
                        })
                    }
                />
                <LargeInput
                    label="Phone Number"
                    required
                    onChange={(event) =>
                        setFormData((prev) => {
                            return { ...prev, phoneNumber: event.target.value };
                        })
                    }
                />
                <LargeInput
                    label="Email"
                    required
                    onChange={(event) =>
                        setFormData((prev) => {
                            return { ...prev, email: event.target.value };
                        })
                    }
                />
                <CustomFooter>
                    <Text size="14">
                        By clicking the Next button you agree to the Feelalytics <Link>terms and conditions</Link>.
                    </Text>
                    <Button
                        style={{ color: colors.white, backgroundColor: colors.red }}
                        size="large"
                        onClick={validateForm}
                    >
                        Next
                    </Button>
                </CustomFooter>
            </Modal>
            <MemberUploadModal loading={false} visible={uploadModal} onCancel={() => setUploadModal(false)} />
        </Container>
    );
};

export default AddMembers;
