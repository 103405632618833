import React from 'react';
import colors from '../constants/colors';
import styled from 'styled-components';

type TextTypes = {
    size?: '100' | '64' | '50' | '28' | '22' | '18' | '14' | '12' | '10' | '8';
    color?: 'red' | 'blue' | 'white' | 'gray';
    disabled?: boolean;
    bold?: boolean;
    center?: boolean;
    hidden?: boolean;
};

const StyledText = styled.p<TextTypes>`
    font-family: 'Roboto', sans-serif;
    text-transform: none;
    text-align: ${({ center }) => (center ? 'center' : null)};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    font-size: ${({ size }) => size}px;
    margin: 0;
    opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
    display: ${({ hidden }) => (hidden ? 'none' : '')};
    color: ${({ color }) => {
        switch (color) {
            case 'red':
                return colors.red;
            case 'white':
                return colors.white;
            case 'gray':
                return colors.darkGray;
            case 'blue':
                return colors.darkBlue;
            default:
                break;
        }
    }};
`;

const Text: React.FC<TextTypes> = ({
    children,
    bold = false,
    color = 'blue',
    size = '18',
    center = false,
    disabled = false,
    hidden = false,
}) => (
    <StyledText disabled={disabled} color={color} size={size} bold={bold} center={center} hidden={hidden}>
        {children}
    </StyledText>
);

export default Text;
