import React, { useEffect, useState } from 'react';
import { Button, Card, Popconfirm, Space, Typography, notification } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import StandardLayout from '../../layout/StandardLayout';
import ScreenHeader from '../../components/ScreenHeader';
import error from '../../utilities/error';
import Auth from '../../types/auth';
import storage from '../../utilities/localStorage';
import api from '../../constants/api';
import useRouter from '../../hooks/useRouter';
import colors from '../../constants/colors';

type ErrorTypes = {
    [key: string]: any;
    dateAdded: string;
    id: string;
    message: string;
    source: string;
    innerException: string;
    stackTrace: string;
};

const StyledCard = styled(Card)`
    font-size: 16px;
`;

const { Text } = Typography;

const ErrorView: React.FC = () => {
    const router = useRouter();

    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [errorData, setErrorData] = useState<ErrorTypes>({} as ErrorTypes);

    const auth: Auth = storage.get('auth');

    const headers = {
        SECRET: auth?.secret,
        PASSPHRASE: auth?.passPhrase,
    };

    const { errorId } = router.query;

    useEffect(() => {
        (async () => {
            setDataLoading(true);
            try {
                await Axios.get(api.error, {
                    params: { id: errorId },
                    headers,
                }).then(({ data }) => {
                    setErrorData(data);
                });
            } catch (err) {
                error(err, 'ErrorView.tsx');
            } finally {
                setDataLoading(false);
            }
        })();
    }, []);

    const deleteError = async () => {
        setDeleteLoading(true);
        try {
            await Axios.delete(api.error, {
                params: { id: errorId },
                headers,
            }).then(({ status }) => {
                if (status === 200) {
                    return notification.success({
                        message: 'Error successfully deleted',
                    });
                }
                notification.error({
                    message: 'An error has occured. Please try again',
                });
            });
        } catch (err) {
            notification.error({
                message: 'An error has occurred',
                description: 'If the error persists please contact Feelalytics',
            });
            error(err, 'ErrorView.tsx');
        } finally {
            setDeleteLoading(false);
            router.history.goBack();
        }
    };

    const DeleteButton = () => (
        <Popconfirm
            title="Are you sure you want to remove this error?"
            okText="Yes"
            cancelText="No"
            onConfirm={deleteError}
        >
            <Button loading={deleteLoading} size="small" style={{ color: colors.white, backgroundColor: colors.red }}>
                Delete
            </Button>
        </Popconfirm>
    );

    return (
        <StandardLayout page="errorView">
            <ScreenHeader showBack title="Error" />
            <StyledCard loading={dataLoading} title={<DeleteButton />}>
                <Space direction="vertical">
                    <Text>
                        <Text code strong>
                            Date:
                        </Text>
                        {moment(errorData.dateAdded).format('LLLL')}
                    </Text>
                    <Text>
                        <Text code strong>
                            Source:
                        </Text>
                        {errorData.source}
                    </Text>
                    <Text>
                        <Text code strong>
                            Message:
                        </Text>
                        {errorData.message}
                    </Text>
                    <Text>
                        <Text code strong>
                            StackTrace:
                        </Text>
                        {errorData.stackTrace || 'Null'}
                    </Text>
                    <Text>
                        <Text code strong>
                            InnerException:
                        </Text>
                        {errorData.innerException || 'Null'}
                    </Text>
                </Space>
            </StyledCard>
        </StandardLayout>
    );
};

export default ErrorView;
