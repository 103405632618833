import { Button, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';

const Container = styled.div`
    display: grid;
    max-width: 628px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

const OptionCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.lg};
    background-color: ${colors.white};
    padding: ${scale.lg};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const StyledInput = styled(Input)`
    width: 580px;
    padding: ${scale.md};
    color: ${colors.darkBlue};
    font-size: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    ::placeholder {
        color: ${colors.darkGray};
        font-size: 24px;
    }
`;

const StyledButton = styled(Button)`
    width: 194px;
    align-self: flex-end;
`;

const ChooseGroupName: React.FC<{ onChange: (text: string) => void; onClick: () => void }> = ({
    onChange,
    onClick,
}) => {
    return (
        <Container>
            <OptionCard>
                <Text bold>Let’s name your group!</Text>
                <StyledInput
                    placeholder="Type your group name here"
                    onChange={(event) => onChange(event.target.value)}
                />
                <StyledButton
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                    onClick={() => onClick()}
                >
                    Next
                </StyledButton>
            </OptionCard>
        </Container>
    );
};

export default ChooseGroupName;
