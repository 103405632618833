import React from 'react';
import { Image } from 'antd';
import logo from '../assets/images/logo.svg';
import useRouter from '../hooks/useRouter';
import styled from 'styled-components';

const StyledImage = styled(Image)`
    :hover {
        cursor: pointer;
    }
`;

const FeelalyticsLogo: React.FC = () => {
    const router = useRouter();

    return (
        <StyledImage
            onClick={() => router.push('/')}
            preview={false}
            alt="Feelalytics Logo"
            src={logo}
            width={200}
            height={40}
        />
    );
};
export default FeelalyticsLogo;
