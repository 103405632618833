import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import scale from '../constants/scale';

type CustomCard = {
    title?: React.ReactNode;
    extra?: React.ReactNode;
    footer?: React.ReactNode;
    formWidth?: boolean;
};

const Card = styled.div<{ formWidth: boolean }>`
    border-radius: 2px;
    background-color: ${colors.white};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: ${scale.lg};
    margin-top: ${scale.lg};
    max-width: ${({ formWidth }) => (formWidth ? 1000 : null)}px;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: ${scale.md};
    margin-bottom: ${scale.md};
    border-bottom: 1px solid ${colors.lightGray};
`;

const HeaderLeft = styled.div`
    justify-self: start;
`;

const HeaderRight = styled.div`
    justify-self: end;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: ${scale.md};
    margin-top: ${scale.md};
    border-top: 1px solid ${colors.lightGray};
`;

const CustomCard: React.FC<CustomCard> = ({ children, title, extra, footer, formWidth = false }) => {
    return (
        <Card formWidth={formWidth}>
            {title || extra ? (
                <Header>
                    <HeaderLeft>{title}</HeaderLeft>
                    <HeaderRight>{extra}</HeaderRight>
                </Header>
            ) : null}
            {children}
            {footer && <Footer>{footer}</Footer>}
        </Card>
    );
};

export default CustomCard;
