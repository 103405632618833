import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import useRouter from '../../hooks/useRouter';
import { useQuery } from 'react-query';
import projectstats from '../../query-functions/projectstats';
import Project from '../../types/project';
import { Result, Button } from 'antd';

const ResultsBodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
    padding: ${scale.md};
`;

const ProjectStats: React.FC = () => {
    const router = useRouter();
    const { project } = router.location.state as { project: Project };

    console.log('project', project);

    const projectstatsQuery = useQuery(['projectstats', project.id], projectstats.getByProject);

    useEffect(() => {
        const { isSuccess, isError } = projectstatsQuery;

        if (isError) {
            router.push('/');
        }

        if (isSuccess) {
            const csvData = new Blob([projectstatsQuery.data], { type: 'text/csv' });
            const csvURL = URL.createObjectURL(csvData);
            const link = document.createElement('a');
            link.href = csvURL;
            link.download = `${project.name}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            router.push('/');
        }
    }, [projectstatsQuery.data]);

    return (
        <>
            <Result
                title="Downloading Statistics"
                subTitle="Please wait while we download your project statistics file"
            />
        </>
    );
};

export default ProjectStats;
