import React, { useEffect, useState } from 'react';
import { Button, Table } from 'antd';
import Text from '../../components/Text';
import styled from 'styled-components';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { useQuery } from 'react-query';
import projects from '../../query-functions/projects';
import scale from '../../constants/scale';
import colors from '../../constants/colors';
import Project from '../../types/project';
import TextResponse from '../../types/textResponse';
import moment from 'moment';
import shadow from '../../constants/shadow';
import BackButton from '../../components/BackButton';

const TitleContainer = styled.div`
    padding: ${scale.lg};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    > :nth-child(1) {
        flex-grow: 1;
    }
    > :nth-child(2) {
        margin-right: 20px;
    }

    @media print {
        display: none;
    }
`;

const StyledTableContainer = styled.div`
    background-color: ${colors.white};
    ${shadow}
`;

const Responses: React.FC = () => {
    const authenticated = useIsAuthenticated();
    const router = useRouter();

    const { project } = router.location.state as { project: Project };
    const { column } = router.location.state as { column: string };

    const [textResponseData, setTextResponseData] = useState<TextResponse[]>([]);
    const [queryLoading, setQueryLoading] = useState<boolean>(false);
    const query = useQuery(['projects', project.id], projects.getTextResults);

    useEffect(() => {
        if (authenticated) return;
        router.push('/login');
    }, [authenticated, router]);

    useEffect(() => {
        const { data, isLoading } = query;

        if (isLoading) setQueryLoading(true);
        if (!isLoading) setQueryLoading(false);
        if (data) {
            setTextResponseData(data);
        }
    }, [query.data, query.isLoading]);

    return (
        <StandardLayout page="responses">
            <TitleContainer>
                <Text size="28">Responses</Text>
                <BackButton noSpacing columnState={column} />
            </TitleContainer>
            <StyledTableContainer>
                <Table
                    columns={[
                        {
                            title: 'Response',
                            dataIndex: 'text',
                            width: 500,
                        },
                        {
                            title: 'Received Date',
                            dataIndex: 'dateAdded',
                            width: 120,
                            align: 'center',
                            render: (value) => moment(value).local().format('M/DD/YY'),
                        },
                        {
                            title: 'Time',
                            dataIndex: 'dateAdded',
                            width: 90,
                            align: 'left',
                            render: (value) => moment(value).local().format('h:mm a'),
                        },
                    ]}
                    loading={queryLoading}
                    pagination={false}
                    dataSource={textResponseData}
                    rowKey={(record) => record.id}
                />
            </StyledTableContainer>
        </StandardLayout>
    );
};

export default Responses;
