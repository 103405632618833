import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';

type RemoveTypes = {
    id: string;
};

const errors = {
    get: async () => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.error, {
                headers,
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
    remove: async ({ id }: RemoveTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .delete(api.error, {
                params: {
                    id,
                },
                headers,
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
};

export default errors;
