import { PhoneOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import usePhoneFormatter from '../../hooks/usePhoneFormatter';
import Text from '../Text';

type PhoneCard = {
    values: any;
    setFieldValue: any;
    handleSubmit: () => void;
    loading: boolean;
};

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;

const PhoneCard: React.FC<PhoneCard> = ({ values, setFieldValue, handleSubmit, loading }) => {
    const { num, setNumber, formatted } = usePhoneFormatter();

    React.useEffect(() => {
        setFieldValue('contactPhoneNumber', num);
    }, [num]);

    return (
        <Card>
            <Text size="22" center>
                Register
            </Text>

            <Input
                style={{ width: 245 }}
                disabled={loading}
                prefix={<PhoneOutlined />}
                value={formatted}
                placeholder="PHONE NUMBER"
                maxLength={14}
                onChange={(e) => {
                    setNumber(e.target.value);
                }}
            />

            <PositionedButton onClick={handleSubmit} type="primary" style={{ color: colors.white }} danger>
                Get Started
            </PositionedButton>
        </Card>
    );
};

export default PhoneCard;
