import React from 'react';

const NEILogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="190.603" height="45.834" viewBox="0 0 190.603 45.834">
        <g id="Group_3207" data-name="Group 3207" transform="translate(-30.549 -219.704)">
            <path
                id="Path_1732"
                data-name="Path 1732"
                d="M84.118,264.879l-.713-.936V219.7h34.572v8.971H93.423v9.3H115.03v8.97H93.423v9.626H118.3v8.97H84.609Z"
                transform="translate(-21.922)"
                fill="#2d3047"
            />
            <g id="Group_3187" data-name="Group 3187" transform="translate(114.655 219.725)">
                <path
                    id="Path_1733"
                    data-name="Path 1733"
                    d="M183.743,219.74h1.418v12.893H184l-8.325-10.572v10.572h-1.418V219.74h1.362l8.123,10.333Z"
                    transform="translate(-174.257 -219.74)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1734"
                    data-name="Path 1734"
                    d="M206.865,221.066H199v4.4h7.036v1.326H199v4.513h7.957v1.326h-9.412V219.74h9.32Z"
                    transform="translate(-183.916 -219.74)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1735"
                    data-name="Path 1735"
                    d="M221.267,232.633h-1.455V221.085h-4.329V219.74H225.6v1.345h-4.329Z"
                    transform="translate(-191.356 -219.74)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1736"
                    data-name="Path 1736"
                    d="M183.577,249.177h-7.865v4.4h7.036V254.9h-7.036v4.513h7.957v1.326h-9.412V247.85h9.32Z"
                    transform="translate(-174.257 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1737"
                    data-name="Path 1737"
                    d="M199.957,257.206h-.074l-4.7-6.888v10.425h-1.418V247.85h1.474l4.7,7.036,4.7-7.036h1.474v12.893h-1.455V250.3Z"
                    transform="translate(-182.35 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1738"
                    data-name="Path 1738"
                    d="M231.209,256.673a6.661,6.661,0,0,1-1.344,2.127,6.407,6.407,0,0,1-2.081,1.464,6.958,6.958,0,0,1-5.369.009,6.343,6.343,0,0,1-3.408-3.564,6.979,6.979,0,0,1-.469-2.551,6.86,6.86,0,0,1,.479-2.551,6.675,6.675,0,0,1,1.345-2.127,6.408,6.408,0,0,1,2.081-1.464,6.962,6.962,0,0,1,5.369-.009,6.349,6.349,0,0,1,3.407,3.564,6.968,6.968,0,0,1,.47,2.551A6.845,6.845,0,0,1,231.209,256.673Zm-1.409-4.6a5.054,5.054,0,0,0-2.671-2.836,4.845,4.845,0,0,0-2.035-.424,4.945,4.945,0,0,0-2.036.414,4.8,4.8,0,0,0-1.593,1.133,5.3,5.3,0,0,0-1.041,1.685,5.648,5.648,0,0,0-.378,2.072,5.719,5.719,0,0,0,.378,2.082,5.049,5.049,0,0,0,2.671,2.837,4.857,4.857,0,0,0,2.036.423,4.944,4.944,0,0,0,2.035-.414,4.788,4.788,0,0,0,1.594-1.132,5.3,5.3,0,0,0,1.041-1.686,5.659,5.659,0,0,0,.378-2.072A5.737,5.737,0,0,0,229.8,252.077Z"
                    transform="translate(-192.622 -231.242)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1739"
                    data-name="Path 1739"
                    d="M247.389,260.743h-1.456V249.195H241.6V247.85h10.112v1.345h-4.328Z"
                    transform="translate(-202.189 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1740"
                    data-name="Path 1740"
                    d="M263.675,247.85v12.893h-1.456V247.85Z"
                    transform="translate(-210.739 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1741"
                    data-name="Path 1741"
                    d="M281.28,256.673a6.673,6.673,0,0,1-1.345,2.127,6.407,6.407,0,0,1-2.081,1.464,6.958,6.958,0,0,1-5.369.009,6.339,6.339,0,0,1-3.407-3.564,6.968,6.968,0,0,1-.47-2.551,6.849,6.849,0,0,1,.479-2.551,6.674,6.674,0,0,1,1.344-2.127,6.414,6.414,0,0,1,2.081-1.464,6.962,6.962,0,0,1,5.369-.009,6.344,6.344,0,0,1,3.407,3.564,6.956,6.956,0,0,1,.47,2.551A6.857,6.857,0,0,1,281.28,256.673Zm-1.409-4.6a5.056,5.056,0,0,0-2.671-2.836,4.85,4.85,0,0,0-2.036-.424,4.947,4.947,0,0,0-2.036.414,4.807,4.807,0,0,0-1.593,1.133,5.3,5.3,0,0,0-1.041,1.685,5.649,5.649,0,0,0-.378,2.072,5.72,5.72,0,0,0,.378,2.082,5.054,5.054,0,0,0,2.671,2.837,4.859,4.859,0,0,0,2.036.423,4.946,4.946,0,0,0,2.036-.414,4.791,4.791,0,0,0,1.593-1.132,5.3,5.3,0,0,0,1.041-1.686,5.647,5.647,0,0,0,.378-2.072A5.725,5.725,0,0,0,279.87,252.077Z"
                    transform="translate(-213.388 -231.242)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1742"
                    data-name="Path 1742"
                    d="M304.246,247.85h1.418v12.893H304.5l-8.325-10.572v10.572H294.76V247.85h1.363l8.123,10.333Z"
                    transform="translate(-224.235 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1743"
                    data-name="Path 1743"
                    d="M329.275,260.678h-1.566l-1.511-3.407h-7.017l-1.529,3.407h-1.491l5.875-12.985H323.4ZM322.7,249.406l-2.947,6.575h5.876Z"
                    transform="translate(-233.111 -231.333)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1744"
                    data-name="Path 1744"
                    d="M341.337,247.85h1.456V259.4h7.257v1.344h-8.713Z"
                    transform="translate(-243.553 -231.398)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1745"
                    data-name="Path 1745"
                    d="M175.932,275.961v12.894h-1.455V275.961Z"
                    transform="translate(-174.348 -243.057)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1746"
                    data-name="Path 1746"
                    d="M191.327,275.961h1.418v12.894h-1.161l-8.325-10.573v10.573h-1.418V275.961H183.2l8.123,10.333Z"
                    transform="translate(-177.402 -243.057)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1747"
                    data-name="Path 1747"
                    d="M215.96,284.941a5.986,5.986,0,0,1-1.409,2.054,6.669,6.669,0,0,1-2.174,1.363,7.5,7.5,0,0,1-2.772.5H205.13V275.961h4.475a7.634,7.634,0,0,1,2.772.488,6.562,6.562,0,0,1,2.174,1.354,6.011,6.011,0,0,1,1.409,2.044,6.766,6.766,0,0,1,0,5.093Zm-1.391-4.522a4.828,4.828,0,0,0-1.068-1.631,4.922,4.922,0,0,0-1.686-1.086,5.928,5.928,0,0,0-2.211-.4h-3.02v10.2h3.02a6.061,6.061,0,0,0,2.211-.386,4.758,4.758,0,0,0,2.754-2.689,5.243,5.243,0,0,0,.378-2.008A5.334,5.334,0,0,0,214.57,280.419Z"
                    transform="translate(-187.061 -243.057)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1748"
                    data-name="Path 1748"
                    d="M237.487,277.288h-7.865v4.4h7.036v1.326h-7.036v4.513h7.957v1.326h-9.412V275.961h9.32Z"
                    transform="translate(-196.616 -243.057)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1749"
                    data-name="Path 1749"
                    d="M255.939,288.855l-4.125-5.544-4.126,5.544h-1.676l4.955-6.576-4.77-6.318h1.713l3.96,5.268,3.924-5.268h1.694l-4.77,6.3,4.955,6.594Z"
                    transform="translate(-204.017 -243.057)"
                    fill="#dd0e3b"
                />
            </g>
            <path
                id="Path_1750"
                data-name="Path 1750"
                d="M62.7,265.538,40.5,236.4v29.137H30.549V219.7h9.3l21.477,28.221V219.7h9.952v45.834Z"
                fill="#2d3047"
            />
            <path
                id="Path_1751"
                data-name="Path 1751"
                d="M147.582,265.538V219.7h10.083v45.834Z"
                transform="translate(-48.539)"
                fill="#2d3047"
            />
            <g id="Group_3188" data-name="Group 3188" transform="translate(168.618 252.575)">
                <path
                    id="Path_1752"
                    data-name="Path 1752"
                    d="M267.747,278.737h-.324v-2.569h-.963v-.3h2.249v.3h-.963Z"
                    transform="translate(-266.461 -275.869)"
                    fill="#dd0e3b"
                />
                <path
                    id="Path_1753"
                    data-name="Path 1753"
                    d="M272.374,277.95h-.016l-1.045-1.532v2.319H271v-2.868h.328l1.045,1.565,1.045-1.565h.328v2.868h-.324v-2.323Z"
                    transform="translate(-268.342 -275.869)"
                    fill="#dd0e3b"
                />
            </g>
        </g>
    </svg>
);

export default NEILogo;
