import Axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from './localStorage';
import { notification } from 'antd';

const error = async (err: unknown, file: string) => {
    const error: any = err;
    //are we in a dev environment?
    //yes
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(error);
        return notification.error({
            message: 'An error has occurred',
            description: `-File: ${file}\n\n-Message: ${error.message}`,
        });
    }
    const auth: Auth = storage.get('auth');

    const headers = {
        SECRET: auth?.secret,
        PASSPHRASE: auth?.passPhrase,
    };

    await Axios.post(
        api.error,
        {
            source: file,
            message: error.message,
            stackTrace: error.stack,
        },
        {
            headers,
        },
    );

    //notification.error({
    //message: 'An error has occurred',
    //description: 'If the error persists please contact Feelalytics',
    //});
};

export default error;
