import { Button, TimePicker, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikField from '../FormikField';
import storage from '../../utilities/localStorage';
import ProjectFormValues, { ProjectCreation } from '../../types/newProject';
import FormValues from '../../constants/projects';
import SpanRequired from '../Styled/SpanRequired';
import moment from 'moment';
import useRouter from '../../hooks/useRouter';
import Project from '../../types/project';

const quarterlyValidation = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    dateStart: Yup.string().required('Required'),
});

const Container = styled(Form)`
    display: grid;
    grid-template-columns: 33% auto;
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
    margin-top: ${scale.xl};
`;

const OptionCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    padding: ${scale.lg};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const InputGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    gap: ${scale.md};
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    height: 40px;
    margin-top: ${scale.md};
    display: flex;
    justify-content: center;
`;

const StyledTimePicker = styled(TimePicker)`
    max-width: 280px;
    width: 100%;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${scale.md};
`;

const StyledButton = styled(Button)`
    width: 194px;
    margin-top: ${scale.md};
`;

const StyledCancelButton = styled(Button)`
    color: ${colors.darkBlue};
    background-color: ${colors.lightGray};
    margin-top: ${scale.md};
    border-color: ${colors.lightGray};
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    :hover {
        background-color: ${colors.lightGray};
        border-color: ${colors.lightGray};
        color: ${colors.red};
    }
`;

const ErrorMessage = styled.div`
    color: ${colors.red};
    font-size: 12px;
`;

const TimePickerContainer = styled.div`
    width: 100%;
`;

const OneTimeSample: React.FC<{
    onClick: () => void;
    onchooseGroup: (project: ProjectCreation) => void;
    projectType: string;
    projectData?: Project;
}> = ({ onClick, onchooseGroup, projectType, projectData }) => {
    const company: any = storage.get('company');
    const router = useRouter();

    const now = moment();

    const remainder = 5 - (moment().minute() % 5);
    const roundedTime = now.add(remainder, 'minutes').format('HH:mm a');

    const [defaultTime, setDefaultTime] = useState<moment.Moment>(moment(roundedTime, 'HH:mm a').utc());
    const [defaultDate, setDefaultDate] = useState<moment.Moment>(moment().add(1, 'day'));

    const CreateProject = (data: ProjectFormValues) => {
        const ProjectToCreate = {
            projectType: projectType,
            name: data.name,
            quarterStartMonth: data.quarterStartMonth,
            dateStart: data.dateStart,
            dateEnd: data.dateStart,
            daysToSample: [],
            sampleTimes: {
                timeStart: data.sampleTimes.timeStart,
                timeEnd: '',
            },
            companyId: company.companyId,
            groups: [],
            sendNow: data.sendNow,
        };
        onchooseGroup(ProjectToCreate);
    };

    const FieldValues = {
        ...FormValues,
        dateStart: moment(defaultDate).format(),
    };

    //fill the data we are going to send
    FieldValues.sampleTimes.timeStart = defaultTime?.utc().format();
    FieldValues.sampleTimes.timeEnd = defaultTime?.add(1, 'days').utc().format();

    useEffect(() => {
        //do we have a project?
        //yes
        if (projectData !== undefined) {
            setDefaultTime(moment(projectData.sampleTimes.timeStart));
            setDefaultDate(moment(projectData.dateStart));
            FieldValues.name = projectData.name;
            FieldValues.dateStart = projectData.dateStart;
            FieldValues.sampleTimes.timeStart = moment(projectData.sampleTimes.timeStart).utc().format();
        }
    }, []);

    return (
        <Formik
            initialValues={FieldValues}
            validationSchema={quarterlyValidation}
            onSubmit={(values) => CreateProject(values)}
        >
            {({ values, setFieldValue, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                <>
                    <Container onSubmit={handleSubmit}>
                        <OptionCard>
                            <InputGrid>
                                <div>
                                    <Text bold>
                                        Name your project <SpanRequired>*</SpanRequired>
                                    </Text>
                                    <FormikField
                                        type="name"
                                        name="name"
                                        placeholder="Project name"
                                        onChange={handleChange}
                                    />
                                    {touched.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                                </div>
                                <div>
                                    <Text bold>
                                        Select the date to send the temperature check <SpanRequired>*</SpanRequired>
                                    </Text>
                                    <StyledDatePicker
                                        format={'MM/DD/YYYY'}
                                        defaultValue={moment(values.dateStart)}
                                        value={moment(values.dateStart)}
                                        onChange={(value) => {
                                            setFieldValue('dateStart', moment(value).format('MM/DD/YYYY'));
                                            setFieldValue('dateEnd', moment(value).add(1, 'days').format('MM/DD/YYYY'));
                                        }}
                                        disabledDate={(current) => {
                                            return moment().add(-1, 'days') >= current;
                                        }}
                                    />
                                    {touched.dateStart && <ErrorMessage>{errors.dateStart}</ErrorMessage>}
                                </div>
                            </InputGrid>
                        </OptionCard>
                        <OptionCard>
                            <InputGrid>
                                <Text bold>
                                    Choose a time to send the temperature check. The time is shown in your current time
                                    zone. <SpanRequired>*</SpanRequired>
                                </Text>
                                <div>
                                    <TimePickerContainer>
                                        <StyledTimePicker
                                            defaultValue={defaultTime.local()}
                                            value={defaultTime.local()}
                                            onChange={(value) => {
                                                setDefaultTime(moment(value).utc());
                                                FieldValues.sampleTimes.timeStart = value?.utc().format();
                                            }}
                                            onSelect={(value) => {
                                                setDefaultTime(moment(value).utc());
                                                FieldValues.sampleTimes.timeStart = value?.utc().format();
                                            }}
                                            use12Hours
                                            format="hh:mm a"
                                            showNow={false}
                                            minuteStep={5}
                                        />
                                    </TimePickerContainer>
                                    {touched.sampleTimes && <ErrorMessage>{errors.sampleTimes}</ErrorMessage>}
                                </div>
                                {process.env.NODE_ENV === 'development' ? (
                                    <pre>{JSON.stringify(values, null, 2)}</pre>
                                ) : null}
                            </InputGrid>
                        </OptionCard>
                    </Container>
                    <ButtonGroup>
                        <StyledCancelButton
                            disabled={isSubmitting}
                            onClick={() => onClick()}
                            size="large"
                            style={{ color: colors.black }}
                            htmlType="submit"
                        >
                            X Cancel
                        </StyledCancelButton>
                        <StyledButton
                            disabled={isSubmitting}
                            onClick={() => handleSubmit()}
                            size="large"
                            style={{ color: colors.white, backgroundColor: colors.red }}
                            htmlType="submit"
                        >
                            {router.location.pathname === '/editproject' ? 'Update Project' : 'Choose Groups'}
                        </StyledButton>
                    </ButtonGroup>
                </>
            )}
        </Formik>
    );
};

export default OneTimeSample;
