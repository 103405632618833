import React, { useState } from 'react';
import NEIBanner from '../../components/NEIBanner';
import StandardLayout from '../../layout/StandardLayout';
import {
    RedoOutlined,
    CalendarOutlined,
    DashboardOutlined,
    NotificationOutlined,
    QuestionOutlined,
    DislikeOutlined,
    LikeOutlined,
} from '@ant-design/icons';

// On Boarding Wizard Components
import ChooseProjectType from '../../components/Project/ChooseProjectType';
import Ongoing from '../../components/Project/Ongoing';
import Quarterly from '../../components/Project/Quarterly';
import OneTimeSample from '../../components/Project/OneTimeSample';
import OneTime from '../../components/Project/OneTime';
import ChooseGroups from '../../components/Project/ChooseGroups';

export type ProjectType =
    | 'Ongoing Sample'
    | 'Quarterly Sample'
    | 'One-Time Sample'
    | 'One-Time Announcement'
    | 'One-Time Custom Question'
    | 'One-Time Yes/No Question';

const NewProjectWizard: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [step2Component, setStep2Component] = useState<JSX.Element | null>(null);
    const [step2Icon, setStep2Icon] = useState<JSX.Element | null>(null);
    const [stepTitle, setStepTitle] = useState<string>('Choose your project type');
    const [projectData, setProjectData] = useState<any>({});

    const chooseGroupRoute = (data: any) => {
        setProjectData(data);
        setStepTitle('Choose your group(s) and schedule your project');
        setCurrentStep(2);
    };

    const OnClickReset = () => {
        setStep2Icon(null);
        setCurrentStep(0);
    };

    const handleSelectedPeriod = (selected: ProjectType) => {
        switch (selected) {
            case 'Ongoing Sample':
                setStep2Icon(<RedoOutlined />);
                setStepTitle('Settings for an ongoing emotional temperature check');
                setStep2Component(
                    <Ongoing
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
            case 'Quarterly Sample':
                setStep2Icon(<CalendarOutlined />);
                setStepTitle('Settings for a quarterly emotional temperature check');
                setStep2Component(
                    <Quarterly
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
            case 'One-Time Sample':
                setStep2Icon(<DashboardOutlined />);
                setStepTitle('Settings for a one-time emotional temperature check');
                setStep2Component(
                    <OneTimeSample
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
            case 'One-Time Announcement':
                setStep2Icon(<NotificationOutlined />);
                setStepTitle('Settings for an announcement');
                setStep2Component(
                    <OneTime
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
            case 'One-Time Custom Question':
                setStep2Icon(<QuestionOutlined />);
                setStepTitle('Settings for a custom question');
                setStep2Component(
                    <OneTime
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
            case 'One-Time Yes/No Question':
                setStep2Icon(
                    <>
                        <LikeOutlined style={{ transform: 'scaleX(-1)' }} />
                        <DislikeOutlined />
                    </>,
                );
                setStepTitle('Settings for a yes / no question');
                setStep2Component(
                    <OneTime
                        onClick={() => OnClickReset()}
                        onchooseGroup={(project) => chooseGroupRoute(project)}
                        projectType={selected}
                    />,
                );
                setCurrentStep(1);
                break;
        }
    };

    const steps = [
        {
            title: 'Choose your sample period',
            content: <ChooseProjectType onClick={(selected) => handleSelectedPeriod(selected)} />,
        },
        {
            title: 'Customize your sample sends',
            content: step2Component,
        },
        {
            title: 'Choose Groups',
            content: <ChooseGroups ProjectData={projectData} onClick={() => setCurrentStep(1)} />,
        },
    ];

    return (
        <StandardLayout simple>
            <NEIBanner titleCard pageIcon={step2Icon} title={stepTitle} />
            {steps[currentStep].content}
        </StandardLayout>
    );
};

export default NewProjectWizard;
