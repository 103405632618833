import React from 'react';
import styled from 'styled-components';
import scale from '../constants/scale';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Text from './Text';
import useRouter from '../hooks/useRouter';

const CustomBackButton = styled.div<{ $noSpacing: boolean }>`
    display: flex;
    flex-direction: row;
    gap: ${scale.sm};
    max-width: max-content;
    justify-content: center;
    ${({ $noSpacing }) =>
        !$noSpacing &&
        `padding: ${scale.sm} ${scale.sm} ${scale.sm} 0;
        margin-bottom: ${scale.md};`}
    border-radius: 2px;

    :hover {
        cursor: pointer;
    }
`;

const BackIcon = styled(ArrowLeftOutlined)`
    font-size: ${scale.lg};
`;

const BackButton = ({ noSpacing = false, columnState = '' }: { noSpacing?: boolean; columnState?: string }) => {
    const router = useRouter();

    return (
        <CustomBackButton $noSpacing={noSpacing} onClick={() => router.push('/', { column: columnState })}>
            <BackIcon />
            <Text>Go back</Text>
        </CustomBackButton>
    );
};

export default BackButton;
