import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import error from '../utilities/error';
import User from '../types/user';
import { UserProfile } from '../pages/EditProfile';

const user = {
    get: async () => {
        const { secret, passPhrase }: Auth = storage.get('auth');
        const { employeeId, companyId }: User = storage.get('user');

        const headers = {
            SECRET: secret,
            PASSPHRASE: passPhrase,
        };

        const { data } = await axios
            .get(api.administrators, {
                params: {
                    id: employeeId,
                    companyId,
                },
                headers,
            })
            .catch((err) => {
                error(err, 'user.ts');
                throw new Error(err);
            });

        return data;
    },
    update: async (userData: UserProfile) => {
        const { secret, passPhrase }: Auth = storage.get('auth');
        const { employeeId, companyId }: User = storage.get('user');

        const headers = {
            SECRET: secret,
            PASSPHRASE: passPhrase,
        };

        const { data } = await axios
            .patch(
                api.administrators,
                { ...userData, id: employeeId, companyId },
                {
                    headers,
                },
            )
            .catch((err) => {
                error(err, 'user.ts');
                throw new Error(err);
            });

        return data;
    },
};

export default user;
