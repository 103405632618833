import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import { QueryFunctionContext } from 'react-query';
import error from '../utilities/error';
import Member from '../types/member';
import Group from '../types/group';

const members = {
    get: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.group, {
                params: {
                    companyId: queryKey[1],
                    groupId: queryKey[2],
                },
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
    getAll: async (groupId: Group['id']) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.member, {
                params: {
                    groupId,
                },
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
    create: async (member: Member[]) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };
        const { data } = await axios
            .post(api.member, member, {
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
    upload: async (file: { groupId: Member['groupId']; fileContents: string }) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };
        const { data } = await axios
            .post(api.member, file, {
                params: {
                    upload: true,
                },
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
    update: async (member: Member) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };
        const { data } = await axios
            .patch(api.member, member, {
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
    remove: async ({
        rawMembers,
        companyId,
        groupId,
    }: {
        rawMembers: Member[];
        companyId: string;
        groupId: string;
    }) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const structuredArr: {
            memberId: Member['id'];
            companyId: Member['companyId'];
            groupId: Member['groupId'];
        }[] = [];

        rawMembers.forEach((member) => {
            const { id } = member;

            structuredArr.push({
                memberId: id,
                companyId,
                groupId,
            });
        });
        const { data } = await axios
            .delete(api.member, {
                data: structuredArr,
                headers,
            })
            .catch((err) => {
                error(err, 'members.ts');
                throw new Error(err);
            });
        return data;
    },
};

export default members;
