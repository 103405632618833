import React, { useEffect, useState } from 'react';
import { Button, Table, Popconfirm, Input, Tooltip, notification } from 'antd';
import {
    CheckCircleOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleOutlined,
    RetweetOutlined,
    TableOutlined,
    UserDeleteOutlined,
    UsergroupAddOutlined,
    CloseOutlined,
    PauseCircleOutlined,
} from '@ant-design/icons';
import StandardLayout from '../layout/StandardLayout';
import useRouter from '../hooks/useRouter';
import storage from '../utilities/localStorage';
import User from '../types/user';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import groups from '../query-functions/groups';
import scale from '../constants/scale';
import styled from 'styled-components';
import colors from '../constants/colors';
import Text from '../components/Text';
import MemberUploadModal from '../components/MemberUploadModal';
import { useFormik } from 'formik';
import members from '../query-functions/members';
import Group from '../types/group';
import Member from '../types/member';
import SHORTNotification from '../components/SHORTNotification';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { device, size } from '../constants/device';
import error from '../utilities/error';
import { CSVLink } from 'react-csv';
import shadow from '../constants/shadow';
import usePhoneFormatter from '../hooks/usePhoneFormatter';
import useActiveMemberCounter from '../hooks/useActiveMemberCounter';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: min-content 1fr;
    grid-gap: ${scale.xl};
    padding: ${scale.xl} 0;
    align-items: start;

    @media ${device.laptop} {
        grid-gap: ${scale.md};
    }

    @media ${device.tablet} {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

const ColumnGroup = styled.div`
    display: grid;
    grid-template-rows: repeat(3, min-content) 1fr;
    grid-gap: ${scale.sm};

    @media ${device.tablet} {
        grid-column-start: span 2;
    }
`;

const GappedContainer = styled.div<{ $vertical?: boolean }>`
    display: flex;
    flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
    align-items: ${({ $vertical }) => ($vertical ? 'flex-start' : 'center')};
    gap: ${scale.sm};
    min-height: 40px;
`;

const StyledButton = styled(Button)<{ $cancelButton?: boolean }>`
    color: ${({ $cancelButton }) => ($cancelButton ? colors.gray : colors.darkBlue)};
`;

const ButtonContainer = styled.div`
    grid-column-start: span 2;
`;

const StyledTableContainer = styled.div`
    border-radius: 2px;
    position: relative;
    background-color: ${colors.white};
    ${shadow}

    tr.paused {
        color: gray;
        font-style: oblique;
    }
`;

const StyledEditIcon = styled(EditOutlined)`
    font-size: 20px;
    color: ${colors.darkBlue};
`;

const StyledPausedIcon = styled(PauseCircleOutlined)`
    font-size: 20px;
    color: ${colors.lightBlue};
`;

const StyledCheckIcon = styled(CheckCircleOutlined)`
    font-size: 20px;
    color: ${colors.aqua};
`;

const NoDataContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: ${colors.white};
    text-align: center;
`;

const tooltipMessage =
    'We require a minimum of six members to qualify as a group so that we can maintain an anonymous reporting system. This provides members with the safety to know that their individual responses can’t be traced back to them.';

const GroupsAndMembers: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<Group[]>([]);
    const [membersData, setMembersData] = useState<Member[]>([]);
    const [selected, setSelected] = useState<Group>({} as Group);
    const [selectedKeys, setSelectedKeys] = useState<Group['id'][]>([]);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [newMemberEditor, setNewMemberEditor] = useState<boolean>(false);
    const [existingMemberEditor, setExistingMemberEditor] = useState<boolean>(false);
    const [moveMemberEditor, setMoveMemberEditor] = useState<boolean>(false);
    const [editingMemberId, setEditingMemberId] = useState<string>('');
    const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
    const [sizeDefault, setSizeDefault] = useState<SizeType>('large');
    const [csvGrData, setCsvGrData] = useState<any[]>([]);
    const [csvMemData, setCsvMemData] = useState<any[]>([]);
    const { num, setNumber, formatted } = usePhoneFormatter();

    const [grDuplicateLoading, setGrDuplicateLoading] = useState<boolean>(false);
    const [grDeleteLoading, setGrDeleteLoading] = useState<boolean>(false);
    const [memCreateLoading, setMemCreateLoading] = useState<boolean>(false);
    const [memRemoveLoading, setMemRemoveLoading] = useState<boolean>(false);
    const [memMoveLoading, setMemMoveLoading] = useState<boolean>(false);
    const [memUploadLoading, setMemUploadLoading] = useState<boolean>(false);

    const companyId = storage.get('company').companyId;

    const groupsQuery = ['groups', companyId];

    const grQuery = useQuery(groupsQuery, groups.getAll);

    const grDuplicateMutation = useMutation(groups.duplicate, {
        onSuccess: () => {
            queryClient.invalidateQueries(groupsQuery);
            resetState();
            SHORTNotification('Group duplicated');
        },
    });

    const grDeleteMutation = useMutation(groups.delete, {
        onSuccess: () => {
            queryClient.invalidateQueries(groupsQuery);
            resetState();
            SHORTNotification('Group deleted');
        },
    });

    const memCreateMutation = useMutation(members.create, {
        onSuccess: async () => {
            const { id } = selected;

            const data = await members.getAll(id);
            setMembersData(data);
            queryClient.invalidateQueries(groupsQuery);
            setNewMemberEditor(false);
            SHORTNotification('Member added');
        },
    });

    const memUpdateMutation = useMutation(members.update, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(groupsQuery);
            setMembersData(data);
            setExistingMemberEditor(false);
            SHORTNotification('Member updated');
        },
    });

    const memRemoveMutation = useMutation(members.remove, {
        onSuccess: ({ members }) => {
            queryClient.invalidateQueries(groupsQuery);
            setSelectedMembers([]);
            setMembersData(members);
            SHORTNotification('Members removed');
        },
    });

    const memUploadMutation = useMutation(members.upload, {
        onSuccess: async () => {
            const { id } = selected;

            const data = await members.getAll(id);
            setMembersData(data);
            queryClient.invalidateQueries(groupsQuery);
            setUploadModal(false);
            SHORTNotification('Members uploaded');
        },
    });

    useEffect(() => {
        const { id } = selected;

        if (id) {
            setNewMemberEditor(false);
            setMembersData(selected.members);
        }
    }, [selected.id]);

    useEffect(() => {
        const { data, isLoading } = grQuery;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
        if (data) {
            // (If zero groups returned, redirect to onboarding)
            //if (data.length === 0) {
            //router.push('/onboarding');
            //}

            setGroupData(data);
            exportGrNameCsv();
        }
    }, [grQuery.data, grQuery.isLoading]);

    useEffect(() => {
        const { isLoading } = grDuplicateMutation;

        if (isLoading) setGrDuplicateLoading(true);
        if (!isLoading) setGrDuplicateLoading(false);
    }, [grDuplicateMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = grDeleteMutation;

        if (isLoading) setGrDeleteLoading(true);
        if (!isLoading) setGrDeleteLoading(false);
    }, [grDeleteMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = memCreateMutation;

        if (isLoading) setMemCreateLoading(true);
        if (!isLoading) setMemCreateLoading(false);
    }, [memCreateMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = memRemoveMutation;

        if (isLoading) setMemRemoveLoading(true);
        if (!isLoading) setMemRemoveLoading(false);
    }, [memRemoveMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = memUploadMutation;

        if (isLoading) setMemUploadLoading(true);
        if (!isLoading) setMemUploadLoading(false);
    }, [memUploadMutation.isLoading]);

    useEffect(() => {
        const { innerWidth } = window;
        const { laptopL, laptop } = size;

        if (innerWidth > laptopL) setSizeDefault('large');
        if (innerWidth <= laptopL) setSizeDefault('middle');
        if (innerWidth <= laptop) setSizeDefault('small');
    }, [window.innerWidth]);

    useEffect(() => {
        if (moveMemberEditor && selectedMembers.length === 0) setMoveMemberEditor(false);
    }, [selectedMembers]);

    useEffect(() => {
        if (membersData.length > 0) {
            setCsvMemData([]);
            membersData.forEach(({ firstName, lastName, phoneNumber, externalId }) => {
                setCsvMemData((prev) =>
                    prev.concat({
                        phoneNumber,
                        firstName,
                        lastName,
                        externalId,
                    }),
                );
            });
        }
    }, [membersData]);

    const resetState = () => {
        setSelected({} as Group);
        setSelectedKeys([]);
        setMembersData([]);
        setSelectedMembers([]);
    };

    const duplicateGroup = (group: Group) => {
        const companyIdentifier = companyId;

        grDuplicateMutation.mutate({ group, companyIdentifier });
    };

    const deleteGroup = (group: Group) => {
        const { id } = group;

        grDeleteMutation.mutate({
            groupId: id,
            companyId,
        });
    };

    const viewGroup = (group: Group) => {
        router.push('/groupview', {
            companyId,
            group,
        });
    };

    const addMemberRow = () => {
        setMembersData([{ phoneNumber: '', firstName: '', lastName: '', externalId: '', id: '' }, ...membersData]);
        setNewMemberEditor(true);
    };

    const editMember = (memberId: Member['id']) => {
        setEditingMemberId(memberId);
        setExistingMemberEditor(true);
    };

    const removeMember = () => {
        memRemoveMutation.mutate({
            rawMembers: selectedMembers,
            companyId,
            groupId: selected.id,
        });
    };

    const moveMember = async (groupId: Group['id']) => {
        const membersToMove: Member[] = [];

        selectedMembers.forEach(({ firstName, lastName, phoneNumber }) => {
            membersToMove.push({
                firstName,
                lastName,
                phoneNumber,
                id: '',
                groupId,
                companyId,
            });
        });
        setMemMoveLoading(true);
        try {
            await members.remove({
                rawMembers: selectedMembers,
                companyId,
                groupId: selected.id,
            });

            await members.create(membersToMove);
        } catch (err) {
            error(err, 'GroupsAndMembers.tsx');
        } finally {
            queryClient.invalidateQueries(groupsQuery);
            setMoveMemberEditor(false);
            resetState();
            setMemMoveLoading(false);
            SHORTNotification('Members moved');
        }
    };

    const exportGrNameCsv = () => {
        setCsvGrData([]);
        groupData.forEach(({ name }) => {
            setCsvGrData((prev) => prev.concat({ name }));
        });
    };

    const onDrop = (acceptedFiles: any) => {
        acceptedFiles.forEach((file: Blob) => {
            const reader = new FileReader();

            reader.onabort = () => SHORTNotification('File reading was aborted');
            reader.onerror = () =>
                SHORTNotification(
                    'File reading has failed. If this issue continues please contact Feelalytics support',
                );
            reader.onload = async () => {
                const { id } = selected;
                const binaryStr = btoa(reader.result as string);
                memUploadMutation.mutate({
                    groupId: id,
                    fileContents: binaryStr,
                });
            };
            reader.readAsText(file);
        });
    };

    const { handleChange, submitForm, setValues, resetForm, values } = useFormik({
        initialValues: {
            phoneNumber: '',
            firstName: '',
            lastName: '',
            externalId: '',
        },
        onSubmit: (values) => {
            const { phoneNumber } = values;
            if (phoneNumber.startsWith('+') === false && phoneNumber.length !== 10) {
                return notification.error({ message: 'Phone number is required and must have 10 digits' });
            }
            const { id } = selected;

            const companyIdentification = companyId;

            const memberData = { ...values, companyId: companyIdentification, groupId: id, department: '', id: '' };

            if (existingMemberEditor) memUpdateMutation.mutate({ ...memberData, id: editingMemberId });
            if (newMemberEditor) memCreateMutation.mutate([memberData]);
            resetForm();
        },
    });

    const cancelInputs = () => {
        if (existingMemberEditor) {
            setExistingMemberEditor(false);
            setEditingMemberId('');
            resetForm();
        }
        if (newMemberEditor) {
            setNewMemberEditor(false);
            setMembersData((prev) => prev.slice(1));
            resetForm();
        }
    };

    // (Format active vs. inactive user counts)
    const [totalMemberCount, activeMemberCount, inactiveMemberCount] = useActiveMemberCounter(membersData);
    const formattedMemberCount = () => {
        return (
            <Text size="10">
                {totalMemberCount} members ({activeMemberCount} active, {inactiveMemberCount} paused)
            </Text>
        );
    };

    const groupsColumn = moveMemberEditor
        ? [
              {
                  title: 'Group Name',
                  dataIndex: 'name',
                  key: 'name',
                  sorter: (a: any, b: any) => (a.name > b.name ? 1 : -1),
              },
              {
                  key: 'moveAction',
                  width: 70,
                  render: (value: string, record: Group) =>
                      record.id !== selected.id ? (
                          <Popconfirm
                              title={`Are you sure you want to move the selected ${
                                  selectedMembers.length > 1 ? 'members' : 'member'
                              } to ${record.name}?`}
                              onConfirm={() => moveMember(record.id)}
                          >
                              <Tooltip title="Move members here" mouseEnterDelay={1}>
                                  <StyledButton
                                      style={{ color: colors.white, backgroundColor: colors.red }}
                                      icon={<RetweetOutlined />}
                                  />
                              </Tooltip>
                          </Popconfirm>
                      ) : null,
              },
          ]
        : [
              {
                  title: 'Group Name',
                  dataIndex: 'name',
                  key: 'name',
                  sorter: (a: any, b: any) => (a.name > b.name ? 1 : -1),
              },
              {
                  title: 'Number',
                  dataIndex: 'smsPhoneNumber',
                  key: 'smsPhoneNumber',
                  width: 127,
                  render: (value: string) => {
                      if (value) {
                          return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                      } else {
                          return value;
                      }
                  },
              },
              {
                  key: 'actions',
                  width: 180,
                  render: (value: string, record: Group) => (
                      <>
                          <Tooltip title="Edit Group">
                              <Button size="small" type="text" onClick={() => viewGroup(record)}>
                                  <EditOutlined style={{ fontSize: scale.mdl }} />
                              </Button>
                          </Tooltip>
                          <Tooltip title="Duplicate Group">
                              <Button size="small" type="text" onClick={() => duplicateGroup(record)}>
                                  <CopyOutlined style={{ fontSize: scale.mdl }} />
                              </Button>
                          </Tooltip>
                          <Popconfirm
                              title={`Are you sure you want to delete ${record.name}?`}
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => deleteGroup(record)}
                          >
                              <Tooltip title="Delete Group">
                                  <Button size="small" type="text">
                                      <DeleteOutlined style={{ fontSize: scale.mdl }} />
                                  </Button>
                              </Tooltip>
                          </Popconfirm>
                      </>
                  ),
              },
          ];

    return (
        <StandardLayout page="usersandgroups">
            <GridContainer>
                <ButtonContainer>
                    <GappedContainer $vertical>
                        <Button
                            onClick={() =>
                                router.push('/groupview', {
                                    companyId,
                                    query: groupsQuery,
                                    newGroup: true,
                                })
                            }
                            size={sizeDefault}
                            style={{ color: colors.white, backgroundColor: colors.red }}
                            icon={<PlusCircleOutlined />}
                        >
                            New Group
                        </Button>
                    </GappedContainer>
                </ButtonContainer>
                <ColumnGroup>
                    <GappedContainer>
                        <Text size="28">Groups</Text>
                        <Text size="14">Choose a group to see its members</Text>
                    </GappedContainer>
                    <Text size="10">{groupData.length ?? 0} Groups</Text>
                    {/* <CSVLink filename="MAgroups.csv" headers={[{ label: 'Group Name', key: 'name' }]} data={csvGrData}> */}
                    <StyledButton
                        onClick={exportGrNameCsv}
                        size={sizeDefault}
                        icon={<TableOutlined />}
                        style={{
                            color: colors.white,
                            backgroundColor: colors.red,
                            visibility: 'hidden',
                            pointerEvents: 'none',
                        }}
                    >
                        Export
                    </StyledButton>
                    {/* </CSVLink> */}
                    <StyledTableContainer>
                        <Table
                            size={sizeDefault}
                            scroll={window.innerWidth <= size.tablet ? { y: 240 } : undefined}
                            dataSource={groupData}
                            columns={groupsColumn}
                            rowSelection={{
                                type: 'radio',
                                columnWidth: '50px',
                                onChange: (rowKey) => setSelectedKeys(rowKey as string[]),
                                onSelect: (record) => setSelected(record),
                                selectedRowKeys: selectedKeys,
                            }}
                            rowKey={(record) => record.id}
                            pagination={false}
                            loading={loading || memMoveLoading || grDeleteLoading || grDuplicateLoading}
                        />
                    </StyledTableContainer>
                </ColumnGroup>
                <ColumnGroup>
                    <GappedContainer>
                        <Text size="28">Members</Text>
                        <StyledButton
                            disabled={!selected.hasOwnProperty('id')}
                            loading={memCreateLoading}
                            size={sizeDefault}
                            type="text"
                            icon={<PlusCircleOutlined />}
                            onClick={addMemberRow}
                        >
                            Add Member
                        </StyledButton>
                        <StyledButton
                            disabled={!selected.hasOwnProperty('id')}
                            onClick={() => setUploadModal(true)}
                            size={sizeDefault}
                            type="text"
                            icon={<UsergroupAddOutlined />}
                        >
                            Upload Members
                        </StyledButton>
                    </GappedContainer>
                    {formattedMemberCount()}
                    <GappedContainer>
                        <StyledButton
                            disabled={selectedMembers.length === 0}
                            size={sizeDefault}
                            style={{
                                color: selectedMembers.length === 0 ? colors.mediumGray : colors.white,
                                backgroundColor: selectedMembers.length === 0 ? colors.lightGray : colors.lightBlue,
                            }}
                            icon={<RetweetOutlined />}
                            onClick={() => setMoveMemberEditor(!moveMemberEditor)}
                        >
                            Move
                        </StyledButton>
                        <Popconfirm
                            disabled={selectedMembers.length === 0}
                            title={`Are you sure you want to remove ${
                                selectedMembers.length > 1 ? 'these members' : 'this member'
                            }?`}
                            onConfirm={removeMember}
                        >
                            <StyledButton
                                disabled={selectedMembers.length === 0}
                                loading={memRemoveLoading}
                                size={sizeDefault}
                                style={{
                                    color: selectedMembers.length === 0 ? colors.mediumGray : colors.white,
                                    backgroundColor: selectedMembers.length === 0 ? colors.lightGray : colors.lightBlue,
                                }}
                                icon={<UserDeleteOutlined />}
                            >
                                Remove
                            </StyledButton>
                        </Popconfirm>
                        <CSVLink
                            filename={`${selected.name}.csv`}
                            headers={[
                                { label: 'First Name', key: 'firstName' },
                                { label: 'Last Name', key: 'lastName' },
                                { label: 'Phone Number', key: 'phoneNumber' },
                                { label: 'ID Number', key: 'externalId' },
                            ]}
                            data={csvMemData}
                        >
                            <StyledButton
                                disabled={!selected.hasOwnProperty('id') || membersData.length === 0}
                                size={sizeDefault}
                                style={{
                                    color:
                                        !selected.hasOwnProperty('id') || membersData.length === 0
                                            ? colors.mediumGray
                                            : colors.white,
                                    backgroundColor:
                                        !selected.hasOwnProperty('id') || membersData.length === 0
                                            ? colors.lightGray
                                            : colors.lightBlue,
                                }}
                                icon={<TableOutlined />}
                            >
                                Export
                            </StyledButton>
                        </CSVLink>
                        {newMemberEditor || existingMemberEditor ? (
                            <StyledButton
                                onClick={cancelInputs}
                                $cancelButton
                                size={sizeDefault}
                                type="text"
                                icon={<CloseOutlined />}
                            >
                                Cancel
                            </StyledButton>
                        ) : null}
                    </GappedContainer>
                    <StyledTableContainer>
                        {membersData.length === 0 ? (
                            <NoDataContainer>
                                <Tooltip title={tooltipMessage} mouseEnterDelay={0.5}>
                                    <span>
                                        <Text>There’s a minimum group size of six. Why?</Text>
                                    </span>
                                </Tooltip>
                            </NoDataContainer>
                        ) : null}
                        <Table
                            size={sizeDefault}
                            loading={loading || memMoveLoading}
                            dataSource={membersData}
                            pagination={false}
                            rowClassName={(record, index) => (record.doNotSMS ? 'paused' : '')}
                            rowSelection={{
                                type: 'checkbox',
                                columnWidth: '48px',
                                onChange: (key, members) => setSelectedMembers(members),
                            }}
                            rowKey={(record) => record.id}
                            columns={[
                                {
                                    title: 'Phone Number',
                                    dataIndex: 'phoneNumber',
                                    key: 'phoneNumber',
                                    render: (value, record) =>
                                        //do we have a new member?
                                        //yes
                                        newMemberEditor && value === '' ? (
                                            <Input
                                                autoFocus
                                                minLength={10}
                                                maxLength={values.phoneNumber.trim().startsWith('+') ? 15 : 10}
                                                name="phoneNumber"
                                                defaultValue=""
                                                value={
                                                    values.phoneNumber.startsWith('+')
                                                        ? values.phoneNumber
                                                        : values.phoneNumber.replace(/\D/, '')
                                                }
                                                onChange={handleChange}
                                            />
                                        ) : //do we have a new member?
                                        //no - editing it
                                        existingMemberEditor && record.id === editingMemberId ? (
                                            <Input
                                                name="phoneNumber"
                                                minLength={10}
                                                maxLength={values.phoneNumber.trim().startsWith('+') ? 15 : 10}
                                                defaultValue={value}
                                                onChange={handleChange}
                                                value={
                                                    value.phoneNumber.startsWith('+')
                                                        ? value.phoneNumber
                                                        : value.phoneNumber.replace(/\D/, '')
                                                }
                                            />
                                        ) : value.startsWith('+') ? (
                                            value.replace(/(\d{2})(\d{4})(\d{6})/, '$1 $2 $3')
                                        ) : (
                                            value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                        ),
                                },
                                {
                                    title: 'First Name',
                                    responsive: ['xxl', 'xl', 'lg', 'md'],
                                    dataIndex: 'firstName',
                                    key: 'firstName',
                                    render: (value, record) =>
                                        newMemberEditor && record.id === '' ? (
                                            <Input name="firstName" defaultValue="" onChange={handleChange} />
                                        ) : existingMemberEditor && record.id === editingMemberId ? (
                                            <Input name="firstName" defaultValue={value} onChange={handleChange} />
                                        ) : (
                                            value
                                        ),
                                },
                                {
                                    title: 'Last Name',
                                    responsive: ['xxl', 'xl', 'lg', 'md'],
                                    dataIndex: 'lastName',
                                    key: 'lastName',
                                    render: (value, record) =>
                                        newMemberEditor && record.id === '' ? (
                                            <Input name="lastName" defaultValue="" onChange={handleChange} />
                                        ) : existingMemberEditor && record.id === editingMemberId ? (
                                            <Input name="lastName" defaultValue={value} onChange={handleChange} />
                                        ) : (
                                            value
                                        ),
                                },
                                {
                                    title: 'ID Number',
                                    responsive: ['xxl', 'xl', 'lg', 'md'],
                                    dataIndex: 'externalId',
                                    key: 'externalId',
                                    render: (value, record) =>
                                        newMemberEditor && record.id === '' ? (
                                            <Input name="externalId" defaultValue="" onChange={handleChange} />
                                        ) : existingMemberEditor && record.id === editingMemberId ? (
                                            <Input name="externalId" defaultValue={value} onChange={handleChange} />
                                        ) : (
                                            value
                                        ),
                                },
                                {
                                    title: 'Paused',
                                    responsive: ['xxl', 'xl', 'lg', 'md'],
                                    width: 80,
                                    dataIndex: 'doNotSMS',
                                    key: 'doNotSMS',
                                    render: (value) =>
                                        !newMemberEditor &&
                                        !existingMemberEditor &&
                                        value && (
                                            <div style={{ textAlign: 'center' }}>
                                                <StyledPausedIcon />
                                            </div>
                                        ),
                                },
                                {
                                    key: 'action',
                                    width: 80,
                                    render: (record: Member) =>
                                        (!record.id && newMemberEditor) ||
                                        (record.id === editingMemberId && existingMemberEditor) ? (
                                            <Button type="text" htmlType="submit" onClick={submitForm}>
                                                <StyledCheckIcon />
                                            </Button>
                                        ) : (
                                            <Button
                                                type="text"
                                                onClick={() => {
                                                    const { id, phoneNumber, firstName, lastName, externalId } = record;
                                                    editMember(id);
                                                    setValues({
                                                        phoneNumber,
                                                        firstName,
                                                        lastName,
                                                        externalId: externalId || '',
                                                    });
                                                }}
                                            >
                                                <StyledEditIcon />
                                            </Button>
                                        ),
                                },
                            ]}
                        />
                    </StyledTableContainer>
                </ColumnGroup>
            </GridContainer>
            <MemberUploadModal
                loading={memUploadLoading}
                visible={uploadModal}
                onCancel={() => setUploadModal(false)}
                onDrop={onDrop}
            />
        </StandardLayout>
    );
};

export default GroupsAndMembers;
