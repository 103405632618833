import React, { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import StandardLayout from '../../layout/StandardLayout';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import moment from 'moment';
import useRouter from '../../hooks/useRouter';
import Project from '../../types/project';
import NeiText from '../../constants/neiText';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import BackButton from '../../components/BackButton';
import PrintButton from '../../components/PrintButton';
import { useQuery } from 'react-query';
import groups from '../../query-functions/groups';
import Group from '../../types/group';

const BAR_CHART_ID = 'chart_div';

const DashboardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: ${scale.xl};
    margin-bottom: ${scale.xl};

    @media print {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 0;
        -webkit-print-color-adjust: exact;

        @page {
            size: landscape;
        }
    }
`;

const ReportContainer = styled.div`
    background-color: ${colors.white};
    padding: ${scale.lg};
    box-shadow: 0 3px 6px #00000016;

    @media print {
        min-height: 100vh;
        -webkit-box-shadow: unset;
        -moz-box-shadow: unset;
        box-shadow: unset;
        padding: 0;
    }

    &:nth-last-of-type(1) {
        min-height: unset;
    }
`;
const TitleContainer = styled.div`
    padding: ${scale.lg};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    > :nth-child(1) {
        flex-grow: 1;
    }
    > :nth-child(2) {
        margin-right: 20px;
    }

    @media print {
        display: none;
    }
`;

const FlexRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${scale.xxl};
`;

const GaugeRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${scale.sm};
    margin-bottom: ${scale.lg};

    @media print {
        width: 350px;
    }
`;

const FlexColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const GappedColumn = styled(FlexColumn)`
    gap: ${scale.md};
`;

const ChartContainer = styled.div`
    width: 100%;
    height: 470px;

    @media print {
        width: 940px;
    }
`;

const BarGraphContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media print {
        justify-content: flex-start;
    }
`;

const BarContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 220px;
    box-shadow: 0 3px 6px #00000026;

    @media print {
        margin-top: 20px;
        margin-bottom: 20px;
    }
`;

const YesOrNoGraphContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
`;

const YesOrNoContainer = styled(BarContainer)`
    height: 110px;
`;

type BarTypes = {
    percent: number;
    color: 'blue' | 'gray' | 'aqua';
};

const Bar = styled.div<BarTypes>`
    width: ${({ percent }) => percent}%;
    height: 100%;
    padding: ${scale.md};
    background-color: ${({ color }) => {
        switch (color) {
            case 'aqua':
                return colors.aqua;
            case 'gray':
                return colors.lightBlue;
            case 'blue':
                return colors.darkBlue;
            default:
                break;
        }
    }};
`;

const StyledBar = styled(Bar)`
    display: flex;
    flex-direction: row;
    height: 110px;
    align-items: flex-end;
    gap: ${scale.md};
`;

const GroupContainer = styled.div`
    padding-left: ${scale.lg};
    padding-right: ${scale.lg};
    padding-bottom: ${scale.lg};
    font-size: 16px;
    li {
        list-style-type: none;
    }
`;
const GroupInformation = ({ groups }: { groups: Group[] }) => {
    return (
        <>
            {groups.length > 0 && (
                <GroupContainer>
                    Groups in this project:
                    <ul>
                        {groups.map((group) => (
                            <li key={group.id}>{group.name}</li>
                        ))}
                    </ul>
                </GroupContainer>
            )}
        </>
    );
};

const BubbleColorPicker = (data: number) => {
    if (data <= -2) {
        return colors.darkBlue;
    }
    if (data >= 2) {
        return colors.aqua;
    }
    if (data <= 1 && data >= -1) {
        return colors.lightBlue;
    }
};

const NeiTitle = (data: string, pulse: any) => {
    if (!pulse) return null;

    let text;
    switch (data) {
        case 'Ongoing Sample':
        case 'Quarterly Sample':
            text = 'NEI from';
            break;
        default:
            text = 'NEI';
            break;
    }

    const dateString = moment(pulse.dateStart).format('L'); // (Or also format as 'LL')

    return `${text} ${dateString}`;
};

const BubbleTitle = (pulse: any) => {
    if (!pulse) return null;

    const dateString = moment(pulse.dateStart).format('L');

    return `Moods from ${dateString}`;
};

const NEIDashboard: React.FC = () => {
    const router = useRouter();

    const { project } = router.location.state as { project: Project };
    const { column } = router.location.state as { column: string };

    const barChartRef = React.useRef<any>(null);

    const [barChartData, setBarChartData] = useState<any>([]);
    const [bubbleData, setBubbleData] = useState<any>([]);

    const [currentPulse, setCurrentPulse] = useState<any>(null);
    const [neiNumber, setNeiNumber] = useState<number | null>(null);
    const [whatThisMeans, setWhatThisMeans] = useState<string>('');
    const [whatToDo, setWhatToDo] = useState<string>('');
    const [yesOrNoData, setYesOrNoData] = useState<{ Positive: 0; Negative: 0 }>({ Positive: 0, Negative: 0 });
    const [percentData, setPercentData] = useState<{ Positive: 0; Neutral: 0; Negative: 0 }>({
        Positive: 0,
        Neutral: 0,
        Negative: 0,
    });
    const [totalResponses, setTotalResponses] = useState<number>(0);

    // Retrieve project groups' information
    const [groupData, setGroupData] = useState<Group[]>([]);
    const groupsQuery = ['groups', project.id];
    const grQuery = useQuery(groupsQuery, groups.getByProject);
    useEffect(() => {
        const { data } = grQuery;
        if (data) {
            setGroupData(data);
        }
    }, [grQuery.data]);

    // (Track total number of active members for "response rate" calculation)
    const [totalActiveMemberCount, setTotalActiveMemberCount] = useState<number>();
    useEffect(() => {
        if (groupData && groupData.length > 0) {
            const activeMembers = new Set();
            groupData.forEach((group: any) => {
                group.members
                    .filter((member: any) => !member.doNotSMS)
                    .forEach((member: any) => {
                        activeMembers.add(member.id);
                    });
            });
            setTotalActiveMemberCount(activeMembers.size);
        }
    }, [groupData]);

    // Load project pulse data into barChartData state
    useEffect(() => {
        // (Reset values)
        setBarChartData([]);

        if (project !== null) {
            const PulseArray: any = project.pulses;

            let counter = 0;
            for (let i = 0; i < PulseArray.length; i++) {
                if (PulseArray[i].nei !== null) {
                    counter++;
                    setBarChartData((prev: any) =>
                        prev.concat({
                            date: moment(PulseArray[i].dateStart).format('MM/DD'),
                            number: PulseArray[i].nei >= 0 ? PulseArray[i].nei : null,
                            number2: PulseArray[i].nei < 0 ? PulseArray[i].nei : null,
                        }),
                    );
                }
            }
            // (Fill in bar chart with empty days)
            for (let j = 0; j < 10 - counter; j++) {
                setBarChartData((prev: any) =>
                    prev.concat({
                        date: moment(PulseArray[counter - 1]?.dateStart)
                            .add(-j - 1, 'days')
                            .format('MM/DD'),
                        number: null,
                        number2: null,
                    }),
                );
            }
        }
    }, [project]);

    // Set initial currentPulse
    useEffect(() => {
        //do we have a project?
        //yes
        if (project !== null) {
            // (To account for different project types, only grab the second pulse if it's available)
            if (project.pulses[0].nei !== null) {
                setCurrentPulse(project.pulses[0]);
            } else if (project.pulses[1]) {
                setCurrentPulse(project.pulses[1]);
            } else {
                setCurrentPulse(project.pulses[0]);
            }
        }
    }, [project]);

    // Set graph values based on current pulse
    useEffect(() => {
        // (Reset state values)
        setBubbleData([]);
        setYesOrNoData({ Positive: 0, Negative: 0 });
        setPercentData({ Positive: 0, Neutral: 0, Negative: 0 });
        setTotalResponses(0);

        const percentObj: { Positive: 0; Neutral: 0; Negative: 0 } = { Positive: 0, Neutral: 0, Negative: 0 };
        let totalCount = 0;
        currentPulse?.results?.forEach((pulseResults: any) => {
            setBubbleData((prev: any) =>
                prev.concat({
                    name: pulseResults.emotion,
                    value: pulseResults.numericValue + 11 * pulseResults.count,
                    color: BubbleColorPicker(pulseResults.value),
                }),
            );
            if (pulseResults.value <= -3) {
                percentObj.Negative += pulseResults.count;
            }
            if (pulseResults.value >= 3) {
                percentObj.Positive += pulseResults.count;
            }
            if (pulseResults.value <= 2 && pulseResults.value >= -2) {
                percentObj.Neutral += pulseResults.count;
            }
            totalCount += pulseResults.count;
        });
        setPercentData(percentObj);
        setTotalResponses(totalCount);

        if (project.projectType === 'One-Time Yes/No Question') {
            const bubbleObj: { Positive: 0; Negative: 0 } = { Positive: 0, Negative: 0 };

            currentPulse?.results?.forEach((pulseResults: any) => {
                if (pulseResults.emotion === 'Positive') {
                    bubbleObj.Positive += pulseResults.count;
                } else {
                    bubbleObj.Negative += pulseResults.count;
                }
            });
            setYesOrNoData(bubbleObj);
        }
    }, [currentPulse]);

    // Set text values to display
    useEffect(() => {
        let TextArray: string[] = [];

        // (Set text)
        if (currentPulse?.nei) {
            TextArray = NeiText(parseInt(currentPulse?.nei));
            setNeiNumber(parseInt(currentPulse?.nei));
        } else if (project.pulses.length > 0 && project.pulses[0]?.nei != null) {
            TextArray = NeiText(parseInt(project.pulses[0].nei));
            setNeiNumber(parseInt(project.pulses[0].nei));
        } else if (project.pulses.length > 1 && project.pulses[1]?.nei != null) {
            TextArray = NeiText(parseInt(project.pulses[1].nei));
            setNeiNumber(parseInt(project.pulses[1].nei));
        } else {
            setNeiNumber(null);
            TextArray = [
                'There is no NEI data available due to lack of responses.',
                'We are unable to recommend taking any action due to a lack of responses.',
            ];
        }
        setWhatThisMeans(TextArray[0]);
        setWhatToDo(TextArray[1]);
    }, [currentPulse]);

    // (Calculate response rate for the current pulse)
    const [pulseResponseRate, setPulseResponseRate] = useState<number>();
    useEffect(() => {
        //do we have the right items for the calculation?
        //yes
        if (
            currentPulse &&
            currentPulse.results &&
            totalActiveMemberCount !== 0 &&
            totalActiveMemberCount !== undefined
        ) {
            setPulseResponseRate(Math.floor((currentPulse?.results?.length / totalActiveMemberCount) * 100));
        }
        //do we have the right items for the calculation?
        //no
        else {
            setPulseResponseRate(0);
        }
    }, [currentPulse, totalActiveMemberCount]);

    // Render Bar Chart
    useEffect(() => {
        if (!barChartRef.current && barChartData.length > 0) {
            barChartRef.current = am4core.create(BAR_CHART_ID, am4charts.XYChart);

            /* ---------------- */

            // Bar Chart Data Structure
            // [{}, {},]
            // date number (positive) number2 (negative)
            barChartRef.current.data = barChartData;

            // Chart xAxis Set up
            const categoryAxis = barChartRef.current.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'date';
            categoryAxis.title.text = 'Dates';
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.cellStartLocation = 0.2;
            categoryAxis.renderer.minGridDistance = 50;
            categoryAxis.renderer.cellEndLocation = 0.8;

            // Chart yAxis Set up
            const valueAxis = barChartRef.current.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = 'Daily NEI';
            valueAxis.calculateTotals = true;
            valueAxis.min = -100;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;

            // Positive Series
            const series = barChartRef.current.series.push(new am4charts.ColumnSeries());
            series.name = 'Weekly NEI Positive';

            series.columns.template.adapter.add('fill', (fill: any, target: any) => {
                // // (If column is currently selected, display as special color)
                // if (moment(currentPulse?.dateStart).format('MM/DD') == target.dataItem.categoryX) {
                //     return am4core.color(colors.red);
                // }

                return am4core.color(colors.aqua);
            });

            series.dataFields.valueY = 'number';
            series.dataFields.valueYShow = 'value';
            series.dataFields.categoryX = 'date';
            series.stacked = true;

            // Positive Series Labels
            const positiveLabels = series.bullets.push(new am4charts.LabelBullet());
            positiveLabels.label.truncate = false;
            positiveLabels.label.text = "{valueY.total.formatNumber('#.')}";
            positiveLabels.label.fill = am4core.color('#000000');
            positiveLabels.locationY = 0;
            positiveLabels.dy = -10;

            // Negative Series
            const series2 = barChartRef.current.series.push(new am4charts.ColumnSeries());
            series2.name = 'Weekly NEI Negative';
            series2.columns.template.fill = am4core.color(colors.darkBlue);
            series2.dataFields.valueY = 'number2';
            series2.dataFields.categoryX = 'date';
            series2.stacked = true;

            // Negative Series Labels
            const negativeLabels = series2.bullets.push(new am4charts.LabelBullet());
            negativeLabels.label.truncate = false;
            negativeLabels.label.text = "-{valueY.total.formatNumber('#.')}";
            negativeLabels.label.fill = am4core.color('#000000');
            negativeLabels.locationY = 0;
            negativeLabels.dy = 10;

            // Change current pulse on click
            [series, series2].forEach((s) => {
                s.columns.template.events.on('hit', (e: any) => {
                    const myPulse = project.pulses.find(
                        (p) => moment(p.dateStart).format('MM/DD') == e.target.dataItem.categoryX,
                    );
                    setCurrentPulse(myPulse);
                });
            });
        }

        // (Clean up upon exiting page)
        return () => {
            barChartRef.current && barChartRef.current.dispose();
            barChartRef.current = null;
        };
    }, [barChartData]);

    // Render Bubble Chart
    useEffect(() => {
        //Create Bubble Chart https://www.amcharts.com/docs/v4/chart-types/force-directed/
        const chart2 = am4core.create('chartdiv2', am4plugins_forceDirected.ForceDirectedTree);

        //Bubble Chart Setup
        const bubbleSeries = chart2.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
        //Bubble Chart Data Structure
        chart2.data = bubbleData;

        //Bubble Chart Styles Setup
        bubbleSeries.dataFields.color = 'color';
        bubbleSeries.dataFields.value = 'value';
        bubbleSeries.dataFields.name = 'name';
        bubbleSeries.nodes.template.label.text = '{name}';
        bubbleSeries.fontSize = 18;
        bubbleSeries.minRadius = 35;
        bubbleSeries.maxRadius = 125;

        return () => {
            chart2.dispose();
        };
    }, [bubbleData]);

    return (
        <StandardLayout>
            <TitleContainer>
                <Text size="28">{project.name}</Text>
                <BackButton noSpacing columnState={column} />
                <PrintButton />
            </TitleContainer>
            <GroupInformation groups={groupData} />
            {project?.projectType !== 'One-Time Yes/No Question' ? (
                <DashboardContainer>
                    <ReportContainer>
                        <FlexRow>
                            <FlexColumn>
                                <Text size="28">{NeiTitle(project.projectType, currentPulse)}</Text>
                                <Text size="100">{neiNumber !== null ? neiNumber : 'N/A'}</Text>
                            </FlexColumn>
                            <FlexColumn>
                                <GaugeChart
                                    className="GaugeStyles"
                                    arcWidth={1}
                                    marginInPercent={0}
                                    cornerRadius={0}
                                    arcPadding={0}
                                    arcsLength={[40, 20, 40]}
                                    colors={[colors.darkBlue, colors.lightBlue, colors.aqua]}
                                    needleColor={colors.red}
                                    needleBaseColor={colors.red}
                                    hideText
                                    percent={neiNumber === null ? 0.5 : (neiNumber + 100) / 200}
                                    style={{ width: '350px' }}
                                />
                                <GaugeRow>
                                    <Text size="8">NEGATIVE</Text>
                                    <Text size="8">NEUTRAL</Text>
                                    <Text size="8">POSITIVE</Text>
                                </GaugeRow>
                            </FlexColumn>
                        </FlexRow>
                    </ReportContainer>
                    {project?.projectType !== 'One-Time Sample' &&
                    project.projectType !== 'One-Time Custom Question' ? (
                        <>
                            <ReportContainer>
                                <Text size="28">NEI for the most recent pulses</Text>
                                <ChartContainer>
                                    <div id={BAR_CHART_ID} style={{ height: '100%' }}></div>
                                </ChartContainer>
                            </ReportContainer>
                            <ReportContainer>
                                <BarGraphContainer>
                                    <Text size="28">{currentPulse?.question}</Text>
                                    <BarContainer>
                                        {(percentData.Negative / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Negative / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Negative / totalResponses) * 100
                                                }
                                                color="blue"
                                            >
                                                <Text size="28" color="white" bold>
                                                    {((percentData.Negative / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="white" bold>
                                                    NEGATIVE
                                                </Text>
                                            </Bar>
                                        ) : null}
                                        {(percentData.Neutral / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Neutral / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Neutral / totalResponses) * 100
                                                }
                                                color="gray"
                                            >
                                                <Text size="28" color="white" bold>
                                                    {((percentData.Neutral / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="white" bold>
                                                    NEUTRAL
                                                </Text>
                                            </Bar>
                                        ) : null}
                                        {(percentData.Positive / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Positive / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Positive / totalResponses) * 100
                                                }
                                                color="aqua"
                                            >
                                                <Text size="28" color="blue" bold>
                                                    {((percentData.Positive / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="blue" bold>
                                                    POSITIVE
                                                </Text>
                                            </Bar>
                                        ) : null}
                                    </BarContainer>
                                    <Text size="14">
                                        {pulseResponseRate !== NaN ? pulseResponseRate : '0'}% Response rate
                                    </Text>
                                </BarGraphContainer>
                            </ReportContainer>
                            <ReportContainer>
                                <Text size="28">{BubbleTitle(currentPulse)}</Text>
                                <ChartContainer>
                                    <div id="chartdiv2"></div>
                                </ChartContainer>
                                <Text size="14">
                                    {pulseResponseRate !== NaN ? pulseResponseRate : '0'}% Response rate
                                </Text>
                            </ReportContainer>
                        </>
                    ) : (
                        <>
                            <ReportContainer>
                                <Text size="28">{BubbleTitle(currentPulse)}</Text>
                                <ChartContainer>
                                    <div id="chartdiv2"></div>
                                </ChartContainer>
                                <Text size="14">
                                    {pulseResponseRate !== NaN ? pulseResponseRate : '0'}% Response rate
                                </Text>
                            </ReportContainer>
                            <ReportContainer>
                                <BarGraphContainer>
                                    <Text size="28">
                                        {project.pulses && project.pulses.length > 0 ? project.pulses[0]?.question : ''}
                                    </Text>
                                    <BarContainer>
                                        {(percentData.Negative / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Negative / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Negative / totalResponses) * 100
                                                }
                                                color="blue"
                                            >
                                                <Text size="28" color="white" bold>
                                                    {((percentData.Negative / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="white" bold>
                                                    NEGATIVE
                                                </Text>
                                            </Bar>
                                        ) : null}
                                        {(percentData.Neutral / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Neutral / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Neutral / totalResponses) * 100
                                                }
                                                color="gray"
                                            >
                                                <Text size="28" color="white" bold>
                                                    {((percentData.Neutral / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="white" bold>
                                                    NEUTRAL
                                                </Text>
                                            </Bar>
                                        ) : null}
                                        {(percentData.Positive / totalResponses) * 100 > 0 ? (
                                            <Bar
                                                percent={
                                                    (percentData.Positive / totalResponses) * 100 < 10
                                                        ? 10
                                                        : (percentData.Positive / totalResponses) * 100
                                                }
                                                color="aqua"
                                            >
                                                <Text size="28" color="blue" bold>
                                                    {((percentData.Positive / totalResponses) * 100).toFixed(2)}%
                                                </Text>
                                                <Text size="14" color="blue" bold>
                                                    POSITIVE
                                                </Text>
                                            </Bar>
                                        ) : null}
                                    </BarContainer>
                                    <Text size="14">
                                        {pulseResponseRate !== NaN ? pulseResponseRate : '0'}% Response rate
                                    </Text>
                                </BarGraphContainer>
                            </ReportContainer>
                        </>
                    )}
                </DashboardContainer>
            ) : (
                <ReportContainer>
                    <YesOrNoGraphContainer>
                        <Text size="28">{currentPulse?.question}</Text>
                        <YesOrNoContainer>
                            {(yesOrNoData.Negative / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100 > 0 ? (
                                <StyledBar
                                    percent={
                                        (yesOrNoData.Negative / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100
                                    }
                                    color="blue"
                                >
                                    <DislikeOutlined style={{ fontSize: '40px', color: colors.white }} />
                                    <Text size="28" color="white" bold>
                                        {(yesOrNoData.Negative / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100}%
                                    </Text>
                                </StyledBar>
                            ) : null}
                            {(yesOrNoData.Positive / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100 > 0 ? (
                                <StyledBar
                                    percent={
                                        (yesOrNoData.Positive / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100
                                    }
                                    color="aqua"
                                >
                                    <LikeOutlined style={{ transform: 'scaleX(-1)', fontSize: '40px' }} />
                                    <Text size="28" color="blue" bold>
                                        {(yesOrNoData.Positive / (yesOrNoData.Negative + yesOrNoData.Positive)) * 100}%
                                    </Text>
                                </StyledBar>
                            ) : null}
                        </YesOrNoContainer>
                        <Text size="14">{pulseResponseRate !== NaN ? pulseResponseRate : '0'}% Response rate</Text>
                    </YesOrNoGraphContainer>
                </ReportContainer>
            )}
        </StandardLayout>
    );
};
export default NEIDashboard;
