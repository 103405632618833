import React from 'react';
import { Button } from 'antd';
import colors from '../constants/colors';

const PrintButton = () => {
    return (
        <Button
            onClick={() => {
                //are we in a dev environment?
                //yes
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('Print PDF button clicked!');
                }
                window.print();
            }}
            size="large"
            style={{ color: colors.white, backgroundColor: colors.red }}
        >
            Save PDF
        </Button>
    );
};

export default PrintButton;
