import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import StandardLayout, { NavContainer } from '../../layout/StandardLayout';
import scale from '../../constants/scale';
import colors from '../../constants/colors';
import { device } from '../../constants/device';
import NEIBanner from '../../components/NEIBanner';
import Text from '../../components/Text';
import Footer from '../../components/Footer';
import Background from '../../assets/images/background.png';
import useRouter from '../../hooks/useRouter';
import FeelalyticsLogo from '../../components/FeelalyticsLogo';

// Image Grid
import g26 from '../../assets/images/onbording/Mask Group 26@2x.png';
import g25 from '../../assets/images/onbording/Mask Group 25@2x.png';
import g27 from '../../assets/images/onbording/Mask Group 27@2x.png';
import g28 from '../../assets/images/onbording/Mask Group 28@2x.png';
import g29 from '../../assets/images/onbording/Mask Group 29@2x.png';
import g30 from '../../assets/images/onbording/Mask Group 30@2x.png';
import g31 from '../../assets/images/onbording/Mask Group 31@2x.png';
import g32 from '../../assets/images/onbording/Mask Group 32@2x.png';
import g33 from '../../assets/images/onbording/Mask Group 33@2x.png';

type Landing = {
    changeStep: (direction: 'prev' | 'next') => void;
};

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: repeat(3, min-content);
    // grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.md};
    border-radius: 2px;
`;

const CallToAction = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.lg};
    @media ${device.laptop} {
        justify-content: center;
        align-items: center;
    }
`;

const ImgContainer = styled.div`
    display: grid;
    width: 328px;
    height: 328px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: ${scale.sm};
`;

const Highlight = styled.span`
    background-color: ${colors.aqua};
`;

const StyledButton = styled(Button)`
    width: 174px;
`;

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const Landing: React.FC<Landing> = ({ changeStep }) => {
    const imgs = [g25, g26, g27, g28, g29, g30, g31, g32, g33];
    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>
            <Card>
                <CallToAction>
                    <Text>Welcome!</Text>
                    <Text size="22">
                        The <Highlight>Net Emotion Score</Highlight> is a single number between -100 and +100 that tells
                        you how happy – or unhappy – your teams are.
                    </Text>
                    <StyledButton
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                        onClick={() => changeStep('next')}
                    >
                        Continue
                    </StyledButton>
                </CallToAction>
                <ImgContainer>
                    {imgs.map((src, index) => {
                        return <img key={index} src={src} alt={`group image #${index}`} width="104px" height="104px" />;
                    })}
                </ImgContainer>
            </Card>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};
export default Landing;
