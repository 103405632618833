// defines the table data columns for different project status'
import React from 'react';
import moment from 'moment';
import Project from '../types/project';
import styled from 'styled-components';
import {
    RedoOutlined,
    CalendarOutlined,
    DashboardOutlined,
    NotificationOutlined,
    QuestionOutlined,
    DislikeOutlined,
    LikeOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { Tooltip } from 'antd';
import Text from '../components/Text';
import scale from './scale';
import colors from './colors';

const LegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.sm};
`;

const LegendRow = styled(LegendContainer)`
    flex-direction: row;
    align-items: center;
`;

const ProjectTypeLegend = () => {
    return (
        <LegendContainer>
            <LegendRow>
                <RedoOutlined style={{ fontSize: '15px' }} />
                <Text color="white">Ongoing</Text>
            </LegendRow>
            <LegendRow>
                <CalendarOutlined style={{ fontSize: '20px' }} />
                <Text color="white">Quarterly Sample</Text>
            </LegendRow>
            <LegendRow>
                <DashboardOutlined style={{ fontSize: '20px' }} />
                <Text color="white">One-Time Sample</Text>
            </LegendRow>
            <LegendRow>
                <NotificationOutlined style={{ fontSize: '20px' }} />
                <Text color="white">Announcement</Text>
            </LegendRow>
            <LegendRow>
                <QuestionOutlined style={{ fontSize: '20px' }} />
                <Text color="white">Custom Question</Text>
            </LegendRow>
            <LegendRow>
                <LikeOutlined style={{ transform: 'scaleX(-1)', fontSize: '20px' }} />
                <DislikeOutlined style={{ fontSize: '20px' }} />
                <Text color="white">Yes/No Question</Text>
            </LegendRow>
            <LegendRow>
                <VideoCameraOutlined style={{ fontSize: '20px' }} />
                <Text color="white">Content Feedback</Text>
            </LegendRow>
        </LegendContainer>
    );
};

const projectIcon = (value: string) => {
    switch (value) {
        case 'Ongoing Sample':
            return (
                <Tooltip title="Ongoing Sample" mouseEnterDelay={1}>
                    <RedoOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'Quarterly Sample':
            return (
                <Tooltip title="Quarterly Sample" mouseEnterDelay={1}>
                    <CalendarOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'One-Time Sample':
            return (
                <Tooltip title="One-Time Sample" mouseEnterDelay={1}>
                    <DashboardOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'One-Time Announcement':
            return (
                <Tooltip title="One-Time Announcement" mouseEnterDelay={1}>
                    <NotificationOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'One-Time Custom Question':
            return (
                <Tooltip title="One-Time Custom Question" mouseEnterDelay={1}>
                    <QuestionOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'One-Time Yes/No Question':
            return (
                <Tooltip title="One-Time Yes/No Question" mouseEnterDelay={1}>
                    <LikeOutlined style={{ transform: 'scaleX(-1)', fontSize: '20px' }} />
                    <DislikeOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
        case 'Content Feedback':
            return (
                <Tooltip title="Content Feedback" mouseEnterDelay={1}>
                    <VideoCameraOutlined style={{ fontSize: '20px' }} />
                </Tooltip>
            );
    }
};

//SCHEDULED
export const scheduled: ColumnsType<Project> = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: (
            <Tooltip title={ProjectTypeLegend} mouseEnterDelay={1}>
                Type
            </Tooltip>
        ),
        dataIndex: 'projectType',
        width: 80,
        align: 'center',
        render: (value) => projectIcon(value),
        filters: [
            {
                text: 'Ongoing',
                value: 'Ongoing Sample',
            },
            {
                text: 'Quarterly',
                value: 'Quarterly Sample',
            },
            {
                text: 'One-Time',
                value: 'One-Time Sample',
            },
            {
                text: 'Announcement',
                value: 'One-Time Announcement',
            },
            {
                text: 'Custom Question',
                value: 'One-Time Custom Question',
            },
            {
                text: 'Yes/No',
                value: 'One-Time Yes/No Question',
            },
            {
                text: 'Content Feedback',
                value: 'Content Feedback',
            },
        ],
        onFilter: (value, record) => record.projectType.includes(String(value)),
    },
    {
        title: 'Start Date',
        dataIndex: 'dateStart',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateStart).unix() - moment(b.dateStart).unix(),
    },
    {
        title: 'Time',
        dataIndex: 'sampleTimes',
        width: 90,
        align: 'left',
        render: (value) => (value && value.timeStart ? moment(value.timeStart).local().format('h:mm a') : ''),
    },
    {
        title: 'End Date',
        dataIndex: 'dateEnd',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateEnd).unix() - moment(b.dateEnd).unix(),
    },
    {
        title: 'Groups',
        dataIndex: 'groupNames',
        width: 190,
        align: 'left',
    },
    {
        title: 'Total Members',
        dataIndex: 'totalMembers',
        width: 130,
        align: 'center',
    },
];

//IN PROGRESS
export const inProgress: ColumnsType<Project> = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: (
            <Tooltip title={ProjectTypeLegend} mouseEnterDelay={1}>
                Type
            </Tooltip>
        ),
        dataIndex: 'projectType',
        width: 80,
        align: 'center',
        render: (value) => projectIcon(value),
        filters: [
            {
                text: 'Ongoing',
                value: 'Ongoing Sample',
            },
            {
                text: 'Quarterly',
                value: 'Quarterly Sample',
            },
            {
                text: 'One-Time',
                value: 'One-Time Sample',
            },
            {
                text: 'Announcement',
                value: 'One-Time Announcement',
            },
            {
                text: 'Custom Question',
                value: 'One-Time Custom Question',
            },
            {
                text: 'Yes/No',
                value: 'One-Time Yes/No Question',
            },
            {
                text: 'Content Feedback',
                value: 'Content Feedback',
            },
        ],
        onFilter: (value, record) => record.projectType.includes(String(value)),
    },
    {
        title: 'Start Date',
        dataIndex: 'dateStart',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateStart).unix() - moment(b.dateStart).unix(),
    },
    {
        title: 'Time',
        dataIndex: 'sampleTimes',
        width: 90,
        align: 'left',
        render: (value) => (value && value.timeStart ? moment(value.timeStart).local().format('h:mm a') : ''),
    },
    {
        title: 'End Date',
        dataIndex: 'dateEnd',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateEnd).unix() - moment(b.dateEnd).unix(),
    },
    {
        title: 'Groups',
        dataIndex: 'groupNames',
        width: 190,
        align: 'left',
    },
    {
        title: 'Total Members',
        dataIndex: 'totalMembers',
        width: 130,
        align: 'center',
    },
    {
        title: 'Response Rate',
        dataIndex: 'responseRate',
        width: 140,
        align: 'center',
        render: (value) => {
            if (value >= 0 && value !== null) {
                return `${value}%`;
            }
        },
    },
    {
        title: 'Latest NEI',
        dataIndex: 'latestNEI',
        width: 100,
        align: 'center',
        render: (value) => {
            if (value !== null) {
                if (value >= -100 && value <= -21) {
                    return {
                        props: {
                            style: {
                                background: colors.red,
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
                if (value >= -20 && value <= 20) {
                    return {
                        props: {
                            style: {
                                background: '#99A5B7',
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
                if (value <= 100 && value >= 21) {
                    return {
                        props: {
                            style: {
                                background: colors.aqua,
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
            }
        },
    },
];

//COMPLETED
export const completed: ColumnsType<Project> = [
    { title: 'Name', dataIndex: 'name' },
    {
        title: (
            <Tooltip title={ProjectTypeLegend} mouseEnterDelay={1}>
                Type
            </Tooltip>
        ),
        dataIndex: 'projectType',
        width: 80,
        align: 'center',
        render: (value) => projectIcon(value),
        filters: [
            {
                text: 'Ongoing',
                value: 'Ongoing Sample',
            },
            {
                text: 'Quarterly',
                value: 'Quarterly Sample',
            },
            {
                text: 'One-Time',
                value: 'One-Time Sample',
            },
            {
                text: 'Announcement',
                value: 'One-Time Announcement',
            },
            {
                text: 'Custom Question',
                value: 'One-Time Custom Question',
            },
            {
                text: 'Yes/No',
                value: 'One-Time Yes/No Question',
            },
            {
                text: 'Video Feedback',
                value: 'Content Feedback',
            },
        ],
        onFilter: (value, record) => record.projectType.includes(String(value)),
    },
    {
        title: 'Start Date',
        dataIndex: 'dateStart',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateStart).unix() - moment(b.dateStart).unix(),
    },
    {
        title: 'Time',
        dataIndex: 'sampleTimes',
        width: 90,
        align: 'left',
        render: (value) => moment(value.timeStart).local().format('h:mm a'),
    },
    {
        title: 'End Date',
        dataIndex: 'dateEnd',
        width: 120,
        align: 'center',
        render: (value) => moment(value).format('M/DD/YY'),
        sorter: (a, b) => moment(a.dateEnd).unix() - moment(b.dateEnd).unix(),
    },
    {
        title: 'Groups',
        dataIndex: 'groupNames',
        width: 190,
        align: 'left',
    },
    {
        title: 'Total Members',
        dataIndex: 'totalMembers',
        width: 130,
        align: 'center',
    },
    {
        title: 'Response Rate',
        dataIndex: 'responseRate',
        width: 140,
        align: 'center',
        render: (value) => {
            if (value >= 0 && value !== null) {
                return `${value}%`;
            }
        },
    },
    {
        title: 'Latest NEI',
        dataIndex: 'latestNEI',
        width: 100,
        align: 'center',
        render: (value) => {
            if (value !== null) {
                if (value >= -100 && value <= -21) {
                    return {
                        props: {
                            style: {
                                background: colors.red,
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
                if (value >= -20 && value <= 20) {
                    return {
                        props: {
                            style: {
                                background: '#99A5B7',
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
                if (value <= 100 && value >= 21) {
                    return {
                        props: {
                            style: {
                                background: colors.aqua,
                                color: colors.darkBlue,
                                fontWeight: '500',
                            },
                        },
                        children: <div>{value}</div>,
                    };
                }
            }
        },
    },
];
