import { Button, Input, Radio } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useMutation } from 'react-query';

import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import FeelalyticsLogo from '../../components/FeelalyticsLogo';
import Footer from '../../components/Footer';
import { NavContainer } from '../../layout/StandardLayout';
import Background from '../../assets/images/background.png';
import companies from '../../query-functions/companies';
import { FormFooter, StyledForm } from '../../components/Styled/Form';
import groups from '../../query-functions/groups';
import storage from '../../utilities/localStorage';
import usePhoneFormatter from '../../hooks/usePhoneFormatter';
import { PhoneOutlined } from '@ant-design/icons';

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
    max-width: 50vw;
`;

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;

const StyledRadio = styled(Radio.Group)`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
`;

type Members = {
    changeStep: (direction: 'prev' | 'next') => void;
    values: any;
    setFieldValue: any;
    handleSubmit: any;
};

const Members: React.FC<Members> = ({ changeStep, values, setFieldValue, handleSubmit }) => {
    const { num: num1, setNumber: setNumber1, formatted: formatted1 } = usePhoneFormatter();
    const { num: num2, setNumber: setNumber2, formatted: formatted2 } = usePhoneFormatter();
    const { num: num3, setNumber: setNumber3, formatted: formatted3 } = usePhoneFormatter();
    const { num: num4, setNumber: setNumber4, formatted: formatted4 } = usePhoneFormatter();
    const { num: num5, setNumber: setNumber5, formatted: formatted5 } = usePhoneFormatter();
    const { num: num6, setNumber: setNumber6, formatted: formatted6 } = usePhoneFormatter();

    useEffect(() => {
        setFieldValue('members[0].phoneNumber', num1);
    }, [num1]);
    useEffect(() => {
        setFieldValue('members[1].phoneNumber', num2);
    }, [num2]);
    useEffect(() => {
        setFieldValue('members[2].phoneNumber', num3);
    }, [num3]);
    useEffect(() => {
        setFieldValue('members[3].phoneNumber', num4);
    }, [num4]);
    useEffect(() => {
        setFieldValue('members[4].phoneNumber', num5);
    }, [num5]);
    useEffect(() => {
        setFieldValue('members[5].phoneNumber', num6);
    }, [num6]);

    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>
            <Card>
                <Text bold>Add the first six team members</Text>
                <Text size="12">
                    Why six? We ask for a minimum group size so that your team members can truly express how they feel
                    while maintaining the psychological safety of anonymity
                </Text>
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted1}
                    placeholder="(512) 555-1212"
                    maxLength={14}
                    onChange={(e) => {
                        setNumber1(e.target.value);
                    }}
                />
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted2}
                    placeholder="(512) 555-1212"
                    maxLength={15}
                    onChange={(e) => {
                        setNumber2(e.target.value);
                    }}
                />
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted3}
                    placeholder="(512) 555-1212"
                    maxLength={15}
                    onChange={(e) => {
                        setNumber3(e.target.value);
                    }}
                />
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted4}
                    placeholder="(512) 555-1212"
                    maxLength={15}
                    onChange={(e) => {
                        setNumber4(e.target.value);
                    }}
                />
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted5}
                    placeholder="(512) 555-1212"
                    maxLength={15}
                    onChange={(e) => {
                        setNumber5(e.target.value);
                    }}
                />
                <Input
                    prefix={<PhoneOutlined />}
                    style={{ width: 245 }}
                    value={formatted6}
                    placeholder="(512) 555-1212"
                    maxLength={15}
                    onChange={(e) => {
                        setNumber6(e.target.value);
                    }}
                />
                <PositionedButton style={{ color: colors.white, backgroundColor: colors.red }} onClick={handleSubmit}>
                    Continue
                </PositionedButton>
            </Card>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};

export default Members;
