import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import error from '../utilities/error';
import storage from '../utilities/localStorage';

type RemoveTypes = {
    id: string;
};

const companies = {
    get: async () => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.company, {
                headers,
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
    create: async (companyData: any) => {
        const headers = {};

        const { data } = await axios
            .post(api.company, companyData, {
                headers,
            })
            .catch((err) => {
                error(err, 'companies.ts');
                throw new Error(err);
            });

        return data;
    },
    remove: async ({ id }: RemoveTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .delete(api.company, {
                params: {
                    id,
                },
                headers,
            })
            .catch((err) => {
                throw new Error(err);
            });
        return data;
    },
};

export default companies;
