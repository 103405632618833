import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';
import { Button, Table } from 'antd';
import useRouter from '../../hooks/useRouter';
import projects from '../../query-functions/projects';
import pulse, { PulseData, GroupsArray } from '../../query-functions/pulse';
import groups from '../../query-functions/groups';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Group from '../../types/group';
import storage from '../../utilities/localStorage';
import SHORTNotification from '../SHORTNotification';
import { days } from '../../constants/projects';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.md};
    grid-template-rows: auto;
    margin-top: ${scale.xl};
`;

const TableContainer = styled.div`
    background-color: ${colors.white};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const OptionCard = styled.div`
    background-color: ${colors.white};
    height: min-content;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const SampleOptionCard = styled(OptionCard)`
    display: flex;
    gap: ${scale.xl};
    flex-direction: column;
    padding: ${scale.lg};
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${scale.md};
`;

const StyledButton = styled(Button)`
    color: ${colors.red};
    border: 1px solid ${colors.red};
`;

type ProjectTypes = {
    projectType: string;
    name: string;
    dateStart: string;
    dateEnd: string;
    sampleTimes: {
        timeStart: string | undefined;
        timeEnd: string | undefined;
    };
    quarterStartMonth: string;
    daysToSample: number[];
    zone: string;
    groups: string[];
    message?: string;
};

//TODO Fix Project any
const ChooseGroups: React.FC<{ ProjectData: any; onClick: () => void }> = ({ ProjectData, onClick }) => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<Group[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<Group['id'][]>([]);
    const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
    const [customMessage, setCustomMessage] = useState<string>('');

    const company: any = storage.get('company');
    const groupsQuery = ['groups', company.companyId];
    const grQuery = useQuery(groupsQuery, groups.getAll, { refetchOnWindowFocus: false });

    const projectPulseCreateMutation = useMutation(pulse.createProjectPulse, {
        onSuccess: () => {
            router.push('/');
        },
    });

    const projectWithPulseCreateMutation = useMutation(projects.create, {
        onSuccess: (data) => {
            SHORTNotification('Project created');
            queryClient.invalidateQueries('projects');
            const PulseObject = PulseData(data.id);
            projectPulseCreateMutation.mutate(PulseObject);
        },
    });

    const projectCreateMutation = useMutation(projects.create, {
        onSuccess: () => {
            SHORTNotification('Project created');
            queryClient.invalidateQueries('projects');
            router.push('/');
        },
    });

    const PulseData = (projectId: string) => {
        const pulseData: PulseData = {
            companyId: ProjectData.companyId,
            projectId: projectId,
            groups: [],
            question: customMessage,
            dateStart: ProjectData.dateStart,
            mentalHealthQuestion: false,
            announcementOnly: false,
            yesNoOnly: false,
        };
        const PulseOptions = PulseDataOptions();

        const GroupOptions = PulseGroupOptions();

        return { ...pulseData, ...GroupOptions, ...PulseOptions };
    };

    const PulseDataOptions = () => {
        switch (ProjectData.projectType) {
            case 'One-Time Sample':
                return {
                    mentalHealthQuestion: true,
                    announcementOnly: false,
                    yesNoOnly: false,
                };
            case 'One-Time Announcement':
                return {
                    mentalHealthQuestion: false,
                    announcementOnly: true,
                    yesNoOnly: false,
                };
            case 'One-Time Custom Question':
                return {
                    mentalHealthQuestion: false,
                    announcementOnly: false,
                    yesNoOnly: false,
                };
            case 'One-Time Yes/No Question':
                return {
                    mentalHealthQuestion: false,
                    announcementOnly: false,
                    yesNoOnly: true,
                };
        }
    };

    const PulseGroupOptions = () => {
        const groupsArray: GroupsArray[] = [];
        selectedGroups.forEach((group: Group) => {
            groupsArray.push({ id: group.id, name: group.name });
        });
        return { groups: groupsArray };
    };

    useEffect(() => {
        const { data, isLoading } = grQuery;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
        if (data) {
            setGroupData([]);
            setGroupData(data);
        }

        if (ProjectData?.message?.length > 1) {
            setCustomMessage('');
            setCustomMessage(ProjectData.message);
        }
    }, [grQuery.data, grQuery.isLoading]);

    useEffect(() => {
        const { isLoading } = projectCreateMutation;
        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
    }, [projectCreateMutation.isLoading]);

    const CreateProject = () => {
        setLoading(true);
        const project = ProjectData;

        //turn string data into number for backend
        if (project.quarterStartMonth === '') {
            project.quarterStartMonth = null;
        }
        if (project.quarterStartMonth === 'First Month (Jan, Apr, Jul, Oct)') {
            project.quarterStartMonth = 1;
        }
        if (project.quarterStartMonth === 'Second Month (Feb, May, Aug, Nov)') {
            project.quarterStartMonth = 2;
        }
        if (project.quarterStartMonth === 'Third Month (Mar, Jun, Sep, Dec)') {
            project.quarterStartMonth = 3;
        }
        selectedKeys.forEach((item) => project.groups.push(item));
        delete project.message;

        //are we in a dev environment?
        //yes
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log({ project });
        }

        //pick mutation flow
        if (ProjectData.projectType === 'Ongoing Sample' || ProjectData.projectType === 'Quarterly Sample') {
            return projectCreateMutation.mutate(project);
        } else {
            return projectWithPulseCreateMutation.mutate(project);
        }
    };

    const NumbersToDays = (data: number[]) => {
        const daysString = data.map((item: number) => `${days[item]}, `);
        return daysString.join(' ');
    };

    const TrimMonthString = (data: string) => {
        const monthString: string = data;
        const matchString: string[] | null = String(monthString).match(/\b[a-zA-Z]{3}\b/g);
        if (matchString !== null) {
            return matchString.join(', ');
        } else {
            return;
        }
    };

    const SetProjectInfo = (data: ProjectTypes) => {
        const daysString: string = NumbersToDays(data.daysToSample);

        switch (data.projectType) {
            //TODO setup all use cases
            case 'Ongoing Sample':
                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">Start date: {moment(data.dateStart).format('l')}</Text>
                            <Text size="22">End date: {moment(data.dateEnd).format('l')}</Text>
                        </div>
                        <div>
                            <Text size="22">Sending on days: {daysString.slice(0, daysString.length - 2)}</Text>
                            <Text size="22">
                                During this time window:{' '}
                                {`${moment(data.sampleTimes.timeStart).local().format('h:mm a')} - ${moment(
                                    data.sampleTimes.timeEnd,
                                )
                                    .local()
                                    .format('h:mm a')}`}
                            </Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
            case 'Quarterly Sample':
                const matchedString = TrimMonthString(data.quarterStartMonth);

                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">Start date: {moment(data.dateStart).format('l')}</Text>
                            <Text size="22">End date: {moment(data.dateEnd).format('l')}</Text>
                        </div>
                        <div>
                            {/* <Text size="22">Sending in months: {matchedString}</Text> */}
                            <Text size="22">Sending in months: {matchedString ? matchedString : null}</Text>
                        </div>
                        <div>
                            <Text size="22">Sending on days: {daysString.slice(0, daysString.length - 2)}</Text>
                            <Text size="22">
                                During this time window:{' '}
                                {`${moment(data.sampleTimes.timeStart).local().format('h:mm a')} - ${moment(
                                    data.sampleTimes.timeEnd,
                                )
                                    .local()
                                    .format('h:mm a')}`}
                            </Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
            case 'One-Time Sample':
                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">Selected Date: {moment(data.dateStart).format('l')}</Text>
                        </div>
                        <div>
                            <Text size="22">
                                {`Sending at this time: ${moment(data.sampleTimes.timeStart)
                                    .local()
                                    .format('LT')
                                    .toLocaleLowerCase()}`}
                            </Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
            case 'One-Time Announcement':
                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">
                                Selected date:{' '}
                                {ProjectData.sendNow === true
                                    ? moment().format('l')
                                    : moment(data.dateStart).format('l')}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">
                                {ProjectData.sendNow === true
                                    ? 'Sending: immediately'
                                    : `Sending at this time: ${moment(data.sampleTimes.timeStart)
                                          .local()
                                          .format('LT')
                                          .toLocaleLowerCase()}`}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">Announcement: {data.message}</Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
            case 'One-Time Custom Question':
                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">
                                Selected date:{' '}
                                {ProjectData.sendNow === true
                                    ? moment().format('l')
                                    : moment(data.dateStart).format('l')}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">
                                {ProjectData.sendNow === true
                                    ? 'Sending: immediately'
                                    : `Sending at this time: ${moment(data.sampleTimes.timeStart)
                                          .local()
                                          .format('LT')
                                          .toLocaleLowerCase()}`}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">Custom question: {data.message}</Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <Text size="22">Name: {data.name}</Text>
                        <div>
                            <Text size="22">
                                Selected date:{' '}
                                {ProjectData.sendNow === true
                                    ? moment().format('l')
                                    : moment(data.dateStart).format('l')}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">
                                {ProjectData.sendNow === true
                                    ? 'Sending: immediately'
                                    : `Sending at this time: ${moment(data.sampleTimes.timeStart)
                                          .local()
                                          .format('LT')
                                          .toLocaleLowerCase()}`}
                            </Text>
                        </div>
                        <div>
                            <Text size="22">Yes/no question: {data.message}</Text>
                        </div>
                        <div>
                            <ButtonContainer>
                                <Button disabled={loading} type="text" onClick={() => router.push('/')}>
                                    X Cancel
                                </Button>
                                <StyledButton disabled={loading} onClick={() => onClick()}>
                                    Go Back to Settings
                                </StyledButton>
                                <Button
                                    disabled={loading || selectedKeys.length < 1}
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => CreateProject()}
                                >
                                    Confirm and Schedule
                                </Button>
                            </ButtonContainer>
                        </div>
                    </>
                );
        }
    };

    return (
        <Container>
            <TableContainer>
                <Table
                    //scroll={{ y: 540 }}
                    dataSource={groupData}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            width: 250,
                            sorter: (a: any, b: any) => (a.name > b.name ? 1 : -1),
                        },
                        {
                            title: 'Description',
                            dataIndex: 'description',
                            width: 295,
                        },
                        {
                            title: 'Members',
                            align: 'center',
                            dataIndex: 'members',
                            render: (d) => d.length,
                        },
                    ]}
                    rowSelection={{
                        type: 'checkbox',
                        columnWidth: '50px',
                        onChange: (rowKey, record) => {
                            setSelectedKeys(rowKey as string[]);
                            setSelectedGroups(record);
                        },
                        selectedRowKeys: selectedKeys,
                    }}
                    rowKey={(record) => record.id}
                    pagination={false}
                    loading={loading}
                />
            </TableContainer>
            <SampleOptionCard>
                <Text size="18" bold>
                    Confirm your project settings
                </Text>
                {SetProjectInfo(ProjectData)}
            </SampleOptionCard>
        </Container>
    );
};

export default ChooseGroups;
