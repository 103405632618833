import styled from 'styled-components';
import colors from '../../constants/colors';

const SpanRequired = styled.span`
    color: ${colors.red};
    font-size: 12px;
    vertical-align: top;
`;

export default SpanRequired;
