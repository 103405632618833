import React, { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import api from '../../constants/api';
import Auth from '../../types/auth';
import storage from '../../utilities/localStorage';
import error from '../../utilities/error';
import { useQueryClient } from 'react-query';
import Administrator from '../../types/administrator';
import BackButton from '../../components/BackButton';
import scale from '../../constants/scale';
import CustomCard from '../../components/CustomCard';
import Text from '../../components/Text';
import { FormFooter, StyledForm } from '../../components/Styled/Form';
import FormikLabel from '../../components/FormikLabel';
import { Formik } from 'formik';
import FormikField from '../../components/FormikField';
import Company from '../../types/company';
import SHORTNotification from '../../components/SHORTNotification';
import * as Yup from 'yup';
import colors from '../../constants/colors';

type RouterState = { administrator?: Administrator; companyId?: Company['id'] };

const FormSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    emailAddress: Yup.string().email('Invalid email').required('Required'),
    phoneNumber: Yup.string().min(10, 'Must be 10 digits').required('Required'),
});

const PageContainer = styled.div`
    padding: ${scale.xl} 0;
`;

const newEmployeeDefaults = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    department: '',
};

const AdministratorForm: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);

    const auth: Auth = storage.get('auth');
    const headers = {
        SECRET: auth?.secret,
        PASSPHRASE: auth?.passPhrase,
    };

    const { administrator, companyId } = router.location.state as RouterState;

    return (
        <StandardLayout page="administratorForm">
            <PageContainer>
                <BackButton />
                <CustomCard
                    formWidth
                    title={<Text size="22">{administrator ? 'Update Administrator' : 'New Administrator'}</Text>}
                >
                    <Formik
                        initialValues={administrator || newEmployeeDefaults}
                        onSubmit={async (values) => {
                            setLoading(true);
                            try {
                                if (administrator) {
                                    const { id, companyId } = administrator;

                                    await Axios.patch(
                                        api.administrators,
                                        { ...values, id, companyId },
                                        { headers },
                                    ).then(({ status }) => {
                                        if (status === 200) {
                                            SHORTNotification('Administrator updated');
                                        }
                                    });
                                }
                                if (!administrator) {
                                    await Axios.post(api.administrators, { ...values, companyId }, { headers }).then(
                                        ({ status }) => {
                                            if (status === 200) {
                                                SHORTNotification('Administrator created');
                                            }
                                        },
                                    );
                                }
                            } catch (err) {
                                error(err, 'AdministratorForm.tsx');
                            } finally {
                                queryClient.invalidateQueries(['employees', administrator?.companyId]);
                                setLoading(false);
                                router.history.goBack();
                            }
                        }}
                        validationSchema={FormSchema}
                    >
                        {({ errors, touched, submitForm, values: { phoneNumber } }) => (
                            <StyledForm>
                                <FormikLabel
                                    required
                                    error={errors.firstName}
                                    touched={touched.firstName}
                                    fieldLabel="First Name"
                                    field={<FormikField name="firstName" />}
                                />
                                <FormikLabel
                                    required
                                    error={errors.lastName}
                                    touched={touched.lastName}
                                    fieldLabel="Last Name"
                                    field={<FormikField name="lastName" />}
                                />
                                <FormikLabel
                                    required
                                    error={errors.phoneNumber}
                                    touched={touched.phoneNumber}
                                    fieldLabel="Phone"
                                    field={
                                        <FormikField
                                            maxLength={10}
                                            name="phoneNumber"
                                            value={
                                                phoneNumber?.startsWith('+')
                                                    ? phoneNumber?.replace(/(\d{2})(\d{4})(\d{6})/, '$1 $2 $3')
                                                    : phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            }
                                        />
                                    }
                                />
                                <FormikLabel
                                    required
                                    error={errors.emailAddress}
                                    touched={touched.emailAddress}
                                    fieldLabel="Email"
                                    field={<FormikField name="emailAddress" />}
                                />
                                <FormikLabel
                                    error={errors.department}
                                    touched={touched.department}
                                    fieldLabel="Department"
                                    field={<FormikField name="department" />}
                                />
                                <FormFooter>
                                    <Button
                                        loading={loading}
                                        onClick={submitForm}
                                        style={{ color: colors.white, backgroundColor: colors.red }}
                                        size="large"
                                    >
                                        {administrator ? 'Update' : 'Submit'}
                                    </Button>
                                </FormFooter>
                            </StyledForm>
                        )}
                    </Formik>
                </CustomCard>
            </PageContainer>
        </StandardLayout>
    );
};
export default AdministratorForm;
