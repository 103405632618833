import { Button, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';

type CompanyCard = {
    values: any;
    setFieldValue: any;
    changeStep: (direction: 'prev' | 'next') => void;
};

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;

const CompanyCard: React.FC<CompanyCard> = ({ values, setFieldValue, changeStep }) => {
    return (
        <Card>
            <Text size="22" center>
                Register
            </Text>

            <Input
                style={{ width: 245 }}
                value={values.name}
                placeholder="COMPANY NAME"
                onChange={(e) => {
                    setFieldValue('name', e.target.value);
                }}
            />

            <Input
                style={{ width: 245 }}
                value={values.contactFirstName}
                placeholder="FIRST NAME"
                onChange={(e) => {
                    setFieldValue('contactFirstName', e.target.value);
                }}
            />

            <Input
                style={{ width: 245 }}
                value={values.contactLastName}
                placeholder="LAST NAME"
                onChange={(e) => {
                    setFieldValue('contactLastName', e.target.value);
                }}
            />

            <PositionedButton
                onClick={() => changeStep('next')}
                style={{ color: colors.white, backgroundColor: colors.red }}
            >
                Next
            </PositionedButton>
        </Card>
    );
};

export default CompanyCard;
