import React from 'react';
import { Field, GenericFieldHTMLAttributes, FieldConfig } from 'formik';
import styled from 'styled-components';
import colors from '../constants/colors';
import scale from '../constants/scale';

const StyledField = styled(Field)`
    background-color: ${colors.white};
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    display: inline-block;
    line-height: 1.5715;
    margin-top: ${scale.md};
    padding: 6.5px 11px;
    transition: all 0.3s;
    width: 100%;
    min-height: ${scale.xxl};

    :hover {
        border-color: ${colors.aqua};
    }

    :focus {
        border-color: ${colors.aqua};
        outline: none;
        box-shadow: 0px 0px 0px 2px #e8faf9;
    }
`;

const FormikField: React.FC<GenericFieldHTMLAttributes & FieldConfig> = (props) => <StyledField {...props} />;

export default FormikField;
