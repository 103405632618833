import React, { useState } from 'react';
import NEIBanner from '../../components/NEIBanner';
import StandardLayout from '../../layout/StandardLayout';
import { Steps } from 'antd';
import Text from '../../components/Text';
import styled from 'styled-components';
import scale from '../../constants/scale';

// On Boarding Wizard Components
import ChooseGroupType from '../../components/Onboarding/ChooseGroupType';
import ChooseGroupName from '../../components/Onboarding/ChooseGroupName';
import SampleSends3a from '../../components/Onboarding/SampleSends3a';
import SampleSends3b from '../../components/Onboarding/SampleSends3b';
import ChooseSamplePeriod from '../../components/Onboarding/ChooseSamplePeriod';
import AddMembers from '../../components/Onboarding/AddMembers';

export type GroupType = 'single' | 'multi';

export type PeriodType = 'ongoing' | 'quarterly' | 'onDemand';

const { Step } = Steps;

const StyledSteps = styled(Steps)`
    margin-bottom: ${scale.lg};
`;

const StyledStep = styled(Step)`
    :hover {
        cursor: pointer;
    }
`;

const OnboardingWizard: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(-1);
    const [groupTypeSelected, setGroupTypeSelected] = useState<GroupType | null>(null);
    const [groupName, setGroupName] = useState<string>('');
    const [step2Component, setStep2Component] = useState<JSX.Element | null>(null);
    const [days, setDays] = useState<string[]>([]);
    const [time, setTime] = useState<string[]>([]);
    const [timeZone, setTimeZone] = useState<string>('');
    const [quarter, setQuarter] = useState<string>('');

    // TODO remove from final
    const StepNavagation = (step: number) => {
        if (groupTypeSelected != null) {
            setCurrentStep(step);
        }
    };

    const handleSelectedPeriod = (selected: PeriodType) => {
        switch (selected) {
            case 'ongoing':
                setStep2Component(
                    <SampleSends3a
                        onChangeCheckbox={(values) => setDays(values)}
                        onChangeTime={(values) => setTime(values)}
                        onChangeZone={(values) => setTimeZone(values)}
                        onClick={() => setCurrentStep(3)}
                    />,
                );
                setCurrentStep(2);
                break;
            case 'quarterly':
                setStep2Component(
                    <SampleSends3b
                        onChangeCheckbox={(values) => setDays(values)}
                        onChangeTime={(values) => setTime(values)}
                        onChangeZone={(values) => setTimeZone(values)}
                        onClick={() => setCurrentStep(3)}
                        onChangeQuarter={(values) => setQuarter(values)}
                    />,
                );
                setCurrentStep(2);
                break;
            case 'onDemand':
                setCurrentStep(3);
                break;
        }
    };

    const steps = [
        {
            title: 'Name your group',
            content: <ChooseGroupName onClick={() => setCurrentStep(1)} onChange={(value) => setGroupName(value)} />,
        },
        {
            title: 'Choose your sample period',
            content: <ChooseSamplePeriod onClick={(selected) => handleSelectedPeriod(selected)} />,
        },
        {
            title: 'Customize your sample sends',
            content: step2Component,
        },
        {
            title: 'Add your members',
            content: <AddMembers />,
        },
    ];

    return (
        <StandardLayout simple>
            <NEIBanner />
            <StyledSteps current={currentStep}>
                {steps.map(({ title }, index) => (
                    <StyledStep key={index} onClick={() => StepNavagation(index)} title={<Text>{title}</Text>} />
                ))}
            </StyledSteps>
            {currentStep > -1 && groupTypeSelected ? (
                <>{steps[currentStep].content}</>
            ) : (
                <ChooseGroupType
                    groupSetter={(type) => {
                        setGroupTypeSelected(type);
                        setCurrentStep(0);
                    }}
                />
            )}
        </StandardLayout>
    );
};

export default OnboardingWizard;
