const scale = {
    min: '4px',
    sm: '8px',
    md: '16px',
    mdl: '20px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
};

export default scale;
