import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import FeelalyticsLogo from '../../components/FeelalyticsLogo';
import Footer from '../../components/Footer';
import { NavContainer } from '../../layout/StandardLayout';
import Background from '../../assets/images/background.png';

type Congratulations = {
    changeStep: (direction: 'prev' | 'next') => void;
};

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;

const Congratulations: React.FC<Congratulations> = ({ changeStep }) => {
    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>
            <Card>
                <Text size="22">Congratulations!</Text>
                <Text size="22">
                    You&apos;re one step closer to understanding how emotions are impacting your organization.
                </Text>

                <PositionedButton
                    onClick={() => {
                        changeStep('next');
                    }}
                    style={{ color: colors.white, backgroundColor: colors.red }}
                >
                    Let&apos;s Go!
                </PositionedButton>
            </Card>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};

export default Congratulations;
