import styled from 'styled-components';
import { Button } from 'antd';
import colors from '../../constants/colors';
import scale from '../../constants/scale';

const CancelButton = styled(Button)`
    color: ${colors.darkBlue};
    background-color: ${colors.lightGray};
    margin-top: ${scale.md};
    border-color: ${colors.lightGray};
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    :hover {
        background-color: ${colors.lightGray};
        border-color: ${colors.lightGray};
        color: ${colors.red};
    }
`;

export default CancelButton;
