import { Input, InputProps } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import scale from '../constants/scale';
import Text from './Text';

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.sm};
`;

const StyledInput = styled(Input)`
    padding: ${scale.md};
    color: ${colors.darkBlue};
    font-size: 24px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    ::placeholder {
        color: ${colors.darkGray};
        font-size: 24px;
    }
`;

const LargeInput: React.FC<InputProps & { label?: string }> = ({ placeholder, label, required, onChange }) => {
    return (
        <InputContainer>
            <Text bold>
                {label}
                {required && ' *'}
            </Text>
            <StyledInput onChange={onChange} placeholder={placeholder} />
        </InputContainer>
    );
};

export default LargeInput;
