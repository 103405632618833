import React, { useEffect, useState } from 'react';
import StandardLayout from '../layout/StandardLayout';
import styled from 'styled-components';
import scale from '../constants/scale';
import { Button } from 'antd';
import { Form, Formik } from 'formik';
import FormikField from '../components/FormikField';
import Text from '../components/Text';
import FormikLabel from '../components/FormikLabel';
import useRouter from '../hooks/useRouter';
import { useQueryClient, useMutation } from 'react-query';
import groups from '../query-functions/groups';
import SHORTNotification from '../components/SHORTNotification';
import Group from '../types/group';
import colors from '../constants/colors';
import { FormFooter } from '../components/Styled/Form';
import * as Yup from 'yup';
import shadow from '../constants/shadow';
import SpanRequired from '../components/Styled/SpanRequired';
import FormikRadio from '../components/FormikRadio';
import { device } from '../constants/device';
import { CloseOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import NEIBanner from '../components/NEIBanner';

type RouterState = {
    companyId: string;
    query: string[];
    newGroup: boolean;
    group: Group;
};

const FormSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    description: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    welcome: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    open: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
});

const StyledForm = styled(Form)`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    padding-top: ${scale.xl};
    column-gap: ${scale.xl};

    @media ${device.laptopL} {
        grid-template-columns: 1fr 2fr;
    }

    @media ${device.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`;

const FormSection = styled.div`
    background-color: ${colors.white};
    padding: ${scale.lg};
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
    ${shadow};
`;

const RightSection = styled(FormSection)`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: max-content;
    column-gap: ${scale.xxl};

    @media ${device.laptopL} {
        column-gap: ${scale.md};
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
`;

const PhoneContainer = styled(ColumnContainer)`
    gap: ${scale.sm};
`;

const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${scale.md};
`;

const TipContainer = styled(ColumnContainer)`
    padding: 0 ${scale.xxl};
    justify-content: center;
    border-left: 1px solid ${colors.lightGray};

    @media ${device.laptopL} {
        padding: 0 ${scale.md};
    }
`;

const newGroupDefaults = {
    name: '',
    description: '',
    welcome: '',
    open: '',
    timezone: '',
};

const GroupView: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const { companyId, query, group: existingGroup } = router.location.state as RouterState;

    const [loading, setLoading] = useState<boolean>(false);
    const [groupToView] = useState<Group>(
        existingGroup && { ...existingGroup, open: existingGroup?.open === true ? 'yes' : 'no' },
    );

    const grCreateMutation = useMutation(groups.create, {
        onSuccess: () => {
            SHORTNotification('Group created');
            queryClient.invalidateQueries(query);
            router.history.goBack();
        },
    });

    const grUpdateMutation = useMutation(groups.update, {
        onSuccess: () => {
            SHORTNotification('Group updated');
            queryClient.invalidateQueries(query);
            router.history.goBack();
        },
    });

    useEffect(() => {
        const { isLoading } = grCreateMutation;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
    }, [grCreateMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = grUpdateMutation;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
    }, [grUpdateMutation.isLoading]);

    return (
        <StandardLayout page="groupview">
            <NEIBanner
                titleCard
                pageIcon={<UsergroupAddOutlined />}
                title={groupToView ? 'Edit Group' : 'Create a Group'}
            />
            <Formik
                initialValues={groupToView || newGroupDefaults}
                onSubmit={(values) => {
                    if (!groupToView) {
                        return grCreateMutation.mutate({
                            group: { ...values, members: [] },
                            companyIdentifier: companyId,
                        });
                    }
                    grUpdateMutation.mutate({
                        group: values,
                        companyIdentifier: companyId,
                    });
                }}
                validationSchema={FormSchema}
            >
                {({ values, handleSubmit, errors, touched }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <FormSection>
                            <FormikLabel
                                required
                                fieldLabel="Name your group"
                                field={<FormikField name="name" />}
                                error={errors.name}
                                touched={touched.name}
                            />
                            {groupToView ? (
                                <PhoneContainer>
                                    <Text bold>Group phone number</Text>
                                    <Text>
                                        {groupToView?.smsPhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                                    </Text>
                                </PhoneContainer>
                            ) : null}
                            <FormikLabel
                                required
                                fieldLabel="Description"
                                field={
                                    <FormikField
                                        name="description"
                                        component="textarea"
                                        maxLength={250}
                                        rows={10}
                                        style={{ resize: 'none' }}
                                    />
                                }
                                error={errors.description}
                                touched={touched.description}
                            />
                        </FormSection>
                        <RightSection>
                            <ColumnContainer>
                                <FormikLabel
                                    required
                                    fullWidth
                                    fieldLabel="Write a welcome message. Be specific so your members do not confuse the welcome message with a spam text."
                                    field={
                                        <FormikField
                                            name="welcome"
                                            component="textarea"
                                            style={{ minHeight: 200, resize: 'none' }}
                                        />
                                    }
                                    error={errors.welcome}
                                    touched={touched.welcome}
                                />
                                <ColumnContainer>
                                    <Text bold>
                                        Do you want to allow anyone with the phone number to subscribe to this group?{' '}
                                        <SpanRequired>*</SpanRequired>
                                    </Text>
                                    <RadioGroup role="group">
                                        <FormikRadio name="open" value="yes" />
                                        <Text>Yes</Text>
                                        <FormikRadio name="open" value="no" />
                                        <Text>No</Text>
                                        {touched.open && errors.open && (
                                            <Text size="12" color="red">
                                                {errors.open}
                                            </Text>
                                        )}
                                    </RadioGroup>
                                </ColumnContainer>
                                <ColumnContainer>
                                    <Text bold>
                                        Choose the group’s main time zone <SpanRequired>*</SpanRequired>
                                    </Text>
                                    <RadioGroup role="group">
                                        <FormikRadio name="timezone" value="Pacific" />
                                        <Text>Pacific</Text>
                                        <FormikRadio name="timezone" value="Mountain" />
                                        <Text>Mountain</Text>
                                        <FormikRadio name="timezone" value="Central" />
                                        <Text>Central</Text>
                                        <FormikRadio name="timezone" value="Eastern" />
                                        <Text>Eastern</Text>
                                        {touched.timezone && errors.timezone && (
                                            <Text size="12" color="red">
                                                {errors.timezone}
                                            </Text>
                                        )}
                                    </RadioGroup>
                                </ColumnContainer>
                            </ColumnContainer>
                            {!groupToView ? (
                                <TipContainer>
                                    <Text>Pro Tip!</Text>
                                    <Text>
                                        Once you create a group, it will be assigned its own unique phone number.
                                    </Text>
                                    <Text>
                                        You can access these group settings any time and see the phone number by
                                        clicking the edit button on the Groups & Members page.
                                    </Text>
                                </TipContainer>
                            ) : null}
                        </RightSection>

                        <FormFooter>
                            <Button
                                icon={<CloseOutlined />}
                                onClick={() => router.history.goBack()}
                                size="large"
                                type="text"
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={loading}
                                size="large"
                                style={{ color: colors.white, backgroundColor: colors.red }}
                                htmlType="submit"
                            >
                                {groupToView ? 'Update' : 'Submit'}
                            </Button>
                        </FormFooter>
                    </StyledForm>
                )}
            </Formik>
        </StandardLayout>
    );
};

export default GroupView;
