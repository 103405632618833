import React, { useState } from 'react';
import { Divider } from 'antd';
import styled from 'styled-components';
import scale from '../constants/scale';
import NEILogo from './NEILogo';
import colors from '../constants/colors';
import { CloseOutlined } from '@ant-design/icons';
import Text from './Text';

const BannerContainer = styled.div`
    width: 100%;
    background-color: ${colors.darkGray};
    padding: ${scale.xl};
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr;
    grid-gap: ${scale.xl};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    position: relative;

    @media print {
        display: none;
        -webkit-box-shadow: unset;
        -moz-box-shadow: unset;
        box-shadow: unset;
    }
`;

const BannerDirectionsContainer = styled(BannerContainer)`
    padding: 0;
    gap: 0;
    display: flex;
    flex-direction: row;
`;

const ContainerRight = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: ${scale.xl};
`;

const StyledDivider = styled(Divider)`
    background-color: ${colors.darkBlue};
    height: auto;
`;

/*
const Link = styled.a`
    text-decoration: underline;
    color: ${colors.darkBlue};
    font-weight: 700;
`;
*/

const CloseButton = styled.a`
    color: ${colors.darkBlue};
    position: absolute;
    bottom: ${scale.sm};
    right: ${scale.sm};
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.mediumBlue};
    color: ${colors.darkBlue};
    font-size: ${scale.xxl};
    width: 116px;
`;

const TitleContainer = styled.div`
    padding: ${scale.xl};
`;

const NEIBanner: React.FC<{ showNEI?: boolean; titleCard?: boolean; pageIcon?: React.ReactNode; title?: string }> = ({
    showNEI,
    titleCard,
    pageIcon,
    title,
}) => {
    const [paragraphShowing, setParagraphShowing] = useState<boolean>(true);

    return (
        <>
            {titleCard ? (
                <BannerDirectionsContainer>
                    {pageIcon != null ? <IconBox>{pageIcon}</IconBox> : null}
                    <TitleContainer>
                        <Text size="28">{title}</Text>
                    </TitleContainer>
                </BannerDirectionsContainer>
            ) : (
                <BannerContainer>
                    <NEILogo />
                    {paragraphShowing && showNEI ? (
                        <>
                            <Text size="14">
                                The NEI represents a single point in time and is a score that constantly changes.
                                Because human emotions are complex, often contradictory, and always changing, the NEI
                                score acts as a compass to help your organization follow the ebb and flow of the overall
                                positive or negative sentiments percolating in various areas of your organization.
                                {/*  <Link>Learn how NEI is calculated</Link> */}
                            </Text>
                            <CloseButton onClick={() => setParagraphShowing(false)}>
                                <CloseOutlined />
                            </CloseButton>
                        </>
                    ) : (
                        <ContainerRight>
                            <StyledDivider type="vertical" />
                            <Text size="28">Calculate your Net Emotional Index</Text>
                        </ContainerRight>
                    )}
                </BannerContainer>
            )}
        </>
    );
};
export default NEIBanner;
