import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'antd/dist/antd.less';
import styled, { createGlobalStyle } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Login from './pages/Login';
import Reports from './pages/Reports';
import Companies from './pages/Admin/Companies';
import Administrators from './pages/Admin/Administrators';
import AdministratorForm from './pages/Admin/AdministratorForm';
import colors from './constants/colors';
import Errors from './pages/Admin/Errors';
import ErrorView from './pages/Admin/ErrorView';
import Auths from './pages/Admin/Auths';
import CompanyForm from './pages/Admin/CompanyForm';
import EditProfile from './pages/EditProfile';
import Dashboard from './pages/Dashboard';
import OnboardingWizard from './pages/Onboarding/OnboardingWizard';
import GroupsAndMembers from './pages/GroupsAndMembers';
import storage from './utilities/localStorage';
import GroupView from './pages/GroupView';
import NEIDashboard from './pages/NEIDashboard';
import Responses from './pages/Project/Responses';
import NewProjectWizard from './pages/Project/NewProjectWizard';
import NotFound from './pages/NotFound';
import MobileResults from './pages/Results/MobileResults';
import ProjectStats from './pages/Results/ProjectStats';
import EditProjectSettings from './pages/Project/EditProject';
import Register from './pages/Registration/Register';
import Onboarding from './pages/Onboarding/Onboarding';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            //used to be 60 * 10000
            //The time in milliseconds after data is considered stale.
            //first number is the number of minutes
            staleTime: 10 * 60000,
        },
    },
});

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${colors.lightGray};
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.3;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;     
    }

/* - - -Gauge Cluster Styles- - - */

    .GaugeStyles {
        filter: drop-shadow(0 3px 3px #d7d7d7);
        width: 100%;
    }

    #chartdiv {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    #chartdiv2 {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    #BubbleChart {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

/* - - -Ant Design Overrides- - - */

    .ant-picker-clear {
        display: none;
    }

    .ant-menu-submenu-title, .ant-menu-item-only-child {
        color: ${colors.white};
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: ${colors.aqua};
    }

    .ant-table-tbody > tr:nth-of-type(even) {
        background-color: ${colors.lightGray};
    }

    .ant-btn-loading {
        color: ${colors.darkBlue};
    }
    
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: ${colors.darkBlue};
        color: ${colors.aqua};
    }

    .ant-picker-dropdown button, .ant-picker-dropdown button:hover {
        color: ${colors.white};
        background-color: ${colors.red};
        border: solid 1px ${colors.red};
    }

    .ant-picker-now-btn, .ant-picker-now-btn:hover{
        color: ${colors.red};
        
    }
    .ant-picker-header button, .ant-picker-header button:hover {
        color: ${colors.darkBlue};
        background-color: ${colors.white};
        border: solid 1px ${colors.white};
    }

/* - - -Ant Design Overrides- - - */
`;

const AppContainer = styled.div`
    min-height: 100vh;
    overflow-y: hidden;
    background-color: ${colors.lightGray};
`;

const App = () => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('auth', storage.get('auth'));
        }
    }, []);

    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <AppContainer>
                    <GlobalStyle />
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/onboarding" component={Onboarding} />
                        <Route exact path="/neidashboard" component={NEIDashboard} />
                        <Route exact path="/responses" component={Responses} />
                        <Route exact path="/groupsandmembers" component={GroupsAndMembers} />
                        <Route exact path="/groupview" component={GroupView} />
                        <Route exact path="/reports" component={Reports} />
                        <Route exact path="/profile" component={EditProfile} />
                        <Route exact path="/newproject" component={NewProjectWizard} />
                        <Route exact path="/editproject" component={EditProjectSettings} />
                        <Route exact path="/results" component={MobileResults} />
                        <Route exact path="/projectstats" component={ProjectStats} />
                        {/* Admin Routes */}
                        <Route exact path="/admin/auths" component={Auths} />
                        <Route exact path="/admin/companies" component={Companies} />
                        <Route exact path="/admin/companyform" component={CompanyForm} />
                        <Route exact path="/admin/administratorform" component={AdministratorForm} />
                        <Route exact path="/admin/administrators" component={Administrators} />
                        <Route exact path="/admin/error/view" component={ErrorView} />
                        <Route exact path="/admin/errors" component={Errors} />
                        {/* Onboarding Routes */}
                        {/* <Route exact path="/landing" component={Landing} /> */}
                        <Route exact path="/onboardingwizard" component={OnboardingWizard} />
                        <Route exact path="*" component={NotFound} />
                    </Switch>
                </AppContainer>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Router>
    );
};
export default App;
