import React, { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { Formik } from 'formik';

import storage from '../../utilities/localStorage';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import { useMutation } from 'react-query';
import Congratulations from './Congratulations';
import Landing from './Landing';
import GroupName from './GroupName';
import Members from './Members';
import groups from '../../query-functions/groups';

const initialValues = {
    name: '',
    timezone: '',
    members: new Array(6).fill({ phoneNumber: '' }),
};

const Onboarding: React.FC = () => {
    const router = useRouter();
    const authenticated = useIsAuthenticated();

    const [currentStep, setCurrentStep] = useState<number>(1);

    // // (Redirect to home page if user is already authenticated)
    // useEffect(() => {
    //     if (authenticated) return router.push('/');
    // }, [authenticated]);

    // Navigate through form screens
    const changeStep = (direction: 'prev' | 'next') => {
        switch (direction) {
            case 'prev':
                setCurrentStep((s) => s - 1);
                break;
            case 'next':
                setCurrentStep((s) => s + 1);
                break;
        }
    };

    const createGroup = useMutation(groups.create, {
        onSuccess: (e) => {
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('It was a success!');
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
        onError: (e) => {
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('It was an error...');
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
    });

    // Form submission
    const submitGroupForm = (values: any) => {
        //are we in a dev environment?
        //yes
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(values);
        }

        // (Get company ID from storage)
        const { companyId } = storage.get('company');

        // (Run custom validation on members)

        // createGroup.mutate({
        //     group: values,
        //     companyIdentifier: companyId,
        // });
    };

    return (
        <>
            {currentStep === 1 && <Congratulations changeStep={changeStep} />}
            {currentStep === 2 && <Landing changeStep={changeStep} />}
            <Formik initialValues={initialValues} validateOnChange onSubmit={(values) => submitGroupForm(values)}>
                {({ values, setFieldValue, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                    <>
                        {currentStep === 3 && (
                            <GroupName changeStep={changeStep} values={values} setFieldValue={setFieldValue} />
                        )}
                        {currentStep === 4 && (
                            <Members
                                changeStep={changeStep}
                                values={values}
                                setFieldValue={setFieldValue}
                                handleSubmit={handleSubmit}
                            />
                        )}
                    </>
                )}
            </Formik>
        </>
    );
};

export default Onboarding;
