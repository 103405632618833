import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import { QueryFunctionContext } from 'react-query';
import error from '../utilities/error';

type PatchTypes = {
    id: string;
    companyId: string;
    enabled: boolean;
};

type RemoveTypes = {
    id: string;
    companyId: string;
};

const administrators = {
    get: async ({ queryKey }: QueryFunctionContext) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .get(api.administrators, {
                params: {
                    companyId: queryKey[1],
                },
                headers,
            })
            .catch((err) => {
                error('administrators.ts', err);
                throw new Error(err);
            });
        return data;
    },
    changeStatus: async ({ id, companyId, enabled }: PatchTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .patch(
                api.administrators,
                { id, companyId, enabled },
                {
                    headers,
                },
            )
            .catch((err) => {
                error('administrators.ts', err);
                throw new Error(err);
            });
        return data;
    },
    remove: async ({ id, companyId }: RemoveTypes) => {
        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const { data } = await axios
            .delete(api.administrators, {
                params: {
                    id,
                    companyId,
                },
                headers,
            })
            .catch((err) => {
                error('administrators.ts', err);
                throw new Error(err);
            });
        return data;
    },
};

export default administrators;
