import { Button, Checkbox, Radio, TimePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../Text';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

const DoubleContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

const OptionCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.lg};
    background-color: ${colors.white};
    padding: ${scale.lg};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const StyledCheckbox = styled(Checkbox.Group)`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
    color: ${colors.darkBlue};
    span {
        color: ${colors.darkBlue};
        font-size: ${scale.md};
        font-weight: 500;
        width: ${scale.lg};
        height: ${scale.lg};
    }
    .ant-checkbox-inner::after {
        width: 8px;
        height: 12px;
    }
    input[type='checkbox'] {
        background-color: ${colors.darkBlue};
    }
`;

const StyledRadio = styled(Radio.Group)`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
`;

const StyledButton = styled(Button)`
    width: 194px;
    align-self: flex-end;
`;

const SampleSends3b: React.FC<{
    onChangeCheckbox: (days: string[]) => void;
    onChangeTime: (time: string[]) => void;
    onChangeZone: (time: string) => void;
    onChangeQuarter: (quarter: string) => void;
    onClick: () => void;
}> = ({ onChangeCheckbox, onChangeTime, onChangeZone, onChangeQuarter, onClick }) => {
    const [customTime, setCustomTime] = useState<string[]>([]);
    const [radioValue, setRadioValue] = useState<string | string[]>();
    const [isNotCustom, setIsNotCustom] = useState<boolean>(true);

    const WeekOptions = [
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' },
        { label: 'Sunday', value: 'Sunday' },
    ];

    useEffect(() => {
        setRadioValue([]);
        setRadioValue('custom');
        onChangeTime(customTime);
    }, [customTime]);

    const CustomTimePicker = (event: any) => {
        switch (event.target.value) {
            case 'custom':
                setRadioValue('custom');
                setIsNotCustom(false);
                onChangeTime(customTime);
                return;
            case '1':
                setRadioValue('1');
                setIsNotCustom(true);
                onChangeTime(['9 am', '5 pm']);
                return;
            case '2':
                setRadioValue('2');
                setIsNotCustom(true);
                onChangeTime(['8 am', '7 pm']);
                return;
            default:
                break;
        }
    };

    return (
        <Container>
            <DoubleContainer>
                <OptionCard>
                    <Text bold>During which month of the quarter do you want to send questions?</Text>
                    <StyledRadio onChange={(event) => onChangeQuarter(event.target.value)}>
                        <Radio value={'Q1'}>First Month (Jan, Apr, July, Oct)</Radio>
                        <Radio value={'Q2'}>Second Month (Feb, May, Aug, Nov)</Radio>
                        <Radio value={'Q3'}>Third Month (Mar, Jun, Sep, Dec)</Radio>
                    </StyledRadio>
                </OptionCard>
                <OptionCard>
                    <Text bold>Which days of the week do you want to send questions?</Text>
                    <StyledCheckbox
                        onChange={(checkValue) => onChangeCheckbox(checkValue as string[])}
                        options={WeekOptions}
                    />
                </OptionCard>
            </DoubleContainer>
            <OptionCard>
                <Text bold>Choose a time range to send questions.</Text>
                <StyledRadio defaultValue={radioValue} onChange={(event) => CustomTimePicker(event)}>
                    <Radio value="1">9:00am - 5:00pm</Radio>
                    <Radio value="2">8:00am - 7:00pm</Radio>
                    <Radio value="custom">Other:{'\u00A0'}</Radio>
                </StyledRadio>
                <TimePicker.RangePicker
                    disabled={isNotCustom}
                    onChange={(value, string) => setCustomTime(string)}
                    use12Hours
                    format="h a"
                />
                <Text bold>Choose the group’s main time zone</Text>
                <StyledRadio onChange={(event) => onChangeZone(event.target.value)}>
                    <Radio value={'Eastern UTC-5'}>Eastern UTC-5</Radio>
                    <Radio value={'Central UTC-6'}>Central UTC-6</Radio>
                    <Radio value={'Mountain UTC-7'}>Mountain UTC-7</Radio>
                    <Radio value={'Pacific UTC-8'}>Pacific UTC-8</Radio>
                </StyledRadio>
                <StyledButton
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                    onClick={() => onClick()}
                >
                    Next
                </StyledButton>
            </OptionCard>
        </Container>
    );
};

export default SampleSends3b;
