import storage from '../utilities/localStorage';
import User from '../types/user';

const useIsAdmin = (): boolean => {
    const user: User = storage.get('user');

    return user?.adminFlag;
};

export default useIsAdmin;
