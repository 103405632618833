import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import scale from '../../constants/scale';
import { ProjectType } from '../../pages/Project/NewProjectWizard';
import Text from '../Text';
import colors from '../../constants/colors';
import useRouter from '../../hooks/useRouter';
import {
    RedoOutlined,
    //CalendarOutlined,
    DashboardOutlined,
    NotificationOutlined,
    QuestionOutlined,
    DislikeOutlined,
    LikeOutlined,
} from '@ant-design/icons';

//TODO fix responcivness
const OptionCard = styled.div`
    background-color: ${colors.white};
    min-height: 380px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${scale.md};
    grid-template-rows: repeat(2, auto);
    margin-top: ${scale.xl};
`;

const SampleOptionCard = styled(OptionCard)`
    display: flex;
    gap: ${scale.xl};
    flex-direction: column;
`;

const CardTitleContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: ${scale.lg};
`;

const IconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    min-height: 100px;
    color: ${colors.darkBlue};
    font-size: ${scale.xxl};
    background: ${colors.mediumBlue};
`;

const TitleContainer = styled.div`
    padding-right: ${scale.xl};
`;

const BodyContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: ${scale.lg};
`;

const StyledButton = styled(Button)`
    display: block;
    width: 194px;
    align-self: flex-end;
    font-weight: 700;
`;

const ButtonGroup = styled.div`
    grid-column: 3/3;
    grid-row: 3/3;
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;

const StyledCancelButton = styled(Button)`
    padding: 0 0;
    margin-bottom: ${scale.xxl};
    color: ${colors.darkBlue};
    background-color: ${colors.lightGray};
    border-color: ${colors.lightGray};
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    :hover {
        background-color: ${colors.lightGray};
        border-color: ${colors.lightGray};
        color: ${colors.red};
    }
`;

/*
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <CalendarOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>Quarterly Emotional Temperature Check</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>
                        Track the emotional temperature of your teams from one quarter to the next. Sample your groups
                        for a 30-day period each quarter.
                    </Text>
                    <Text>This project type uses our standard bank of questions to ask how each person feels.</Text>
                    <StyledButton onClick={() => onClick('Quarterly Sample')} size="large" type="primary" style={{ color: colors.white }} danger>
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>

*/

const ChooseProjectType: React.FC<{ onClick: (selected: ProjectType) => void }> = ({ onClick }) => {
    const router = useRouter();
    return (
        <Container>
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <RedoOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>Ongoing Emotional Temperature Check</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>
                        Check the emotional temperature of your teams on an ongoing basis. Choose the start and end
                        dates.
                    </Text>
                    <Text>This project type uses our standard bank of questions to ask how each person feels.</Text>
                    <StyledButton
                        onClick={() => onClick('Ongoing Sample')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <DashboardOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>One-Time Emotional Temperature Check</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>Check the emotional temperature of your teams.</Text>
                    <Text>This project type uses our standard bank of questions to ask how each person feels.</Text>
                    <StyledButton
                        onClick={() => onClick('One-Time Sample')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <QuestionOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>Ask a One-Time Custom Question</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>Customize a question to get a sense of how your teams feel about a certain topic.</Text>
                    <StyledButton
                        onClick={() => onClick('One-Time Custom Question')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <LikeOutlined style={{ transform: 'scaleX(-1)' }} />
                        <DislikeOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>Ask a Yes/No Question</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>Customize a follow-up question for a yes or no response.</Text>
                    <StyledButton
                        onClick={() => onClick('One-Time Yes/No Question')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>
            <SampleOptionCard>
                <CardTitleContainer>
                    <IconBox>
                        <NotificationOutlined />
                    </IconBox>
                    <TitleContainer>
                        <Text bold>Send an Announcement</Text>
                    </TitleContainer>
                </CardTitleContainer>
                <BodyContainer>
                    <Text>Customize an announcement without receiving a response.</Text>
                    <StyledButton
                        onClick={() => onClick('One-Time Announcement')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose
                    </StyledButton>
                </BodyContainer>
            </SampleOptionCard>
            <ButtonGroup>
                <StyledCancelButton
                    onClick={() => router.push('/')}
                    size="large"
                    style={{ color: colors.black }}
                    htmlType="submit"
                >
                    X Cancel
                </StyledCancelButton>
            </ButtonGroup>
        </Container>
    );
};

export default ChooseProjectType;
