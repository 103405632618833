import React, { useEffect, useState } from 'react';
import { CloseCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Tooltip, Popconfirm } from 'antd';
import styled from 'styled-components';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import administrators from '../../query-functions/administrators';
import scale from '../../constants/scale';
import CustomCard from '../../components/CustomCard';
import Administrator from '../../types/administrator';
import Company from '../../types/company';
import BackButton from '../../components/BackButton';
import SHORTNotification from '../../components/SHORTNotification';
import colors from '../../constants/colors';

type RouterState = {
    empPlaceholder: Administrator[];
    companyId: Company['id'];
};

const PageContainer = styled.div`
    padding: ${scale.xl} 0;
`;

const FilterContainer = styled.div`
    padding: ${scale.sm};
`;

const Administrators: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();

    const [loading, setLoading] = useState<boolean>(false);
    const [statusLoading, setStatusLoading] = useState<boolean>(false);
    const [removeLoading, setRemoveLoading] = useState<boolean>(false);
    const [empData, setEmpData] = useState<Administrator[]>([]);
    const [selected, setSelected] = useState<Administrator>({} as Administrator);
    const [filteredData, setFilteredData] = useState<Administrator[]>([]);
    const [value, setValue] = useState<string>('');

    const { empPlaceholder, companyId } = router.location.state as RouterState;

    const query = useQuery(['administrators', companyId], administrators.get, {
        placeholderData: empPlaceholder,
    });

    const statusMutation = useMutation(administrators.changeStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(['administrators', companyId]);
            SHORTNotification('Administrator updated');
        },
    });

    const removeMutation = useMutation(administrators.remove, {
        onSuccess: () => {
            queryClient.invalidateQueries(['administrators', companyId]);
            SHORTNotification('Administrator removed');
        },
    });

    useEffect(() => {
        const { data, isLoading } = query;

        if (isLoading) setLoading(true);
        if (!isLoading) setLoading(false);
        if (data) {
            setEmpData(data);
        }
    }, [query.data, query.isLoading]);

    useEffect(() => {
        const { isLoading } = statusMutation;

        if (isLoading) setStatusLoading(true);
        if (!isLoading) setStatusLoading(false);
    }, [statusMutation.isLoading]);

    useEffect(() => {
        const { isLoading } = removeMutation;

        if (isLoading) setRemoveLoading(true);
        if (!isLoading) setRemoveLoading(false);
    }, [removeMutation.isLoading]);

    const ActionBar: React.FC = () => {
        const [disabled, setDisabled] = useState<boolean>(true);

        useEffect(() => {
            if (selected.id) setDisabled(false);
        }, [selected]);

        const changeStatus = async () => {
            statusMutation.mutate({
                id: selected.id,
                companyId,
                enabled: !selected.enabled,
            });
        };

        const removeEmp = async () => {
            removeMutation.mutate({
                id: selected.id,
                companyId,
            });
        };

        return (
            <Space direction="horizontal">
                <Button
                    disabled={disabled}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                    onClick={() => router.push(`/admin/administratorform`, { administrator: selected })}
                    icon={<EditOutlined />}
                >
                    Edit
                </Button>
                <Button
                    loading={statusLoading}
                    disabled={disabled}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                    onClick={changeStatus}
                    icon={<PoweroffOutlined />}
                >
                    {selected.enabled ? 'Disable' : 'Enable'}
                </Button>
                <Popconfirm
                    title={`Are you sure you want to remove ${selected.firstName} ${selected.lastName}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={removeEmp}
                >
                    <Button
                        loading={removeLoading}
                        disabled={disabled}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                        icon={<DeleteOutlined />}
                    >
                        Remove
                    </Button>
                </Popconfirm>
            </Space>
        );
    };

    const NewEmployee = () => (
        <Tooltip title="New Administrator">
            <Button
                size="large"
                onClick={() => router.history.push('/admin/administratorform', { companyId })}
                icon={<PlusOutlined />}
                style={{ color: colors.white, backgroundColor: colors.red }}
            />
        </Tooltip>
    );

    const Filter = (column: string) => (
        <FilterContainer>
            <Space>
                <Input
                    value={value}
                    onChange={(e) => {
                        const currValue = e.target.value;
                        setValue(currValue);
                        const newData = empData.filter((entry: any) =>
                            entry[column].toString().toLowerCase().includes(value),
                        );
                        setFilteredData(newData);
                    }}
                />
                <Tooltip title="Clear Search">
                    <Button
                        onClick={() => {
                            setValue('');
                            setFilteredData([]);
                        }}
                        icon={<CloseCircleOutlined />}
                    />
                </Tooltip>
            </Space>
        </FilterContainer>
    );

    return (
        <StandardLayout page="administrators">
            <PageContainer>
                <BackButton />
                <CustomCard title={<ActionBar />} extra={<NewEmployee />}>
                    <Table
                        loading={loading}
                        columns={[
                            {
                                title: 'Name',
                                filterDropdown: Filter('name'),
                                render: (value, { firstName, lastName }) => `${firstName} ${lastName}`,
                            },
                            {
                                title: 'Phone Number',
                                dataIndex: 'phoneNumber',
                                filterDropdown: Filter('phone'),
                                render: (value) => value?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
                            },
                            {
                                title: 'Email',
                                dataIndex: 'emailAddress',
                                filterDropdown: Filter('email'),
                            },
                            {
                                title: 'Department',
                                dataIndex: 'department',
                                filterDropdown: Filter('department'),
                            },
                            {
                                title: 'Status',
                                dataIndex: 'enabled',
                                render: (value: boolean) => (
                                    <Tag color={value === true ? 'success' : 'error'}>
                                        {value === true ? 'Enabled' : 'Disabled'}
                                    </Tag>
                                ),
                            },
                        ]}
                        pagination={false}
                        scroll={{ x: 1100 }}
                        dataSource={filteredData.length > 0 ? filteredData : empData}
                        rowKey={(record) => record.id}
                        onRow={(record) => ({
                            onClick: () => setSelected(record),
                        })}
                        rowSelection={{
                            selectedRowKeys: [selected.id],
                            type: 'radio',
                        }}
                    />
                </CustomCard>
            </PageContainer>
        </StandardLayout>
    );
};

export default Administrators;
