import React, { useEffect } from 'react';
import ScreenHeader from '../components/ScreenHeader';
import StandardLayout from '../layout/StandardLayout';
import useRouter from '../hooks/useRouter';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const Reports: React.FC = () => {
    const authenticated = useIsAuthenticated();
    const router = useRouter();

    useEffect(() => {
        if (authenticated) return;
        router.push('/login');
    });

    return (
        <StandardLayout page="reports">
            <ScreenHeader title="Reports" />
        </StandardLayout>
    );
};

export default Reports;
