import Member from '../types/member';

const useActiveMemberCounter = (membersData: Member[]): number[] => {
    const totalMemberCount = membersData.length;
    const activeMemberCount = membersData.filter((member) => !member.doNotSMS).length;
    const inactiveMemberCount = membersData.filter((member) => member.doNotSMS).length;

    return [totalMemberCount, activeMemberCount, inactiveMemberCount];
};
export default useActiveMemberCounter;
