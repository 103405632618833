import React, { useEffect, useState } from 'react';
import { Button, Input, Popconfirm, Space, Table, Tooltip } from 'antd';
import {
    CloseCircleOutlined,
    DeleteOutlined,
    EyeOutlined,
    PlusOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import useIsAdmin from '../../hooks/useIsAdmin';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import companies from '../../query-functions/companies';
import CustomCard from '../../components/CustomCard';
import Company from '../../types/company';
import SHORTNotification from '../../components/SHORTNotification';
import storage from '../../utilities/localStorage';
import colors from '../../constants/colors';

const FilterContainer = styled.div`
    padding: 8px;
`;

const Companies: React.FC = () => {
    const authenticated = useIsAuthenticated();
    const admin = useIsAdmin();
    const router = useRouter();
    const queryClient = useQueryClient();

    const [companyData, setCompanyData] = useState<Company[]>([]);
    const [selected, setSelected] = useState<Company>({} as Company);
    const [filteredData, setFilteredData] = useState<Company[]>([]);
    const [value, setValue] = useState<string>('');
    const [queryLoading, setQueryLoading] = useState<boolean>(false);
    const [mutationLoading, setMutationLoading] = useState<boolean>(false);

    const query = useQuery('companies', companies.get);
    const coRemoveMutation = useMutation(companies.remove, {
        onSuccess: () => {
            queryClient.invalidateQueries('companies');
            SHORTNotification('Company deleted');
        },
    });

    useEffect(() => {
        if (authenticated && admin) return;
        router.push('/login');
    }, [authenticated, router, admin]);

    useEffect(() => {
        const { data, isLoading } = query;

        if (isLoading) setQueryLoading(true);
        if (!isLoading) setQueryLoading(false);
        if (data) {
            setCompanyData(data);
        }
    }, [query.data, query.isLoading]);

    useEffect(() => {
        const { isLoading } = coRemoveMutation;

        if (isLoading) setMutationLoading(true);
        if (!isLoading) setMutationLoading(false);
    }, [coRemoveMutation.isLoading]);

    const removeComp = () => {
        const { id } = selected;

        coRemoveMutation.mutate({
            id,
        });
    };

    // Function to allow admin to access any company
    const accessCompany = (companyName: any, companyId: any) => {
        storage.set('company', { companyName, companyId });
        router.push('/');
    };

    const ActionBar: React.FC = () => {
        const [disabled, setDisabled] = useState<boolean>(true);

        useEffect(() => {
            if (selected.id) setDisabled(false);
        }, [selected.id]);

        return (
            <Space direction="horizontal">
                <Button
                    onClick={() => router.push(`/admin/companyform`, selected)}
                    disabled={disabled}
                    size="large"
                    style={{
                        color: disabled ? colors.mediumGray : colors.white,
                        backgroundColor: disabled ? colors.lightGray : colors.aqua,
                    }}
                    icon={<EyeOutlined />}
                >
                    View
                </Button>
                <Button
                    disabled={disabled}
                    size="large"
                    style={{
                        color: disabled ? colors.mediumGray : colors.white,
                        backgroundColor: disabled ? colors.lightGray : colors.aqua,
                    }}
                    icon={<UserOutlined />}
                    onClick={() =>
                        router.push(`/admin/administrators`, {
                            empPlaceholder: selected.employees,
                            companyId: selected.id,
                        })
                    }
                >
                    Administrators
                </Button>
                <Button
                    disabled={disabled}
                    size="large"
                    style={{
                        color: disabled ? colors.mediumGray : colors.white,
                        backgroundColor: disabled ? colors.lightGray : colors.aqua,
                    }}
                    icon={<TeamOutlined />}
                    onClick={() => router.push('/usersandgroups', { companyId: selected.id })}
                >
                    Groups
                </Button>
                <Popconfirm
                    title={`Are you sure you want to remove ${selected.name}?`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={removeComp}
                >
                    <Button
                        loading={mutationLoading}
                        disabled={disabled}
                        size="large"
                        style={{
                            color: disabled ? colors.mediumGray : colors.white,
                            backgroundColor: disabled ? colors.lightGray : colors.aqua,
                        }}
                        icon={<DeleteOutlined />}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            </Space>
        );
    };

    const NewCompany = () => (
        <Tooltip title="New Company">
            <Button
                size="large"
                onClick={() => router.push('/admin/companyform')}
                style={{ color: colors.white, backgroundColor: colors.red }}
                icon={<PlusOutlined />}
            />
        </Tooltip>
    );

    const Filter = (column: string) => (
        <FilterContainer>
            <Space>
                <Input
                    value={value}
                    onChange={(e) => {
                        const currValue = e.target.value;
                        setValue(currValue);
                        const newData = companyData.filter((entry) =>
                            entry[column].toString().toLowerCase().includes(value),
                        );
                        setFilteredData(newData);
                    }}
                />
                <Tooltip title="Clear Search">
                    <Button
                        onClick={() => {
                            setValue('');
                            setFilteredData([]);
                        }}
                        icon={<CloseCircleOutlined />}
                    />
                </Tooltip>
            </Space>
        </FilterContainer>
    );

    return (
        <StandardLayout page="companies">
            <CustomCard title={<ActionBar />} extra={<NewCompany />}>
                <Table
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            width: 240,
                            filterDropdown: Filter('name'),
                        },
                        {
                            title: 'Contact',
                            width: 200,
                            render: (value, { name, contactFirstName, contactLastName }) =>
                                name === 'Feelalytics' ? '' : `${contactFirstName} ${contactLastName}`,
                        },
                        {
                            title: 'Phone Number',
                            dataIndex: 'contactPhoneNumber',
                            width: 140,
                            render: (value) => value?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
                        },
                        {
                            title: 'Email',
                            dataIndex: 'contactEmailAddress',
                        },
                        {
                            title: '',
                            align: 'right',
                            render: (value, { name, id }) => (
                                <Button
                                    style={{ color: colors.white, backgroundColor: colors.red }}
                                    onClick={() => {
                                        accessCompany(name, id);
                                    }}
                                >
                                    Access account
                                </Button>
                            ),
                        },
                    ]}
                    loading={queryLoading}
                    pagination={false}
                    dataSource={filteredData.length > 0 ? filteredData : companyData}
                    rowKey={(record) => record.id}
                    onRow={(record) => ({
                        onClick: () => setSelected(record),
                    })}
                    rowSelection={{
                        selectedRowKeys: [selected.id],
                        type: 'radio',
                        columnWidth: 40,
                    }}
                />
            </CustomCard>
        </StandardLayout>
    );
};

export default Companies;
