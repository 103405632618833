import React, { useState, useEffect } from 'react';
//import { Button } from 'antd';
//import { PhoneOutlined, MessageOutlined } from '@ant-design/icons';
//import Axios from 'axios';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';

import FeelalyticsLogo from '../../components/FeelalyticsLogo';
//import usePhoneFormatter from '../../hooks/usePhoneFormatter';
//import storage from '../../utilities/localStorage';
//import api from '../../constants/api';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import Background from '../../assets/images/background.png';
//import error from '../../utilities/error';
import { NavContainer } from '../../layout/StandardLayout';
//import scale from '../../constants/scale';
//import Text from '../../components/Text';
import colors from '../../constants/colors';
import Footer from '../../components/Footer';
//import queryString from 'query-string';
//import { useLocation } from 'react-router-dom';
import EmailCard from '../../components/Registration/EmailCard';
import CompanyCard from '../../components/Registration/CompanyCard';
import PhoneCard from '../../components/Registration/PhoneCard';
import { useMutation } from 'react-query';
import companies from '../../query-functions/companies';
import SHORTNotification from '../../components/SHORTNotification';

const formSchema = Yup.object().shape({
    contactEmailAddress: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required')
        .email('Invalid email'),
});

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

/*
const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;
*/

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const initialValues = {
    name: '',
    welcome: 'This is a welcome',
    contactFirstName: '',
    contactLastName: '',
    contactEmailAddress: '',
    contactPhoneNumber: '5125551212',
};

const Register: React.FC = () => {
    const router = useRouter();
    const authenticated = useIsAuthenticated();

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    const createCompany = useMutation(companies.create, {
        mutationKey: 'register',
        onSuccess: (e) => {
            SHORTNotification('Account created');
            router.push('/login');
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
        onError: (e) => {
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        },
    });

    // (Redirect to home page if user is already authenticated)
    useEffect(() => {
        if (authenticated) return router.push('/');
    }, [authenticated]);

    // Navigate through form screens
    const changeStep = (direction: 'prev' | 'next') => {
        switch (direction) {
            case 'prev':
                setCurrentStep((s) => s - 1);
                break;
            case 'next':
                setCurrentStep((s) => s + 1);
                break;
        }
    };

    // Form submission
    const submitForm = (values: any) => {
        //are we in a dev environment?
        //yes
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Submitting values:', values);
        }
        createCompany.mutate(values);
    };

    // (When form is submitting, set state to 'loading')
    useEffect(() => {
        setLoading(!!createCompany.isLoading);
    }, [createCompany.isLoading]);

    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>

            <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                validateOnChange
                onSubmit={(values) => submitForm(values)}
            >
                {({ values, setFieldValue, errors, handleSubmit }) => (
                    <>
                        {currentStep === 1 && (
                            <EmailCard
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                changeStep={changeStep}
                            />
                        )}
                        {currentStep === 2 && (
                            <CompanyCard values={values} setFieldValue={setFieldValue} changeStep={changeStep} />
                        )}
                        {currentStep === 3 && (
                            <PhoneCard
                                values={values}
                                setFieldValue={setFieldValue}
                                handleSubmit={handleSubmit}
                                loading={loading}
                            />
                        )}
                    </>
                )}
            </Formik>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};

export default Register;
