/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Button, Card, Input, Space, Table, Tooltip, Tag, Popconfirm } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import ScreenHeader from '../../components/ScreenHeader';
import StandardLayout from '../../layout/StandardLayout';
import useRouter from '../../hooks/useRouter';
import useIsAuthenticated from '../../hooks/useIsAuthenticated';
import useIsAdmin from '../../hooks/useIsAdmin';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import errors from '../../query-functions/errors';
import colors from '../../constants/colors';

type ErrorTypes = {
    [key: string]: any;
    dateAdded: string;
    id: string;
    message: string;
    source: string;
};

const buttonColor = '#1B90FF';

const StyledButton = styled(Button)`
    background-color: ${({ disabled }) => (disabled ? '' : buttonColor)};
    border-color: ${({ disabled }) => (disabled ? '' : buttonColor)};
`;

const Errors: React.FC = () => {
    const authenticated = useIsAuthenticated();
    const admin = useIsAdmin();
    const router = useRouter();

    const [loading, setLoading] = useState<boolean>(false);
    const [backButton, setBackButton] = useState<boolean>(false);
    const [errorData, setErrorData] = useState<ErrorTypes[]>([]);
    const [selected, setSelected] = useState<ErrorTypes>({} as ErrorTypes);
    const [filteredData, setFilteredData] = useState<ErrorTypes[]>([]);
    const [value, setValue] = useState<string>('');

    const queryClient = useQueryClient();

    const { data } = useQuery('errors', errors.get);

    const mutation = useMutation(errors.remove, {
        onSuccess: () => {
            queryClient.invalidateQueries('errors');
        },
    });

    // Check for auth and admin
    useEffect(() => {
        // Auth and admin true, exit
        if (authenticated && admin) return;
        // Auth or admin false, redirect
        router.push('/login');
    }, [authenticated, router, admin]);

    useEffect(() => {
        setErrorData([]);
        if (data && data.length > 0) {
            setLoading(true);
            data?.forEach((obj: any) => {
                setErrorData((prevState) =>
                    prevState.concat({
                        dateAdded: moment(obj.dateAdded).format('L LT'),
                        id: obj.id,
                        message: obj.message,
                        source: obj.source,
                    }),
                );
            });
            setLoading(false);
        }
    }, [data]);

    const ActionBar: React.FC = () => {
        const [disabled, setDisabled] = useState<boolean>(true);
        const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

        useEffect(() => {
            if (selected.id) setDisabled(false);
        }, [selected]);

        const deleteError = () => {
            mutation.mutate({
                id: selected.id,
            });
        };

        return (
            <Space direction="horizontal">
                <StyledButton
                    disabled={disabled}
                    size="large"
                    style={{
                        color: disabled ? colors.mediumGray : colors.white,
                        backgroundColor: disabled ? colors.lightGray : colors.aqua,
                    }}
                    onClick={() => router.push(`/admin/error/view?errorId=${selected.id}`)}
                >
                    View
                </StyledButton>
                <Popconfirm
                    title={`Are you sure you want to remove ${selected.name}`}
                    okText="Yes"
                    cancelText="No"
                    onConfirm={deleteError}
                >
                    <Button
                        loading={deleteLoading}
                        disabled={disabled}
                        size="large"
                        style={{
                            color: disabled ? colors.mediumGray : colors.white,
                            backgroundColor: disabled ? colors.lightGray : colors.red,
                        }}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            </Space>
        );
    };

    const Filter = (column: string) => (
        <div style={{ padding: 8 }}>
            <Space>
                <Input
                    value={value}
                    onChange={(e) => {
                        const currValue = e.target.value;
                        setValue(currValue);
                        const newData = errorData.filter((entry) =>
                            entry[column].toString().toLowerCase().includes(value),
                        );
                        setFilteredData(newData);
                    }}
                />
                <Tooltip title="Clear Search">
                    <Button
                        onClick={() => {
                            setValue('');
                            setFilteredData([]);
                        }}
                        icon={<CloseCircleOutlined />}
                    />
                </Tooltip>
            </Space>
        </div>
    );

    const columns = [
        {
            title: 'Date',
            dataIndex: 'dateAdded',
            filterDropdown: Filter('dateAdded'),
            width: 200,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            width: 300,
            filterDropdown: Filter('source'),
        },
        {
            title: 'Message',
            dataIndex: 'message',
            ellipsis: true,
        },
        {
            title: 'Platform',
            dataIndex: 'source',
            width: 120,
            render: (tag: string) => {
                const result = tag?.search('.tsx');
                return <Tag color={result > 0 ? 'cyan' : 'green'}>{result > 0 ? 'FrontEnd' : 'BackEnd'}</Tag>;
            },
        },
    ];

    return (
        <StandardLayout page="errors">
            <ScreenHeader showBack={backButton} title="Errors" />
            <Card title={<ActionBar />}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={filteredData.length > 0 ? filteredData : errorData}
                    rowKey={(record) => record.id}
                    onRow={(record) => ({
                        onClick: () => setSelected(record),
                    })}
                    rowSelection={{
                        selectedRowKeys: [selected.id],
                        type: 'radio',
                        columnWidth: 40,
                    }}
                />
            </Card>
        </StandardLayout>
    );
};

export default Errors;
