import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import scale from '../constants/scale';
import SpanRequired from './Styled/SpanRequired';
import Text from './Text';

type Label = {
    field: React.ReactNode;
    fieldLabel: string;
    touched: boolean | undefined;
    error: string | undefined;
    required?: boolean;
    fullWidth?: boolean;
};

const Label = styled.label<{ fullWidth?: boolean }>`
    font-size: 18px;
    font-weight: 700;
    color: ${colors.darkBlue};
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: ${scale.md};
    ${({ fullWidth }) => fullWidth && 'grid-column-start: span 2'}
`;

const ErrorText = styled.div`
    position: absolute;
    bottom: 0;
`;

const FormikLabel: React.FC<Label> = ({ field, fieldLabel, touched, error, required, fullWidth }) => (
    <Label fullWidth={fullWidth}>
        <Text bold>
            {fieldLabel}
            {required && <SpanRequired> *</SpanRequired>}
        </Text>
        {field}
        <ErrorText>
            <Text size="12" color="red">
                {error && touched ? error : null}
            </Text>
        </ErrorText>
    </Label>
);
export default FormikLabel;
