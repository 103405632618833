import React, { useState, useEffect } from 'react';
import { Input, Button, message } from 'antd';
import { PhoneOutlined, MessageOutlined } from '@ant-design/icons';
import Axios from 'axios';
import styled from 'styled-components';
import FeelalyticsLogo from '../components/FeelalyticsLogo';
import usePhoneFormatter from '../hooks/usePhoneFormatter';
import storage from '../utilities/localStorage';
import api from '../constants/api';
import useRouter from '../hooks/useRouter';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import Background from '../assets/images/background.png';
import error from '../utilities/error';
import { NavContainer } from '../layout/StandardLayout';
import scale from '../constants/scale';
import Text from '../components/Text';
import colors from '../constants/colors';
import Footer from '../components/Footer';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import shadow from '../constants/shadow';

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const StyledButton = styled(Button)`
    align-self: flex-end;
    background-color: 'transparent';
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

const LoginInputCard = styled.div`
    background-color: ${colors.white}90;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const PositionedButton = styled(Button)`
    justify-self: end;
`;

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const Login: React.FC = () => {
    const router = useRouter();
    const authenticated = useIsAuthenticated();

    const { num, setNumber, formatted } = usePhoneFormatter();
    const [authSecret, setAuthSecret] = useState<string>('');
    const [authDateExpires, setAuthDateExpires] = useState<string>('');
    const [authPassPhrase, setAuthPassPhrase] = useState<string>('');
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string>('');

    const location = useLocation();
    const qs = queryString.parse(location.search);
    const overridePhone = qs['override'] ? qs['override'] : null;

    //    useEffect(() => {
    //        if (authenticated) {
    //            return router.push('/');
    //        }
    //    }, [authenticated, router]);

    const submitPhone = async () => {
        setLoading(true);
        //try this for up to 10 times
        for (let nTry = 0; nTry < 10; nTry++) {
            //are we in a dev environment?
            //yes
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log('try', nTry.toString());
            }
            try {
                //are we in a dev environment?
                //yes
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('Requesting new auth');
                }
                let tempSecret = '';
                let tempPassPhrase = '';
                await Axios.get(api.auth, { timeout: 6000 }).then(({ data: { secret, passPhrase, dateExpires } }) => {
                    tempSecret = secret;
                    tempPassPhrase = passPhrase;

                    //set it in the storage
                    storage.set('auth', {
                        secret,
                        passPhrase,
                        dateExpires,
                    });
                    //are we in a dev environment?
                    //yes
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log('We got an auth from the server');
                    }
                    setAuthSecret(secret);
                    setAuthPassPhrase(passPhrase);
                    setAuthDateExpires(dateExpires);
                });

                await Axios.post(
                    api.SMSAuth,
                    {
                        phoneNumber: num,
                        overridePhone,
                    },
                    {
                        headers: {
                            SECRET: tempSecret,
                            PASSPHRASE: tempPassPhrase,
                        },
                        timeout: 10000,
                    },
                ).then(({ data }) => {
                    //are we in a dev environment?
                    //yes
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log('data', data);
                    }
                    //did it work?
                    //yes
                    if (data.success === true) {
                        //are we in a dev environment?
                        //yes
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.log('found the account, breaking');
                        }
                        setCodeSent(true);
                        nTry = 100;
                    }
                    //did it work?
                    //no - last try failed
                    else if (data.success === false && nTry >= 9) {
                        //are we in a dev environment?
                        //yes
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.log('We need to fail it after ' + nTry + ' tries');
                        }
                        message.error('We were unable to verify your phone number. Try again.');
                        setNumber('');
                    }
                    //did it work?
                    //no - keep trying
                    else {
                        //are we in a dev environment?
                        //yes
                        if (process.env.NODE_ENV === 'development') {
                            // eslint-disable-next-line no-console
                            console.log('Try ' + nTry.toString(), new Date());
                        }
                    }
                });
            } catch (err) {
                //have we reached the end of our tries?
                //yes
                if (nTry >= 9) {
                    message.error('We were unable to find your phone number. Try again.');
                }
            }
        }

        setLoading(false);
    };

    const submitCode = async (fullCode: string) => {
        if (fullCode.length !== 3) {
            return message.warn('Code must be 3 digits');
        }

        setLoading(true);
        try {
            await Axios.post(
                api.SMSAuth,
                {
                    phoneNumber: num,
                    code: fullCode,
                    overridePhone,
                },
                {
                    headers: {
                        SECRET: authSecret,
                        PASSPHRASE: authPassPhrase,
                    },
                    timeout: 4000,
                },
            ).then(
                ({
                    data: {
                        adminFlag,
                        companyId,
                        companyName,
                        employeeId,
                        firstName,
                        lastName,
                        phoneNumber,
                        pulse,
                        questionCount,
                        responseCount,
                        success,
                    },
                }) => {
                    if (success === true) {
                        storage.set('user', {
                            adminFlag,
                            companyId,
                            companyName,
                            employeeId,
                            firstName,
                            lastName,
                            phoneNumber,
                            pulse,
                            questionCount,
                            responseCount,
                        });
                        storage.set('company', { companyId, companyName });
                        router.push('/');
                    }
                    if (success === false) {
                        message.error('Unable to match the code to the phone number. Try again.');
                        setCode('');
                    }
                },
            );
        } catch (err) {
            error(err, 'Login.tsx');
        } finally {
            setLoading(false);
        }
    };

    const submit = async (event: React.MouseEvent | React.KeyboardEvent) => {
        event.preventDefault();
        //have we sent the code yet?
        //no
        if (!codeSent) {
            //submit their phone
            await submitPhone();
        }
        setConfirmationCode(code);
    };

    const setConfirmationCode = (fullCode: string) => {
        setCode(fullCode);

        //do we have 3 digits?
        //yes
        if (fullCode.length === 3) {
            submitCode(fullCode);
        }
    };

    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>
            <LoginInputCard>
                <Text size="22" center>
                    {codeSent ? 'Enter the code we texted' : 'Enter your phone number'}
                </Text>
                {codeSent ? (
                    <Input
                        disabled={loading}
                        inputMode="numeric"
                        style={{ width: 245 }}
                        placeholder="123"
                        prefix={<MessageOutlined />}
                        onChange={(event) => setConfirmationCode(event.target.value)}
                        value={code}
                        maxLength={3}
                        onPressEnter={(event) => submit(event)}
                        autoFocus
                    />
                ) : (
                    <Input
                        name="Phone"
                        disabled={loading}
                        inputMode="tel"
                        style={{ width: 245 }}
                        placeholder="(512) 559-8599"
                        prefix={<PhoneOutlined />}
                        onChange={(event) => setNumber(event.target.value)}
                        value={formatted}
                        maxLength={14}
                        onPressEnter={(event) => submit(event)}
                        autoFocus
                    />
                )}
                <PositionedButton
                    onClick={submit}
                    style={{ color: colors.white, backgroundColor: colors.red, borderWidth: 0 }}
                    loading={loading}
                >
                    {codeSent ? 'Login' : 'Send login code'}
                </PositionedButton>
            </LoginInputCard>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};

export default Login;
