import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import scale from '../../constants/scale';
import { GroupType } from '../../pages/Onboarding/OnboardingWizard';
import Text from '../Text';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${scale.xl};
    grid-template-rows: auto;
`;

export const OptionCard = styled.div`
    background-color: ${colors.white};
    padding: ${scale.xl};
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
`;

const OptionCardDisabled = styled(OptionCard)`
    position: relative;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.lg};
`;

export const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${scale.lg};
`;

const CenteredButton = styled(Button)`
    align-self: center;
`;

const DisabledCardOverlay = styled.div`
    background-color: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ChooseGroupType: React.FC<{ groupSetter: (type: GroupType) => void }> = ({ groupSetter }) => {
    return (
        <Container>
            <OptionCard>
                <TextContainer>
                    <Text bold>Option 1 - Include your whole business as a single groups</Text>
                    <Text>
                        If you choose to include your entire business as a single group, you can quickly get a sense of
                        the overall emotional state in real time.
                    </Text>
                    <ListContainer>
                        <li>
                            <Text>
                                You’ll be able to see which days have a higher NEI and which days have a lower NEI.
                            </Text>
                        </li>
                        <li>
                            <Text>
                                Find out how emotionally volatile your organization is – are they pretty steady or do
                                their emotions have wide swings?
                            </Text>
                        </li>
                        <li>
                            <Text>
                                If you track it consistently over time, you can begin to cross reference changes in your
                                company’s NEI with different business or social related events and determine the
                                percentage positive or negative impact these events have on your entire team.
                            </Text>
                        </li>
                    </ListContainer>
                    <CenteredButton
                        onClick={() => groupSetter('single')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose Single Group
                    </CenteredButton>
                </TextContainer>
            </OptionCard>
            <OptionCardDisabled>
                <TextContainer>
                    <Text bold>Option 2 – Create multiple groups</Text>
                    <Text>
                        You can create multiple groups of people in any way that aligns with your business’
                        organizational structure. Don’t worry! If you want to do multiple groups but you aren’t quite
                        ready, you can add them at any time later.
                    </Text>
                    <ListContainer>
                        <li>
                            <Text>
                                For example, you may choose to create groups by department, or by geographic location,
                                or you might choose to create groups of people who share similar job titles.
                            </Text>
                        </li>
                        <li>
                            <Text>
                                With this option, you can see your entire business’ NEI score, and you can compare the
                                NEI scores of the groups you create.
                            </Text>
                        </li>
                        <li>
                            <Text>
                                This can help you understand where intensely negative or intensely positive emotions are
                                originating from.
                            </Text>
                        </li>
                    </ListContainer>
                    <CenteredButton
                        onClick={() => groupSetter('multi')}
                        size="large"
                        style={{ color: colors.white, backgroundColor: colors.red }}
                    >
                        Choose Multiple Groups
                    </CenteredButton>
                </TextContainer>
                <DisabledCardOverlay>
                    <Text size="28">Coming Soon</Text>
                </DisabledCardOverlay>
            </OptionCardDisabled>
        </Container>
    );
};

export default ChooseGroupType;
