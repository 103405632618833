import React, { useState, useEffect } from 'react';
import StandardLayout from '../layout/StandardLayout';
import styled from 'styled-components';
import scale from '../constants/scale';
import colors from '../constants/colors';
import useRouter from '../hooks/useRouter';
import Text from '../components/Text';
import { Button, Table, Tooltip, Popconfirm } from 'antd';
import {
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
    CopyOutlined,
    BarChartOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    MessageOutlined,
    StockOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons';
import projects from '../query-functions/projects';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import SHORTNotification from '../components/SHORTNotification';
import Project from '../types/project';
import { completed, inProgress, scheduled } from '../constants/dashboard';
import { ColumnsType } from 'antd/lib/table';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import shadow from '../constants/shadow';
import storage from '../utilities/localStorage';
import groups from '../query-functions/groups';
import Auth from '../types/auth';

type ProjectDataCategory = 'scheduled' | 'inProgress' | 'completed';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
    margin-top: ${scale.lg};
    margin-bottom: ${scale.lg};
`;

const StyledButton = styled(Button)`
    width: 174px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${scale.md};
`;

const StyledTableContainer = styled.div`
    background-color: ${colors.white};
    ${shadow}
`;

const Dashboard: React.FC = () => {
    const router = useRouter();
    const queryClient = useQueryClient();
    const authenticated = useIsAuthenticated();

    const { column } = (router.location.state as { column: string })
        ? (router.location.state as { column: string })
        : { column: 'inProgress' };

    const [loading, setLoading] = useState(false);
    const [activeData, setActiveData] = useState<Project[]>([]);
    const [activeType, setActiveType] = useState<ProjectDataCategory>();

    const [columnConfig, setColumnConfig] = useState<ColumnsType<Project>>();

    const [scheduledData, setScheduledData] = useState<Project[]>([]);
    const [inProgressData, setInProgressData] = useState<Project[]>([]);
    const [completedData, setCompletedData] = useState<Project[]>([]);

    const [scheduledNum, setScheduledNum] = useState<number>(0);
    const [inProgressNum, setInProgressNum] = useState<number>(0);
    const [completedNum, setCompletedNum] = useState<number>(0);

    const companyId = storage.get('company')?.companyId;
    const grQuery = useIsAuthenticated() === true ? useQuery(['groups', companyId], groups.getAll) : null;

    useEffect(() => {
        if (column !== null) {
            switchProjectType(column as ProjectDataCategory);
        } else {
            setActiveData(inProgressData);
            setColumnConfig(inProgress);
            setActiveType('inProgress');
        }
    }, []);

    useEffect(() => {
        //are we authenticated?
        //yes
        if (!authenticated) {
            router.push('/login');
        }
    }, [authenticated, router]);

    const projectDataQuery = useQuery(['projects', companyId], projects.getByCompany);

    const projectTableau = (projectId: any) => {
        projects
            .getTableauResults(projectId)
            .catch((err) => {
                //error(err, 'projects.ts');
                throw new Error(err);
            })
            .finally(() => {
                SHORTNotification('Results will be sent to you via email');
            });
    };

    const projectDuplicateMutation = useMutation(projects.duplicate, {
        onSuccess: () => {
            SHORTNotification('Project duplicated');
            queryClient.invalidateQueries('projects');
            setLoading(false);
        },
    });

    const projectDeleteMutation = useMutation(projects.delete, {
        onSuccess: () => {
            SHORTNotification('Project deleted');
            queryClient.invalidateQueries('projects');
            setLoading(false);
        },
    });

    const projectEnableDisablePauseMutation = useMutation(projects.enableDisable, {
        onSuccess: () => {
            queryClient.invalidateQueries('projects');
            setLoading(false);
        },
    });

    const clearState = () => {
        setActiveData([]);
        setScheduledData([]);
        setInProgressData([]);
        setCompletedData([]);
        setScheduledNum(0);
        setInProgressNum(0);
        setCompletedNum(0);
    };

    useEffect(() => {
        const { data, isLoading } = projectDataQuery;
        const today = moment().format('YYYYMMDD');

        if (isLoading) {
            setLoading(true);
        }
        if (data) {
            clearState();
            data.forEach((project: Project) => {
                const { dateStart, dateEnd } = project;

                project.groupNames = '';
                //do we have a query yet?
                //yes
                if (grQuery !== null) {
                    //iterate through the groups in the project
                    for (let nGroupCount = 0; nGroupCount < project.groups.length; nGroupCount++) {
                        //iterate through the company groups
                        for (let nGroup = 0; nGroup < grQuery?.data?.length; nGroup++) {
                            //is this our group?
                            //yes
                            if (grQuery.data[nGroup].id === project.groups[nGroupCount]) {
                                //add it to our list
                                project.groupNames += grQuery.data[nGroup].name + ', ';
                            }
                        }
                    }
                }
                //do we have any?
                //yes
                if (project.groupNames !== '') {
                    project.groupNames = project.groupNames.substring(0, project.groupNames.length - 2);
                }

                const projectStartDate = moment(dateStart).format('YYYYMMDD');
                const projectEndDate = moment(dateEnd).format('YYYYMMDD');

                //does it started yet?
                //no
                if (projectStartDate > today) {
                    setScheduledData((prev) => prev.concat(project));
                    setScheduledNum((prev) => prev + 1);
                }
                //are we in progress?
                //yes
                if (today >= projectStartDate && today <= projectEndDate) {
                    setInProgressData((prev) => prev.concat(project));
                    setInProgressNum((prev) => prev + 1);
                }
                //are we done?
                //yes
                if (projectEndDate < today) {
                    setCompletedData((prev) => prev.concat(project));
                    setCompletedNum((prev) => prev + 1);
                }
            });
        }
    }, [projectDataQuery.data, projectDataQuery.isLoading]);

    useEffect(() => {
        const { data } = projectDataQuery;

        const dataLength = scheduledData.length + inProgressData.length + completedData.length;

        if (data && dataLength === data.length) {
            switch (activeType) {
                case 'inProgress':
                    setActiveData(inProgressData);
                    break;
                case 'completed':
                    setActiveData(completedData);
                    break;
                case 'scheduled':
                    setActiveData(scheduledData);
                    break;
            }
            setLoading(false);
        }
    }, [projectDataQuery.data, scheduledData, inProgressData, completedData]);

    const deleteProject = (data: Project['id']) => {
        setLoading(true);
        projectDeleteMutation.mutate(data);
    };

    const enableDisableProject = (data: any) => {
        setLoading(true);
        projectEnableDisablePauseMutation.mutate(data);
        SHORTNotification(data.enabled ? 'Project disabled' : 'Project enabled');
    };

    const generateTableauResults = (proj: Project) => {
        projectTableau(proj.id);
    };

    const duplicateProject = (data: any) => {
        delete data.pulses;
        delete data.latestNEI;
        delete data.responseRate;
        setLoading(true);
        projectDuplicateMutation.mutate(data);
    };

    const actionsColumn: ColumnsType<Project> =
        activeType === 'completed'
            ? [
                  {
                      title: 'Actions',
                      width: 260,
                      align: 'center',
                      render: (value: string, record: Project) => (
                          <>
                              <Tooltip title={'Get Video Feedback Results'} mouseEnterDelay={0.6}>
                                  <Popconfirm
                                      title={`Would you like to receive current analytics for ${record.name}?`}
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() => generateTableauResults(record)}
                                  >
                                      <Button
                                          size="small"
                                          type="text"
                                          style={{
                                              display: record.projectType === 'Content Feedback' ? '' : 'none',
                                          }}
                                      >
                                          <StockOutlined style={{ fontSize: scale.mdl }} />
                                      </Button>
                                  </Popconfirm>
                              </Tooltip>
                              <Tooltip title="Edit project" mouseEnterDelay={0.6}>
                                  <Button
                                      size="small"
                                      type="text"
                                      style={{
                                          display:
                                              record.projectType === 'Content Feedback'
                                                  ? 'none'
                                                  : process.env.NODE_ENV === 'development'
                                                  ? ''
                                                  : 'none',
                                      }}
                                      onClick={() => router.push('/editproject', { project: record, edit: true })}
                                  >
                                      <EditOutlined style={{ fontSize: scale.lg }} />
                                  </Button>
                              </Tooltip>
                              <Tooltip title="Delete project" mouseEnterDelay={0.6}>
                                  <Popconfirm
                                      title={`Are you sure you want to delete ${record.name}?`}
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() => deleteProject(record.id)}
                                  >
                                      <Button
                                          size="small"
                                          type="text"
                                          disabled={record.projectType === 'Content Feedback' ? true : loading}
                                      >
                                          <DeleteOutlined style={{ fontSize: scale.lg }} />
                                      </Button>
                                  </Popconfirm>
                              </Tooltip>
                              <Tooltip title="View project statistics" mouseEnterDelay={0.6}>
                                  <Button
                                      size="small"
                                      type="text"
                                      disabled={
                                          record.projectType === 'Content Feedback'
                                              ? true
                                              : loading || record.projectType === 'One-Time Announcement'
                                      }
                                      onClick={() =>
                                          router.push('/neidashboard', { project: record, column: activeType })
                                      }
                                  >
                                      <BarChartOutlined style={{ fontSize: scale.lg }} />
                                  </Button>
                              </Tooltip>
                              <Tooltip title="Download Project Stats" mouseEnterDelay={0.6}>
                                  <Button
                                      size="small"
                                      type="text"
                                      disabled={
                                          record.projectType === 'Content Feedback'
                                              ? true
                                              : loading || record.projectType === 'One-Time Announcement'
                                      }
                                      onClick={() => {
                                          router.push('/projectstats', { project: record, column: activeType });
                                      }}
                                  >
                                      <ArrowDownOutlined style={{ fontSize: scale.lg }} />
                                  </Button>
                              </Tooltip>
                              <Tooltip title={'View responses'} mouseEnterDelay={0.6}>
                                  <Button
                                      size="small"
                                      type="text"
                                      disabled={
                                          record.projectType === 'Content Feedback'
                                              ? true
                                              : loading ||
                                                record.projectType === 'One-Time Announcement' ||
                                                record.respondantCount == 0
                                      }
                                      onClick={() => router.push('/responses', { project: record, column: activeType })}
                                  >
                                      <MessageOutlined style={{ fontSize: scale.mdl }} />
                                  </Button>
                              </Tooltip>
                          </>
                      ),
                  },
              ]
            : [
                  {
                      title: 'Actions',
                      width: 260,
                      align: 'center',
                      render: (value: string, record: Project) => {
                          let editDisabled = false;
                          if (
                              record.projectType === 'One-Time Announcement' ||
                              record.projectType === 'One-Time Custom Question' ||
                              record.projectType === 'One-Time Yes/No Question'
                          ) {
                              if (record.pulses && record.pulses.length > 0 && record.pulses[0].queued) {
                                  editDisabled = true;
                              }
                          }
                          return (
                              <>
                                  <Tooltip title={'Get Video Feedback Results'} mouseEnterDelay={0.6}>
                                      <Popconfirm
                                          title={`Would you like to receive current analytics for ${record.name}?`}
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => generateTableauResults(record)}
                                      >
                                          <Button
                                              size="small"
                                              type="text"
                                              style={{
                                                  display: record.projectType === 'Content Feedback' ? '' : 'none',
                                              }}
                                          >
                                              <StockOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Popconfirm>
                                  </Tooltip>
                                  <Tooltip title={'Pause project'} mouseEnterDelay={0.6}>
                                      <Popconfirm
                                          title={`Are you sure you want to pause ${record.name}?`}
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => enableDisableProject(record)}
                                      >
                                          <Button
                                              size="small"
                                              type="text"
                                              style={{
                                                  display:
                                                      record.projectType === 'Content Feedback'
                                                          ? 'none'
                                                          : record.enabled
                                                          ? ''
                                                          : 'none',
                                              }}
                                          >
                                              <PauseCircleOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Popconfirm>
                                  </Tooltip>
                                  <Tooltip title={'Resume project'} mouseEnterDelay={0.6}>
                                      <Popconfirm
                                          title={`Are you sure you want to resume ${record.name}?`}
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => enableDisableProject(record)}
                                      >
                                          <Button
                                              size="small"
                                              type="text"
                                              style={{
                                                  display:
                                                      record.projectType === 'Content Feedback'
                                                          ? 'none'
                                                          : record.enabled
                                                          ? 'none'
                                                          : '',
                                              }}
                                          >
                                              <PlayCircleOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Popconfirm>
                                  </Tooltip>
                                  <Tooltip title="Edit project" mouseEnterDelay={0.6}>
                                      <Button
                                          size="small"
                                          type="text"
                                          disabled={
                                              process.env.NODE_ENV === 'development' &&
                                              record.projectType !== 'Content Feedback'
                                                  ? false
                                                  : record.projectType === 'Content Feedback'
                                                  ? true
                                                  : editDisabled
                                          }
                                          onClick={() => router.push('/editproject', { project: record, edit: true })}
                                      >
                                          <EditOutlined style={{ fontSize: scale.mdl }} />
                                      </Button>
                                  </Tooltip>
                                  <Tooltip title="Delete project" mouseEnterDelay={0.6}>
                                      <Popconfirm
                                          title={`Are you sure you want to delete ${record.name}?`}
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => deleteProject(record.id)}
                                      >
                                          <Button
                                              size="small"
                                              type="text"
                                              disabled={record.projectType === 'Content Feedback' ? true : loading}
                                          >
                                              <DeleteOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Popconfirm>
                                  </Tooltip>
                                  <Tooltip title="Duplicate project" mouseEnterDelay={0.6}>
                                      <Popconfirm
                                          title={`Are you sure you want to duplicate ${record.name}?`}
                                          okText="Yes"
                                          cancelText="No"
                                          onConfirm={() => duplicateProject(record)}
                                      >
                                          <Button
                                              size="small"
                                              type="text"
                                              disabled={record.projectType === 'Content Feedback' ? true : loading}
                                          >
                                              <CopyOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Popconfirm>
                                  </Tooltip>
                                  {activeType !== 'scheduled' ? (
                                      <Tooltip title={'View project statistics'} mouseEnterDelay={0.6}>
                                          <Button
                                              size="small"
                                              type="text"
                                              disabled={
                                                  record.projectType === 'Content Feedback'
                                                      ? true
                                                      : loading ||
                                                        record.projectType === 'One-Time Announcement' ||
                                                        (record.responseRate <= 0 &&
                                                            record.pulses &&
                                                            record.pulses.length == 0)
                                              }
                                              onClick={() =>
                                                  router.push('/neidashboard', { project: record, column: activeType })
                                              }
                                          >
                                              <BarChartOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Tooltip>
                                  ) : null}
                                  {activeType !== 'scheduled' ? (
                                      <Tooltip title={'View responses'} mouseEnterDelay={0.6}>
                                          <Button
                                              size="small"
                                              type="text"
                                              disabled={
                                                  record.projectType === 'Content Feedback'
                                                      ? true
                                                      : loading ||
                                                        record.projectType === 'One-Time Announcement' ||
                                                        record.respondantCount == 0
                                              }
                                              onClick={() =>
                                                  router.push('/responses', { project: record, column: activeType })
                                              }
                                          >
                                              <MessageOutlined style={{ fontSize: scale.mdl }} />
                                          </Button>
                                      </Tooltip>
                                  ) : null}
                              </>
                          );
                      },
                  },
              ];

    const switchProjectType = (type: ProjectDataCategory) => {
        switch (type) {
            case 'scheduled':
                setActiveData(scheduledData);
                setColumnConfig(scheduled);
                setActiveType('scheduled');
                break;

            case 'inProgress':
                setActiveData(inProgressData);
                setColumnConfig(inProgress);
                setActiveType('inProgress');
                break;

            case 'completed':
                setActiveData(completedData);
                setColumnConfig(completed);
                setActiveType('completed');
                break;

            default:
                break;
        }
    };

    return (
        <StandardLayout page="projects">
            <HeaderContainer>
                <StyledButton
                    icon={<PlusCircleOutlined />}
                    onClick={() => router.push('/newproject')}
                    size="large"
                    style={{ color: colors.white, backgroundColor: colors.red }}
                >
                    New Project
                </StyledButton>
                <Text size="28">Projects</Text>
                <ButtonContainer>
                    <Button
                        onClick={() => switchProjectType('scheduled')}
                        disabled={loading}
                        style={{
                            color: activeType === 'scheduled' ? colors.white : colors.gray,
                            backgroundColor: activeType === 'scheduled' ? colors.mediumBlue : colors.white,
                        }}
                    >{`${scheduledNum} Scheduled`}</Button>
                    <Button
                        onClick={() => switchProjectType('inProgress')}
                        disabled={loading}
                        style={{
                            color: activeType === 'inProgress' ? colors.white : colors.gray,
                            backgroundColor: activeType === 'inProgress' ? colors.mediumBlue : colors.white,
                        }}
                    >{`${inProgressNum} In Progress`}</Button>
                    <Button
                        onClick={() => switchProjectType('completed')}
                        disabled={loading}
                        style={{
                            color: activeType === 'completed' ? colors.white : colors.gray,
                            backgroundColor: activeType === 'completed' ? colors.mediumBlue : colors.white,
                        }}
                    >{`${completedNum} Completed`}</Button>
                </ButtonContainer>
            </HeaderContainer>
            <StyledTableContainer>
                <Table
                    dataSource={activeData}
                    loading={loading}
                    pagination={false}
                    rowKey={(record) => record.id}
                    //scroll={{ y: 640 }}
                    columns={columnConfig ? [...columnConfig, ...actionsColumn] : []}
                />
            </StyledTableContainer>
        </StandardLayout>
    );
};
export default Dashboard;
