import React, { useEffect } from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import storage from '../utilities/localStorage';
import useRouter from '../hooks/useRouter';
import useIsAdmin from '../hooks/useIsAdmin';
import colors from '../constants/colors';
import scale from '../constants/scale';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const { Item, SubMenu } = Menu;

const StyledMenu = styled(Menu)`
    background-color: ${colors.darkBlue};
    color: ${colors.lightGray};
    border-bottom-width: 0;
    font-size: ${scale.md};
`;

const SubMenuItem = styled(Item)`
    background-color: ${colors.darkBlue};
    color: ${colors.lightGray};
`;

const MenuBar: React.FC<{ current: string }> = ({ current }) => {
    const authenticated = useIsAuthenticated();
    const router = useRouter();
    const admin = useIsAdmin();

    useEffect(() => {
        if (authenticated) return;
        router.push('/login');
    }, [authenticated, router]);

    const logout = () => {
        storage.clear();
        router.push('/login');
    };

    return (
        <StyledMenu mode="horizontal" selectedKeys={[current]} triggerSubMenuAction="hover">
            <Item onClick={() => router.push('/')} key="projects">
                Projects
            </Item>
            <Item onClick={() => router.push('/groupsandmembers')} key="usersandgroups">
                Groups & Members
            </Item>
            <SubMenu key="submenu" title="Account">
                <SubMenuItem onClick={() => router.push('/profile')} key="myProfile">
                    My Profile
                </SubMenuItem>
                <SubMenuItem onClick={logout} key="logout">
                    Log Out
                </SubMenuItem>
            </SubMenu>
            {admin ? (
                <>
                    <SubMenu key="submenu2" title="Admin">
                        <SubMenuItem onClick={() => router.push('/admin/auths')} key="auths">
                            Auths
                        </SubMenuItem>
                        <SubMenuItem onClick={() => router.push('/admin/companies')} key="companies">
                            Companies
                        </SubMenuItem>
                        <SubMenuItem onClick={() => router.push('/admin/errors')} key="errors">
                            Error Reports
                        </SubMenuItem>
                    </SubMenu>
                </>
            ) : null}
        </StyledMenu>
    );
};

export default MenuBar;
