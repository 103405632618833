import { Button, Input, Radio } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { useMutation } from 'react-query';

import colors from '../../constants/colors';
import scale from '../../constants/scale';
import Text from '../../components/Text';
import FeelalyticsLogo from '../../components/FeelalyticsLogo';
import Footer from '../../components/Footer';
import { NavContainer } from '../../layout/StandardLayout';
import Background from '../../assets/images/background.png';
import companies from '../../query-functions/companies';
import { FormFooter, StyledForm } from '../../components/Styled/Form';
import groups from '../../query-functions/groups';
import storage from '../../utilities/localStorage';

// const formSchema = Yup.object().shape({
//     contactEmailAddress: Yup.string()
//         .min(2, 'Too Short!')
//         .max(50, 'Too Long!')
//         .required('Required')
//         .email('Invalid email'),
// });

const PageContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#2d304780, #2d304780), url(${Background});
    background-position: center;
    background-size: cover;
    position: relative;
`;

const TopBar = styled(NavContainer)`
    position: absolute;
    top: 0;
`;

const Card = styled.div`
    background-color: ${colors.white}FF;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: ${scale.lg};
    padding: ${scale.xl};
    border-radius: 2px;
`;

const BottomBar = styled.div`
    width: 100%;
    color: ${colors.gray};
    position: absolute;
    bottom: 0;
`;

const PositionedButton = styled(Button)`
    justify-self: start;
    text-transform: uppercase;
`;

const StyledRadio = styled(Radio.Group)`
    display: flex;
    flex-direction: column;
    gap: ${scale.md};
`;

type GroupName = {
    changeStep: (direction: 'prev' | 'next') => void;
    values: any;
    setFieldValue: any;
};

const GroupName: React.FC<GroupName> = ({ changeStep, values, setFieldValue }) => {
    return (
        <PageContainer>
            <TopBar>
                <FeelalyticsLogo />
            </TopBar>
            <Card>
                <Input
                    style={{ width: 245 }}
                    value={values.name}
                    placeholder="NAME YOUR FIRST GROUP"
                    onChange={(e) => {
                        setFieldValue('name', e.target.value);
                    }}
                />
                <Text bold>Choose the group’s main time zone</Text>
                <StyledRadio onChange={(e) => setFieldValue('timezone', e.target.value)}>
                    <Radio value={'Eastern'}>Eastern UTC-5</Radio>
                    <Radio value={'Central'}>Central UTC-6</Radio>
                    <Radio value={'Mountain'}>Mountain UTC-7</Radio>
                    <Radio value={'Pacific'}>Pacific UTC-8</Radio>
                </StyledRadio>
                <PositionedButton
                    style={{ color: colors.white, backgroundColor: colors.red }}
                    onClick={() => {
                        changeStep('next');
                    }}
                >
                    Continue
                </PositionedButton>
            </Card>
            <BottomBar>
                <Footer />
            </BottomBar>
        </PageContainer>
    );
};

export default GroupName;
