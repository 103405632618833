import React from 'react';
import { Modal, ModalProps, Button, Spin } from 'antd';
import Text from './Text';
import { UsergroupAddOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import scale from '../constants/scale';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import colors from '../constants/colors';

const StyledGroupIcon = styled(UsergroupAddOutlined)`
    font-size: 38px;
    grid-row-start: span 2;
`;

const StyledUploadContainer = styled.div<{ $loading: boolean }>`
    grid-column-start: 2;
    grid-column-end: 3;
    border: 1px dashed ${colors.darkBlue};
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: ${colors.lightGray};
    cursor: pointer;
    ${({ $loading }) =>
        $loading &&
        `background-color: ${colors.white};
         cursor: not-allowed;
         border-color: ${colors.lightGray};`}
`;

const DropInput = styled.input``;

const MemberUploadModal: React.FC<ModalProps & DropzoneOptions & { loading: boolean }> = ({
    onCancel,
    visible,
    onDrop,
    loading,
}) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Modal
            centered
            onCancel={onCancel}
            visible={visible}
            footer={<Button onClick={onCancel}>Close</Button>}
            bodyStyle={{ display: 'grid', gridTemplateColumns: '1fr 4fr', gridGap: scale.sm }}
        >
            <StyledGroupIcon />
            <Text bold>Bulk Upload</Text>
            <Text>
                Make sure your spreadsheet is formatted then click the “choose file” below to upload your data. It might
                take a minute to upload so don’t navigate away just yet!
            </Text>
            <StyledUploadContainer {...getRootProps()} $loading={loading}>
                {loading ? (
                    <Spin />
                ) : (
                    <>
                        <DropInput {...getInputProps()} type="file" accept=".csv, .xlsx" />
                        {isDragActive ? (
                            <Text size="14" color="blue">
                                Drop the file here ...
                            </Text>
                        ) : (
                            <Text size="14" color="blue">
                                Drag and drop a file here, or click to select a file
                            </Text>
                        )}
                    </>
                )}
            </StyledUploadContainer>
        </Modal>
    );
};
export default MemberUploadModal;
