import axios from 'axios';
import api from '../constants/api';
import Auth from '../types/auth';
import storage from '../utilities/localStorage';
import error from '../utilities/error';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const projectstats = {
    getByProject: async ({ queryKey }: any) => {
        //are we authed?
        //no
        if (useIsAuthenticated() === false) {
            return [];
        }

        const auth: Auth = storage.get('auth');

        const headers = {
            SECRET: auth?.secret,
            PASSPHRASE: auth?.passPhrase,
        };

        const projectId = queryKey[1];
        const params = { projectId };

        console.log('projectId', projectId);

        const { data } = await axios
            .get(api.projectstats, {
                headers,
                params,
            })
            .catch((err) => {
                //error(err, 'projectstats.ts');
                throw new Error(err);
            });

        return data;
    },
};

export default projectstats;
